// src/infinityClient.ts
import { v4 as uuid } from "uuid";
import { captureException as captureException3 } from "@sentry/hub";
import * as API from "@pexip/infinity-api";
import { Backoff as Backoff2 } from "@pexip/utils";
import { getErrorCode } from "@pexip/infinity-api";

// src/requestClient.ts
import { releaseToken, refreshToken, withToken } from "@pexip/infinity-api";
import { Backoff } from "@pexip/utils";

// src/constants.ts
var backoffBaseOptions = { min: 100, max: 3e4, jitter: 0.2 };
var NONE = "none";

// src/logger.ts
import log from "@pexip/logger";
var logger = log.child({ name: "infinity" });

// src/requestClient.ts
var createRequestClient = ({
  conferenceAlias,
  backoff = new Backoff(backoffBaseOptions),
  expires = 120,
  fetcher = window.fetch,
  token,
  host,
  tokenExpiredCb
}) => {
  let refreshPromise;
  let lastRefreshTs = new Date().getTime();
  const refreshInterval = setInterval(() => {
    void handleRefresh();
  }, expires * 1e3 / 3);
  const handleRefresh = async () => {
    if (refreshPromise) {
      logger.debug(
        "Refresh token already in progress. Returning pending one."
      );
      return refreshPromise;
    }
    refreshPromise = doRefresh();
    return refreshPromise;
  };
  const isTokenValid = () => lastRefreshTs + expires * 1e3 > new Date().getTime() - 5 * 1e3;
  const doRefresh = async () => {
    while (isTokenValid()) {
      logger.debug("trying to refresh token");
      try {
        const res = await refreshToken({
          fetcher: withToken(fetcher, token),
          params: {
            conferenceAlias
          },
          host
        });
        if (res.status === 200) {
          lastRefreshTs = new Date().getTime();
          token = res.data.result.token;
          logger.debug("Token refreshed. Cleanup refreshPromise.");
          refreshPromise = void 0;
          backoff.reset();
          return;
        }
        logger.warn(
          "Failed to refresh token (%s), retrying after backoff",
          res.data
        );
        await backoff.promise();
      } catch (e) {
        logger.warn(
          "Failed to refresh token, retrying after backoff. Error: ",
          e
        );
        await backoff.promise();
      }
    }
    backoff.reset();
    tokenExpiredCb?.();
    throw new Error("Unable to update access token");
  };
  const fireAndForgetRefresh = async () => {
    if (!isTokenValid()) {
      logger.warn("Token has already expired. Cannot refresh.");
      return false;
    }
    try {
      const res = await refreshToken({
        fetcher: withToken(fetcher, token),
        params: {
          conferenceAlias
        },
        host
      });
      if (res.status === 200) {
        lastRefreshTs = new Date().getTime();
        token = res.data.result.token;
        logger.debug("Token refreshed");
        return true;
      }
      logger.warn("Failed to refresh token (%s).", res.data);
      return false;
    } catch (e) {
      logger.warn("Failed to refresh token. Error: ", e);
      return false;
    }
  };
  const cleanup = async (reason) => {
    lastRefreshTs = 0;
    if (refreshInterval) {
      clearInterval(refreshInterval);
    }
    if (!token) {
      return;
    }
    const body = {};
    if (reason) {
      body.reason = reason;
    }
    if (reason === "Browser closed") {
      const isQueued = navigator.sendBeacon(
        `${host}/api/client/v2/conferences/${conferenceAlias}/release_token?token=${token}`,
        new Blob([JSON.stringify(body)], { type: "application/json" })
      );
      if (!isQueued) {
        logger.debug('Failed to queue "release_token" beacon');
      }
    } else {
      await releaseToken({
        fetcher: withToken(fetcher, token),
        params: { conferenceAlias },
        body,
        host
      });
    }
  };
  return {
    get token() {
      return token;
    },
    get expires() {
      return expires;
    },
    get fetcher() {
      return withToken(fetcher, token);
    },
    refreshToken: fireAndForgetRefresh,
    cleanup
  };
};

// src/call.ts
import { captureException as captureException2, withScope } from "@sentry/hub";
import { createMainPeerConnection } from "@pexip/peer-connection";
import { createStatsCollector } from "@pexip/peer-connection-stats";
import { isEmpty } from "@pexip/utils";

// src/signals.ts
import { createPCSignals } from "@pexip/peer-connection";
import { createStatsSignals } from "@pexip/peer-connection-stats";
import { createSignal, combine } from "@pexip/signal";
var callPrefix = "call:pc";
var pcMainSignals = createPCSignals(
  [
    "onIceCandidate",
    "onIceGatheringStateChange",
    "onIceConnectionStateChange",
    "onReceiveIceCandidate",
    "onRemoteStreams",
    "onConnectionStateChange",
    "onTransceiverChange",
    "onSecureCheckCode"
  ],
  `${callPrefix}:main`
);
var callLivenessSignals = {
  onReconnecting: createSignal({
    name: `${callPrefix}:reconnecting`
  }),
  onReconnected: createSignal({ name: `${callPrefix}:reconnected` })
};
var audioInboundStatsSignals = createStatsSignals("audio:inbound");
var audioOutboundStatsSignals = createStatsSignals("audio:outbound");
var videoInboundStatsSignals = createStatsSignals("video:inbound");
var videoOutboundStatsSignals = createStatsSignals("video:outbound");
var presoVideoInboundStatsSignals = createStatsSignals(
  "preso:video:inbound"
);
var presoVideoOutboundStatsSignals = createStatsSignals(
  "preso:video:outbound"
);
var combinedRtcStatsSignal = combine(
  audioInboundStatsSignals.onRtcStats,
  audioOutboundStatsSignals.onRtcStats,
  videoInboundStatsSignals.onRtcStats,
  videoOutboundStatsSignals.onRtcStats,
  presoVideoInboundStatsSignals.onRtcStats,
  presoVideoOutboundStatsSignals.onRtcStats
);
var combinedCallQualitySignal = combine(
  audioInboundStatsSignals.onCallQuality,
  audioOutboundStatsSignals.onCallQuality
);
var combinedCallQualityStatsSignal = combine(
  audioInboundStatsSignals.onCallQualityStats,
  audioOutboundStatsSignals.onCallQualityStats
);

// src/utils.ts
import { createSignal as createSignal2 } from "@pexip/signal";

// src/types.ts
var CallType = /* @__PURE__ */ ((CallType2) => {
  CallType2["audio"] = "audio";
  CallType2["video"] = "video";
  CallType2["api"] = "api";
  return CallType2;
})(CallType || {});

// src/utils.ts
var createInfinitySignal = (name, scope = "", variant = "generic") => createSignal2({
  name: `infinity/${scope}:${name}`,
  allowEmittingWithoutObserver: allowEmittingWithoutObserver(name),
  variant
});
var allowEmittingWithoutObserver = (signal) => [
  "onRtcStats",
  "onCallQualityStats",
  "onCallQuality",
  "onRequestedLayout",
  "onStage",
  "onPresentationFrame"
].includes(signal);
var REQUIRED_INFINITY_SIGNAL_KEYS = [
  "onAuthenticatedWithConference",
  "onConnected",
  "onDisconnected",
  "onAnswer",
  "onPresentationAnswer",
  "onFailedRequest",
  "onRetryQueueFlushed",
  "onPinRequired",
  "onError",
  "onIdp",
  "onRedirect",
  "onParticipants",
  "onParticipantJoined",
  "onParticipantLeft",
  "onMessage",
  "onApplicationMessage",
  "onMe",
  "onMyselfMuted",
  "onRequestedLayout",
  "onStage",
  "onConferenceStatus",
  "onTransfer",
  "onRaiseHand",
  "onLiveCaptions",
  "onSplashScreen",
  "onNewOffer",
  "onIceCandidate",
  "onUpdateSdp",
  "onPeerDisconnect",
  "onExtension",
  "onLayoutUpdate"
];
var createInfinityClientSignals = (more, scope = "") => {
  const signalScope = scope && [scope, ":"].join("");
  return [...REQUIRED_INFINITY_SIGNAL_KEYS, ...more].reduce(
    (signals, key) => ({
      ...signals,
      [key]: createInfinitySignal(key, signalScope)
    }),
    {}
  );
};
var REQUIRED_CALL_SIGNAL_KEYS = [
  "onCallConnected",
  "onRemoteStream",
  "onRemotePresentationStream",
  "onPresentationConnectionChange",
  "onRtcStats",
  "onCallQualityStats",
  "onCallQuality",
  "onSecureCheckCode"
];
var createCallSignals = (more, scope = "") => {
  const callScope = "call";
  const signalScope = scope && [scope, ":", callScope, ":"].join("");
  return [...REQUIRED_CALL_SIGNAL_KEYS, ...more].reduce(
    (signals, key) => ({
      ...signals,
      [key]: createInfinitySignal(key, signalScope)
    }),
    {}
  );
};
var REQUIRED_EVENT_SIGNAL_KEYS = [
  "onCallDisconnected",
  "onPresentationStart",
  "onPresentationStop",
  "onPresentationEnded",
  "onPresentationFrame",
  "onParticipantCreate",
  "onParticipantUpdate",
  "onParticipantDelete",
  "onMessage",
  "onConferenceUpdate",
  "onStageUpdate",
  "onLayoutUpdate",
  "onDisconnect",
  "onParticipantSyncBegin",
  "onParticipantSyncEnd",
  "onRefer",
  "onHold",
  "onFecc",
  "onRefreshToken",
  "onLiveCaptions",
  "onPeerDisconnect",
  "onNewOffer",
  "onUpdateSdp",
  "onNewCandidate",
  "onSplashScreen"
];
var createEventSignals = (more, scope = "") => {
  const callScope = "events";
  const signalScope = scope && [scope, ":", callScope, ":"].join("");
  return [...REQUIRED_EVENT_SIGNAL_KEYS, ...more].reduce(
    (signals, key) => ({
      ...signals,
      [key]: createInfinitySignal(key, signalScope)
    }),
    {}
  );
};
var toIceCandidate = (iceCandidate) => ({
  candidate: iceCandidate?.candidate ?? "",
  mid: iceCandidate?.sdpMid ?? "0",
  ...iceCandidate?.usernameFragment && {
    ufrag: iceCandidate.usernameFragment
  }
});
var getPresenter = (presentationEvent) => {
  const { presenter_name, presenter_uri } = presentationEvent;
  if (presenter_name && presenter_uri) {
    return `${presenter_name} <${presenter_uri}>`;
  }
  return presentationEvent.presenter_uri || "";
};
var canControl = (participant) => {
  if (!participant.service_type) {
    return false;
  }
  return ["conference", "lecture"].includes(participant.service_type) && participant.role === "chair";
};
var normalizeParticipant = (participant) => ({
  callType: participant.is_audio_only_call === "YES" ? "audio" /* audio */ : participant.is_video_call === "YES" ? "video" /* video */ : "api" /* api */,
  canControl: canControl(participant),
  canDisconnect: participant.disconnect_supported === "YES",
  canFecc: participant.fecc_supported === "YES",
  canMute: participant.mute_supported === "YES",
  canRaiseHand: participant.service_type !== "gateway",
  canSpotlight: participant.service_type !== "gateway",
  canTransfer: participant.transfer_supported === "YES",
  displayName: participant.display_name || participant?.uri?.replace("sip:", "") || "User",
  handRaisedTime: participant.buzz_time,
  identity: participant.uuid,
  isCameraMuted: participant.is_video_muted,
  isEndpoint: participant?.uri?.includes("sip"),
  isExternal: participant.is_external,
  isGateway: participant.service_type === "gateway",
  isHost: participant.role === "chair",
  isMainVideoDroppedOut: Boolean(participant.is_main_video_dropped_out),
  isMuted: participant.is_muted === "YES",
  isPresenting: participant.is_presenting === "YES",
  isSpotlight: participant.spotlight > 0,
  isStreaming: participant.is_streaming_conference,
  isVideoSilent: participant.is_video_silent,
  isWaiting: participant.service_type === "waiting_room",
  needsPresentationInMix: Boolean(participant.needs_presentation_in_mix),
  protocol: participant.protocol,
  raisedHand: participant.buzz_time > 0,
  role: participant.role,
  rxPresentation: participant.rx_presentation_policy === "ALLOW",
  serviceType: participant.service_type,
  spotlightOrder: participant.spotlight,
  startAt: new Date(participant.start_time * 1e3),
  startTime: participant.start_time,
  uri: participant.uri ?? "",
  uuid: participant.uuid
});
var getDirection = (tracks = []) => tracks.length > 0 ? "sendrecv" : "recvonly";

// src/eventSource.ts
import { captureException } from "@sentry/hub";
import { addRedactedValue } from "@pexip/logger";
var eventSignals = createEventSignals([]);
var EventSrcEvents = [
  {
    type: "presentation_start",
    fn: (event) => {
      eventSignals.onPresentationStart.emit(getPresenter(event));
    }
  },
  {
    type: "presentation_stop",
    fn: eventSignals.onPresentationStop.emit
  },
  {
    type: "presentation_ended",
    fn: eventSignals.onPresentationEnded.emit
  },
  {
    type: "presentation_frame",
    fn: ({ lastEventId }) => {
      eventSignals.onPresentationFrame.emit(lastEventId ?? "");
    }
  },
  {
    type: "participant_create",
    fn: (event) => {
      eventSignals.onParticipantCreate.emit(normalizeParticipant(event));
    }
  },
  {
    type: "participant_update",
    fn: (event) => {
      eventSignals.onParticipantUpdate.emit(normalizeParticipant(event));
    }
  },
  {
    type: "participant_delete",
    fn: ({ uuid: uuid2 }) => {
      eventSignals.onParticipantDelete.emit(uuid2);
    }
  },
  {
    type: "message_received",
    fn: eventSignals.onMessage.emit
  },
  {
    type: "conference_update",
    fn: ({
      locked,
      guests_muted: guestsMuted,
      all_muted: allMuted,
      started,
      live_captions_available: liveCaptionsAvailable,
      breakout_rooms: breakoutRooms,
      classification,
      direct_media: directMedia,
      presentation_allowed: presentationAllowed
    }) => {
      eventSignals.onConferenceUpdate.emit({
        locked,
        guestsMuted,
        allMuted: allMuted ?? false,
        started,
        liveCaptionsAvailable: liveCaptionsAvailable ?? false,
        breakoutRooms: breakoutRooms ?? false,
        classification,
        directMedia: directMedia ?? false,
        presentationAllowed: presentationAllowed ?? false
      });
    }
  },
  {
    type: "stage",
    fn: eventSignals.onStageUpdate.emit
  },
  {
    type: "layout",
    fn: eventSignals.onLayoutUpdate.emit
  },
  {
    type: "disconnect",
    fn: eventSignals.onDisconnect.emit
  },
  {
    type: "call_disconnected",
    fn: eventSignals.onCallDisconnected.emit
  },
  {
    type: "participant_sync_begin",
    fn: eventSignals.onParticipantSyncBegin.emit
  },
  {
    type: "participant_sync_end",
    fn: eventSignals.onParticipantSyncEnd.emit
  },
  {
    type: "refer",
    fn: eventSignals.onRefer.emit
  },
  {
    type: "on_hold",
    fn: eventSignals.onHold.emit
  },
  {
    type: "fecc",
    fn: eventSignals.onFecc.emit
  },
  {
    type: "refresh_token",
    fn: eventSignals.onRefreshToken.emit
  },
  {
    type: "live_captions",
    fn: eventSignals.onLiveCaptions.emit
  },
  {
    type: "peer_disconnect",
    fn: eventSignals.onPeerDisconnect.emit
  },
  {
    type: "new_offer",
    fn: eventSignals.onNewOffer.emit
  },
  {
    type: "update_sdp",
    fn: eventSignals.onUpdateSdp.emit
  },
  {
    type: "new_candidate",
    fn: eventSignals.onNewCandidate.emit
  },
  {
    type: "splash_screen",
    fn: eventSignals.onSplashScreen.emit
  }
];
function createEventSource({
  host,
  conferenceAlias,
  token
}) {
  const eventSource = new EventSource(
    `${host}/api/client/v2/conferences/${conferenceAlias}/events?token=${token}`
  );
  const listen = (eventType, fn) => eventSource?.addEventListener(
    eventType,
    ({ data }) => {
      try {
        if (data && typeof data === "string") {
          const eventParsed = JSON.parse(data);
          logData(eventType, eventParsed);
          fn(eventParsed);
        }
      } catch (error) {
        logger.error(
          { error },
          "Unable to parse EventSource message"
        );
        captureException(error);
      }
    },
    false
  );
  EventSrcEvents.forEach(
    (event) => listen(event.type, event.fn)
  );
  return eventSource;
}
var logData = (eventType, data) => {
  if (data instanceof Object) {
    if ("display_name" in data && data.display_name) {
      addRedactedValue(data.display_name);
    }
  }
  logger.debug(getLoggableEventSubset(data), `${eventType} received`);
};
var getLoggableEventSubset = (data) => {
  if (isChat(data)) {
    return { uuid: data.uuid };
  }
  return data;
};
var isChat = (data) => data instanceof Object && "payload" in data && "uuid" in data;

// src/call.ts
var createCall = (options) => {
  const presoState = new Proxy(
    {
      send: "new",
      recv: "new"
    },
    {
      set: (target, p, value) => {
        const didUpdate = Reflect.set(target, p, value);
        if (didUpdate) {
          options.callSignals.onPresentationConnectionChange.emit(
            target
          );
        }
        return didUpdate;
      }
    }
  );
  const pc = createPCCall(options);
  const disconnect2 = () => {
    pc.cleanup();
    pc.close();
    presoState.recv = "disconnected";
    presoState.send = "disconnected";
  };
  const setStream = (stream) => {
    pcMainSignals.onOfferRequired.emit(stream);
  };
  const setBandwidth = (bandwidth) => {
    pc.peer.bandwidth = bandwidth;
  };
  const present = async (stream) => {
    if (!stream) {
      return;
    }
    try {
      presoState.send = "connecting";
      presoState.recv = "disconnected";
      await options.takeFloor({});
      await pc.peer.setLocalStream(stream, "preso");
      presoState.send = "connected";
    } catch {
      presoState.send = "failed";
    }
  };
  const stopPresenting = () => {
    void options.releaseFloor({});
    presoState.send = "disconnected";
  };
  const receivePresentation = async () => {
    if (["connecting", "connected"].includes(presoState.recv)) {
      return;
    }
    presoState.recv = "connecting";
    if (presoState.send === "connected") {
      await options.releaseFloor({});
      presoState.send = "disconnected";
    }
    const presoTransceiver = pc.peer.getTransceiver("video", "preso");
    if (presoTransceiver) {
      options.callSignals.onRemotePresentationStream.emit(
        new MediaStream([presoTransceiver.receiver.track])
      );
      presoState.recv = "connected";
    } else {
      presoState.recv = "failed";
    }
  };
  const stopReceivingPresentation = () => {
    if (presoState.recv === "connected" || presoState.recv === "connecting") {
      presoState.recv = "disconnected";
    }
  };
  const sendDataChannelEvent = (event) => {
    if (!pc.dataChannel || pc.dataChannel.readyState !== "open") {
      logger.warn(
        { eventType: event.type },
        "DataChannel not open. Cannot send event."
      );
      return;
    }
    pc.dataChannel.send(JSON.stringify(event));
  };
  return {
    get presoState() {
      return presoState;
    },
    disconnect: disconnect2,
    present,
    receivePresentation,
    setBandwidth,
    setStream,
    stopPresenting,
    stopReceivingPresentation,
    sendDataChannelEvent
  };
};
var createPCCall = ({
  ack: ack2,
  sendOffer,
  update: update2,
  newCandidate: newCandidate2,
  getCurrentCallUuid,
  peerOptions,
  signals,
  mediaStream,
  callSignals,
  dataChannelId
}) => {
  let callEstablished = false;
  let sdpExchanged = false;
  let turn443 = false;
  let apiOneTimeCallbacks = {};
  let videoPacketsReceived = 0;
  let incomingCandidates = [];
  let outgoingCandidates = [];
  let dataChannel;
  const statsCollectors = { inbound: {}, outbound: {} };
  const createPC = (rtcConfig = peerOptions.rtcConfig) => {
    const peer2 = createMainPeerConnection(pcMainSignals, {
      ...peerOptions,
      rtcConfig,
      transceiversDirection: {
        main: {
          audio: getDirection(mediaStream?.getAudioTracks()),
          video: getDirection(mediaStream?.getVideoTracks())
        },
        preso: {
          video: "sendrecv"
        }
      }
    });
    if (dataChannelId) {
      try {
        dataChannel = peer2.createDataChannel("pexChannel", {
          negotiated: true,
          id: dataChannelId
        });
        dataChannel.onmessage = (event) => {
          try {
            const msg = JSON.parse(event.data);
            if (msg.type === "message") {
              eventSignals.onMessage.emit({
                ...msg.body,
                direct: false
              });
            }
          } catch (error) {
            logger.error(
              { error, eventType: event.type },
              "Failed to parse DataChannel event data from server."
            );
            withScope((scope) => {
              scope.setContext("DataChannel event", {
                eventType: event.type
              });
              captureException2(error);
            });
          }
        };
      } catch (error) {
        logger.error({ error }, "Failed to create DataChannel");
        captureException2(error);
      }
    }
    return peer2;
  };
  let peer = createPC();
  pcMainSignals.onOfferRequired.emit(mediaStream);
  const addIceServers = (iceServers) => {
    const rtcConfig = peer.getConfiguration();
    return isEmpty(iceServers) ? rtcConfig : {
      ...rtcConfig,
      iceServers: [...rtcConfig.iceServers ?? [], ...iceServers]
    };
  };
  const executeOneTimeApiCallback = (func) => {
    const callbacks = apiOneTimeCallbacks[func];
    if (callbacks) {
      callbacks.forEach((cb) => cb());
      apiOneTimeCallbacks[func] = void 0;
    }
  };
  const addOnceApiCallback = (func, cb) => {
    const cbArray = apiOneTimeCallbacks[func];
    if (cbArray) {
      cbArray.push(cb);
    } else {
      apiOneTimeCallbacks[func] = [cb];
    }
  };
  const createStatsCollectors = () => {
    const audioReceiver = peer.getTransceiver("audio")?.receiver;
    if (audioReceiver) {
      statsCollectors.inbound.audio = createStatsCollector({
        input: audioReceiver,
        signals: audioInboundStatsSignals
      });
    }
    const audioSender = peer.getTransceiver("audio")?.sender;
    if (audioSender) {
      statsCollectors.outbound.audio = createStatsCollector({
        input: audioSender,
        signals: audioOutboundStatsSignals
      });
    }
    const videoReceiver = peer.getTransceiver("video")?.receiver;
    if (videoReceiver) {
      statsCollectors.inbound.video = createStatsCollector({
        input: videoReceiver,
        signals: videoInboundStatsSignals
      });
    }
    const videoSender = peer.getTransceiver("video")?.sender;
    if (videoSender) {
      statsCollectors.outbound.video = createStatsCollector({
        input: videoSender,
        signals: videoOutboundStatsSignals
      });
    }
    const presoVideoReceiver = peer.getTransceiver(
      "video",
      "preso"
    )?.receiver;
    if (presoVideoReceiver) {
      statsCollectors.inbound.preso = createStatsCollector({
        input: presoVideoReceiver,
        signals: presoVideoInboundStatsSignals
      });
    }
    const presoVideoSender = peer.getTransceiver("video", "preso")?.sender;
    if (presoVideoSender) {
      statsCollectors.outbound.preso = createStatsCollector({
        input: presoVideoSender,
        signals: presoVideoOutboundStatsSignals
      });
    }
  };
  const cleanupStats = () => {
    statsCollectors.inbound.audio?.cleanup();
    statsCollectors.outbound.audio?.cleanup();
    statsCollectors.inbound.video?.cleanup();
    statsCollectors.outbound.video?.cleanup();
    statsCollectors.inbound.preso?.cleanup();
    statsCollectors.outbound.preso?.cleanup();
  };
  const restartIce = (targetPeer) => {
    if (!["new", "closed"].includes(targetPeer.connectionState)) {
      targetPeer.restartIce();
    }
  };
  const handleAck = (turn) => () => {
    sdpExchanged = true;
    incomingCandidates.forEach(pcMainSignals.onReceiveIceCandidate.emit);
    incomingCandidates = [];
    turn443 = false;
    if (!turn) {
      return;
    }
    const rtcConfig = addIceServers(turn);
    if (peer.setConfiguration) {
      peer.setConfiguration(rtcConfig);
      peer.restartIce();
    } else {
      peer.close();
      peer = createPC(rtcConfig);
      pcMainSignals.onOfferRequired.emit(mediaStream);
    }
  };
  const sendCandidate = (candidate) => void newCandidate2({
    candidate
  }).then(() => executeOneTimeApiCallback("newCandidate"));
  const bufferOutgoingCandidate = (candidate) => outgoingCandidates.push(candidate);
  let detachApiSignals = [
    signals.onAnswer.add(({ sdp, turn }) => {
      const answer = new RTCSessionDescription({
        sdp,
        type: "answer"
      });
      turn443 = Boolean(turn);
      outgoingCandidates.forEach((candidate) => {
        void newCandidate2({
          candidate
        }).then(() => executeOneTimeApiCallback("newCandidate"));
      });
      outgoingCandidates = [];
      pcMainSignals.onReceiveAnswer.emit(answer);
      void ack2({}).then(handleAck(turn));
    }),
    signals.onNewOffer.add((sdp) => {
      if (!sdp) {
        return;
      }
      const offer = new RTCSessionDescription({
        sdp,
        type: "offer"
      });
      pcMainSignals.onReceiveOffer.emit(offer);
    }),
    signals.onUpdateSdp.add((sdp) => {
      if (!sdp) {
        return;
      }
      const offer = new RTCSessionDescription({
        sdp,
        type: "offer"
      });
      pcMainSignals.onReceiveOffer.emit(offer);
    }),
    signals.onIceCandidate.add((candidate) => {
      if (sdpExchanged) {
        pcMainSignals.onReceiveIceCandidate.emit(candidate);
      } else {
        incomingCandidates.push(candidate);
      }
    })
  ];
  let detachPCSignals = [
    pcMainSignals.onOffer.add(({ sdp }) => {
      if (!sdp) {
        return;
      }
      if (sdpExchanged) {
        void update2({ sdp });
      } else {
        void sendOffer({ sdp });
      }
    }),
    pcMainSignals.onAnswer.add(({ sdp }) => {
      void ack2({ sdp }).then(handleAck());
    }),
    pcMainSignals.onRemoteStreams.add((streams) => {
      logger.debug({ streams }, "Received streams");
      streams.forEach((stream) => {
        if (peer.isRemoteMainStream(stream)) {
          const mainVideoTracks = stream.getVideoTracks().filter(peer.isRemoteMainTrack());
          const streamWithoutPreso = new MediaStream([
            ...stream.getAudioTracks(),
            ...mainVideoTracks
          ]);
          logger.debug(
            {
              stream,
              tracks: stream.getTracks(),
              streamWithoutPreso,
              tracksWithoutPreso: streamWithoutPreso.getTracks()
            },
            "Emits remote stream"
          );
          callSignals.onRemoteStream.emit(streamWithoutPreso);
        }
      });
    }),
    pcMainSignals.onIceCandidate.add((event) => {
      if (turn443) {
        return;
      }
      const candidate = toIceCandidate(event);
      if (getCurrentCallUuid()) {
        sendCandidate(candidate);
      } else {
        bufferOutgoingCandidate(candidate);
      }
    }),
    pcMainSignals.onConnectionStateChange.add((connectionState) => {
      if (connectionState === "connected") {
        callEstablished = true;
      } else if (connectionState === "closed") {
        signals.onError.emit({
          error: "WebRTC connection closed",
          errorCode: "#pex117"
        });
      }
      if (!callEstablished) {
        if (connectionState === "connected") {
          callSignals.onCallConnected.emit();
        }
        if (connectionState === "failed") {
          signals.onError.emit({
            error: "WebRTC connection failed",
            errorCode: "#pex128"
          });
        }
      }
    }),
    pcMainSignals.onIceConnectionStateChange.add((iceConnectionState) => {
      if (iceConnectionState === "failed") {
        signals.onError.emit({
          error: "Could not find ICE candidates",
          errorCode: "#pex196"
        });
      }
    }),
    pcMainSignals.onError.add((reason) => {
      logger.error(reason);
      captureException2(reason);
    }),
    pcMainSignals.onTransceiverChange.add(() => {
      cleanupStats();
      createStatsCollectors();
    }),
    pcMainSignals.onSecureCheckCode.add(callSignals.onSecureCheckCode.emit),
    combinedRtcStatsSignal.add(
      ([
        audioIn,
        audioOut,
        videoIn,
        videoOut,
        presoVideoIn,
        presoVideoOut
      ]) => {
        const stats = {
          inbound: {
            audio: audioIn,
            video: videoIn,
            preso: presoVideoIn
          },
          outbound: {
            audio: audioOut,
            video: videoOut,
            preso: presoVideoOut
          }
        };
        window.pexDebug = {
          ...window.pexDebug,
          stats
        };
        callSignals.onRtcStats.emit(stats);
      }
    ),
    combinedCallQualityStatsSignal.add(([audioIn, audioOut]) => {
      callSignals.onCallQualityStats.emit({
        inbound: { audio: audioIn },
        outbound: { audio: audioOut }
      });
    }),
    combinedCallQualitySignal.add(callSignals.onCallQuality.emit),
    videoInboundStatsSignals.onRtcStats.add((stats) => {
      if (stats) {
        const update3 = stats.packetsTransmitted;
        if (update3 < videoPacketsReceived) {
          return;
        }
        if (videoPacketsReceived !== 0 && videoPacketsReceived === update3) {
          logger.info(
            "Triggering ICE restart due to packet stagnation"
          );
          const resumeStatsUpdates = [
            statsCollectors.inbound.video?.resetStats(),
            statsCollectors.outbound.video?.resetStats(),
            statsCollectors.inbound.audio?.resetStats(),
            statsCollectors.outbound.audio?.resetStats()
          ];
          addOnceApiCallback("newCandidate", () => {
            videoPacketsReceived++;
            resumeStatsUpdates.forEach((resume) => resume?.());
            callLivenessSignals.onReconnected.emit();
          });
          callLivenessSignals.onReconnecting.emit();
          restartIce(peer);
        }
        videoPacketsReceived = update3;
      }
    })
  ];
  const cleanup = () => {
    detachApiSignals = detachApiSignals.flatMap((detach) => {
      detach();
      return [];
    });
    detachPCSignals = detachPCSignals.flatMap((detach) => {
      detach();
      return [];
    });
    cleanupStats();
    apiOneTimeCallbacks = {};
  };
  const close = () => {
    callEstablished = false;
    sdpExchanged = false;
    if (peer.connectionState === "closed") {
      return;
    }
    peer.close();
  };
  return {
    get peer() {
      return peer;
    },
    get dataChannel() {
      return dataChannel;
    },
    cleanup,
    close
  };
};

// src/infinityClient.ts
var createInfinityClient = (signals, callSignals) => {
  let requestClient;
  let eventSource;
  let currentCall;
  let currentCallType;
  let currentCallUuid;
  let currentConferenceAlias;
  let currentParticipantUuid;
  let currentHost;
  let currentPin;
  let currentConferenceExtension;
  let currentChosenIdp = NONE;
  let currentSsoToken = NONE;
  let detachSignals = [];
  let participants = {};
  let conferenceStatus;
  let conferenceFeatureFlags;
  let secureCheckCode = "";
  let stun;
  let turn;
  let dataChannelId;
  let clientStatsUpdateInterval;
  let latestStats;
  let useRelayCandidatesOnly;
  let disconnectPromise;
  let restartCallPromise;
  let themeSchema;
  let retryList = [];
  const timerIDs = {
    eventSrcReconnect: -1,
    update: -1
  };
  const intervalIDs = {
    pushStatistics: -1
  };
  const applicationJsonType = "application/json";
  const captureNoRequestClient = () => {
    const errorMsg = "Attempted to create EventSource before RequestClient, or RequestClient has been already cleaned up. Aborting.";
    logger.warn(errorMsg);
    return;
  };
  const isCriticalAction = (funcName) => {
    return ["call", "sendOffer", "ack", "disconnect"].includes(funcName);
  };
  const getCurrentCallUuid = () => currentCallUuid;
  function generateEndpoint(opts) {
    const wrappedFunc = async (...args) => {
      logger.debug({ opts }, `${opts.funcName} called`);
      const { func, funcName, requiresToken, retriable } = opts;
      const flushRetryQueue = async () => {
        if (retryList.length > 0 && await requestClient?.refreshToken()) {
          const promise = Promise.allSettled(
            retryList.map((func2) => func2())
          );
          retryList = [];
          const res = await promise;
          const hasFailure = res.find(
            (settled) => settled.status === "fulfilled" && settled.value === void 0
          );
          if (!hasFailure) {
            signals.onRetryQueueFlushed.emit();
          }
        }
      };
      let endpointPromise;
      try {
        if (requiresToken) {
          if (!requestClient) {
            captureNoRequestClient();
            return;
          }
          await flushRetryQueue();
          endpointPromise = await func(args, requestClient.fetcher);
        } else {
          endpointPromise = await func(args);
        }
      } catch (e) {
        if (retriable) {
          logger.error(
            { error: e },
            `Adding failed request '${funcName}' to retry queue.`
          );
          retryList.push(() => wrappedFunc(...args));
        } else {
          logger.error(
            { error: e },
            `Request '${funcName}' threw an Error.`
          );
          if (navigator.onLine) {
            captureException3(e);
          }
          if (isCriticalAction(funcName)) {
            cleanupAndDisconnect(
              "Could not execute critical network action"
            );
            return endpointPromise;
          }
        }
        signals.onFailedRequest.emit(funcName);
      }
      return endpointPromise;
    };
    return wrappedFunc;
  }
  const openEventSource = (host, conferenceAlias, backoff = new Backoff2(backoffBaseOptions)) => {
    if (!requestClient) {
      captureNoRequestClient();
      return;
    }
    clearTimeout(timerIDs.eventSrcReconnect);
    eventSource?.close();
    eventSource = createEventSource({
      conferenceAlias,
      host,
      token: requestClient.token
    });
    eventSource.onopen = () => {
      logger.debug("Event Source opened");
      backoff.reset();
    };
    eventSource.onerror = (error) => {
      if (!eventSource) {
        return;
      }
      eventSource.close();
      logger.debug("EventSource error, Trying to reconnect", error);
      timerIDs.eventSrcReconnect = window.setTimeout(() => {
        if (requestClient?.token) {
          openEventSource(host, conferenceAlias, backoff);
        }
      }, backoff.duration());
    };
  };
  const startCall = ({
    bandwidth,
    mediaStream
  }) => {
    currentCall = createCall({
      sendOffer,
      ack: ack2,
      newCandidate: newCandidate2,
      update: update2,
      takeFloor: takeFloor2,
      releaseFloor: releaseFloor2,
      getCurrentCallUuid,
      signals,
      eventSignals,
      callSignals,
      peerOptions: {
        allow1080p: conferenceFeatureFlags?.allow1080p,
        allow4kPreso: conferenceFeatureFlags?.isDirectMedia,
        allowCodecSdpMunging: !conferenceFeatureFlags?.isDirectMedia,
        allowVP9: conferenceFeatureFlags?.allowVP9,
        bandwidth,
        rtcConfig: {
          bundlePolicy: "max-bundle",
          iceServers: [
            ...stun ? [
              {
                urls: stun.map((stun2) => stun2.url)
              }
            ] : [],
            ...turn ? turn : []
          ],
          ...useRelayCandidatesOnly && {
            iceTransportPolicy: "relay"
          }
        }
      },
      dataChannelId,
      mediaStream
    });
  };
  const restartCallRequest = async (opt) => {
    await disconnectRequest({
      callback: () => {
        currentCall?.disconnect();
        currentCall = void 0;
        currentCallUuid = void 0;
      },
      release: () => {
        return Promise.resolve();
      }
    });
    startCall(opt);
  };
  const restartCall = async (...params) => {
    restartCallPromise = restartCallRequest(...params);
    return restartCallPromise;
  };
  const call = generateEndpoint({
    func: async (reqParams) => {
      const {
        bandwidth,
        conferenceAlias,
        directMedia = true,
        displayName,
        node = window.location.host,
        host = `${window.location.protocol}//${node}`,
        mediaStream,
        pin,
        chosenIdp,
        ssoToken,
        token,
        conferenceExtension
      } = reqParams[0];
      if (pin) {
        currentPin = pin;
      }
      if (chosenIdp) {
        currentChosenIdp = chosenIdp;
      }
      if (ssoToken) {
        currentSsoToken = ssoToken;
      }
      if (conferenceExtension) {
        currentConferenceExtension = conferenceExtension;
      }
      if (restartCallPromise) {
        await restartCallPromise;
      }
      if (disconnectPromise) {
        await disconnectPromise;
      }
      const res = await API.requestToken({
        fetcher: currentPin ? API.withPin(window.fetch, currentPin) : window.fetch,
        body: {
          display_name: displayName,
          chosen_idp: currentChosenIdp,
          direct_media: directMedia,
          sso_token: currentSsoToken,
          node,
          token,
          conference_extension: currentConferenceExtension
        },
        params: {
          conferenceAlias
        },
        host
      });
      switch (res.status) {
        case 200:
          conferenceFeatureFlags = {
            chatEnabled: Boolean(res.data.result.chat_enabled),
            isDirectMedia: Boolean(res.data.result.direct_media),
            guestsCanPresent: Boolean(
              res.data.result.guests_can_present
            ),
            allow1080p: Boolean(res.data.result.allow_1080p),
            allowVP9: Boolean(res.data.result.vp9_enabled),
            callType: res.data.result.call_type ?? "video"
          };
          currentCallType = res.data.result.call_type;
          currentConferenceAlias = conferenceAlias;
          currentParticipantUuid = res.data.result.participant_uuid;
          currentHost = host;
          requestClient = createRequestClient({
            conferenceAlias,
            token: res.data.result.token,
            expires: Number(res.data.result.expires),
            host,
            tokenExpiredCb: () => {
              cleanupAndDisconnect(
                "Could not reconnect to the meeting"
              );
            }
          });
          themeSchema = await requestTheme({});
          openEventSource(host, conferenceAlias);
          if (detachSignals.length > 0) {
            cleanupSignals();
          }
          detachSignals = subscribeSignals();
          stun = res.data.result.stun;
          turn = res.data.result.turn;
          dataChannelId = res.data.result.pex_datachannel_id;
          clientStatsUpdateInterval = res.data.result.client_stats_update_interval;
          useRelayCandidatesOnly = res.data.result.use_relay_candidates_only;
          if (clientStatsUpdateInterval) {
            intervalIDs.pushStatistics = window.setInterval(
              pushStatistics,
              clientStatsUpdateInterval
            );
          }
          signals.onAuthenticatedWithConference.emit({
            conferenceAlias: currentConferenceAlias
          });
          logger.debug({ currentCall }, "Creates a new call");
          startCall({
            bandwidth,
            mediaStream
          });
          break;
        case 403:
        case 415:
          if (typeof res.data.result === "string") {
            signals.onError.emit({
              error: res.data.result,
              errorCode: getErrorCode(res.data.result)
            });
          } else if ("pin" in res.data.result && "guest_pin" in res.data.result) {
            signals.onPinRequired.emit({
              hasHostPin: res.data.result.pin === "required",
              hasGuestPin: res.data.result.guest_pin === "required"
            });
          } else if ("idp" in res.data.result) {
            signals.onIdp.emit(res.data.result.idp);
          } else if ("redirect_url" in res.data.result && "redirect_idp" in res.data.result) {
            signals.onRedirect.emit({
              redirectUrl: res.data.result.redirect_url,
              redirectIdp: res.data.result.redirect_idp
            });
          } else if ("conference_extension" in res.data.result && "conference_extension_type" in res.data.result) {
            signals.onExtension.emit(
              res.data.result.conference_extension_type
            );
          }
          break;
        case 404:
          signals.onError.emit({
            error: res.data.result,
            errorCode: getErrorCode(res.data.result)
          });
          break;
        case 502:
        case 504:
        case 529:
          signals.onError.emit({
            error: res.data.result,
            errorCode: getErrorCode(res.data.result)
          });
          break;
      }
      return res;
    },
    funcName: "call",
    requiresToken: false
  });
  const pushStatistics = () => {
    const convertBitrate = (bitrate) => bitrate ? bitrate / 1e3 : void 0;
    const convertJitter = (jitter) => jitter ? jitter * 1e3 : void 0;
    const convertPercentage = (percentage) => percentage ? percentage * 100 : void 0;
    void statistics2({
      audio: {
        rx_bitrate: convertBitrate(latestStats?.inbound.audio?.bitrate),
        rx_codec: latestStats?.inbound.audio?.codec?.replace(
          "audio/",
          ""
        ),
        rx_historic_packet_loss: convertPercentage(
          latestStats?.inbound.audio?.totalPercentageLost
        ),
        rx_jitter: convertJitter(latestStats?.inbound.audio?.jitter),
        rx_packets_lost: latestStats?.inbound.audio?.packetsLost,
        rx_packets_received: latestStats?.inbound.audio?.packetsTransmitted,
        rx_windowed_packet_loss: convertPercentage(
          latestStats?.inbound.audio?.recentPercentageLost
        ),
        tx_bitrate: convertBitrate(
          latestStats?.outbound.audio?.bitrate
        ),
        tx_codec: latestStats?.outbound.audio?.codec?.replace(
          "audio/",
          ""
        ),
        tx_historic_packet_loss: convertPercentage(
          latestStats?.outbound.audio?.totalPercentageLost
        ),
        tx_packets_sent: latestStats?.outbound.audio?.packetsTransmitted,
        tx_rb_jitter: convertJitter(
          latestStats?.outbound.audio?.jitter
        ),
        tx_rb_packetslost: latestStats?.outbound.audio?.packetsLost,
        tx_windowed_packet_loss: convertPercentage(
          latestStats?.outbound.audio?.recentPercentageLost
        )
      },
      video: {
        rx_bitrate: convertBitrate(latestStats?.inbound.video?.bitrate),
        rx_codec: latestStats?.inbound.video?.codec?.replace(
          "video/",
          ""
        ),
        rx_fps: latestStats?.inbound.video?.framesPerSecond,
        rx_historic_packet_loss: convertPercentage(
          latestStats?.inbound.video?.totalPercentageLost
        ),
        rx_packets_lost: latestStats?.inbound.video?.packetsLost,
        rx_packets_received: latestStats?.inbound.video?.packetsTransmitted,
        rx_resolution: latestStats?.inbound.video?.resolution,
        rx_windowed_packet_loss: convertPercentage(
          latestStats?.inbound.video?.recentPercentageLost
        ),
        tx_bitrate: convertBitrate(
          latestStats?.outbound.video?.bitrate
        ),
        tx_codec: latestStats?.outbound.video?.codec?.replace(
          "video/",
          ""
        ),
        tx_fps: latestStats?.outbound.video?.framesPerSecond,
        tx_historic_packet_loss: convertPercentage(
          latestStats?.outbound.video?.totalPercentageLost
        ),
        tx_packets_sent: latestStats?.outbound.video?.packetsTransmitted,
        tx_rb_packetslost: latestStats?.outbound.video?.packetsLost,
        tx_resolution: latestStats?.outbound.video?.resolution,
        tx_windowed_packet_loss: convertPercentage(
          latestStats?.outbound.video?.recentPercentageLost
        )
      },
      presentation: {
        rx_bitrate: convertBitrate(latestStats?.inbound.preso?.bitrate),
        rx_codec: latestStats?.inbound.preso?.codec?.replace(
          "video/",
          ""
        ),
        rx_fps: latestStats?.inbound.preso?.framesPerSecond,
        rx_historic_packet_loss: convertPercentage(
          latestStats?.inbound.preso?.totalPercentageLost
        ),
        rx_packets_lost: latestStats?.inbound.preso?.packetsLost,
        rx_packets_received: latestStats?.inbound.preso?.packetsTransmitted,
        rx_resolution: latestStats?.inbound.preso?.resolution,
        rx_windowed_packet_loss: convertPercentage(
          latestStats?.inbound.preso?.recentPercentageLost
        ),
        tx_bitrate: convertBitrate(
          latestStats?.outbound.preso?.bitrate
        ),
        tx_codec: latestStats?.outbound.preso?.codec?.replace(
          "video/",
          ""
        ),
        tx_fps: latestStats?.outbound.preso?.framesPerSecond,
        tx_historic_packet_loss: convertPercentage(
          latestStats?.outbound.preso?.totalPercentageLost
        ),
        tx_packets_sent: latestStats?.outbound.preso?.packetsTransmitted,
        tx_rb_packetslost: latestStats?.outbound.preso?.packetsLost,
        tx_resolution: latestStats?.outbound.preso?.resolution,
        tx_windowed_packet_loss: convertPercentage(
          latestStats?.outbound.preso?.recentPercentageLost
        )
      }
    });
  };
  const sendOffer = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        sdp,
        conferenceAlias = currentConferenceAlias,
        host = currentHost,
        participantUuid = currentParticipantUuid
      } = reqParams[0];
      const res = await API.callsWebrtcParticipant({
        fetcher,
        body: {
          call_type: "WEBRTC",
          sdp,
          media_type: currentCallType
        },
        params: {
          conferenceAlias,
          participantUuid
        },
        host
      });
      if (res.status === 200) {
        currentCallUuid = res.data.result.call_uuid;
        if (res.data.result.sdp) {
          signals.onAnswer.emit(res.data.result);
        }
      }
      return res;
    },
    funcName: "sendOffer",
    requiresToken: true
  });
  const ack2 = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        callUuid = currentCallUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost,
        participantUuid = currentParticipantUuid,
        sdp
      } = reqParams[0];
      if (!callUuid) {
        return;
      }
      return API.ack({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid,
          callUuid
        },
        body: { sdp },
        host
      });
    },
    funcName: "ack",
    requiresToken: true
  });
  const newCandidate2 = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        candidate,
        callUuid = currentCallUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost,
        participantUuid = currentParticipantUuid
      } = reqParams[0];
      if (!callUuid) {
        return;
      }
      return API.newCandidate({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid,
          callUuid
        },
        body: candidate,
        host
      });
    },
    funcName: "newCandidate",
    requiresToken: true
  });
  const update2 = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        sdp,
        callUuid = currentCallUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost,
        participantUuid = currentParticipantUuid
      } = reqParams[0];
      const doUpdate = async () => {
        if (!callUuid) {
          clearTimeout(timerIDs.update);
          return;
        }
        try {
          const res = await API.update({
            fetcher,
            body: {
              sdp
            },
            params: {
              conferenceAlias,
              participantUuid,
              callUuid
            },
            host
          });
          if (res.status === 200) {
            clearTimeout(timerIDs.update);
            await requestClient?.refreshToken();
            if (!present && eventSource?.readyState === 2) {
              openEventSource(host, conferenceAlias);
            }
            if (typeof res.data.result === "string") {
              signals.onAnswer.emit({
                sdp: res.data.result,
                call_uuid: callUuid
              });
            } else {
              signals.onAnswer.emit({
                sdp: res.data.result.sdp,
                call_uuid: res.data.result.call_uuid
              });
            }
          }
          return res;
        } catch (reason) {
          logger.error({ reason }, "Failed /update attempt");
          timerIDs.update = window.setTimeout(() => {
            void doUpdate();
          }, 1e3);
        }
      };
      clearTimeout(timerIDs.update);
      return doUpdate();
    },
    funcName: "update",
    requiresToken: true
  });
  const takeFloor2 = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        participantUuid = currentParticipantUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API.takeFloor({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid
        },
        host
      });
    },
    funcName: "takeFloor",
    requiresToken: true
  });
  const releaseFloor2 = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        participantUuid = currentParticipantUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API.releaseFloor({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid
        },
        host
      });
    },
    funcName: "releaseFloor",
    requiresToken: true
  });
  const cleanupSignals = () => {
    detachSignals.forEach((detach) => detach());
    detachSignals = [];
  };
  const cleanup = () => {
    logger.debug("Cleanup");
    currentCall?.disconnect();
    currentCall = void 0;
    eventSource?.close();
    eventSource = void 0;
    currentCallUuid = void 0;
    dataChannelId = void 0;
    clientStatsUpdateInterval = void 0;
    latestStats = void 0;
    useRelayCandidatesOnly = void 0;
    participants = {};
    secureCheckCode = "";
    conferenceStatus = void 0;
    currentPin = void 0;
    currentChosenIdp = NONE;
    currentSsoToken = NONE;
    currentConferenceExtension = void 0;
    cleanupSignals();
    Object.values(timerIDs).map((timer) => clearTimeout(timer));
    Object.values(intervalIDs).map((interval) => clearInterval(interval));
  };
  const releaseToken2 = async (reason) => {
    await requestClient?.cleanup(reason);
    requestClient = void 0;
  };
  const cleanupAndDisconnect = (error) => {
    signals.onDisconnected.emit({
      error,
      errorCode: getErrorCode(error)
    });
    cleanup();
    void releaseToken2();
  };
  const disconnectRequest = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        callUuid = currentCallUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost,
        participantUuid = currentParticipantUuid,
        reason,
        callback = cleanup,
        release = releaseToken2
      } = reqParams[0];
      callback();
      if (callUuid && reason !== "Browser closed") {
        try {
          await API.disconnectCall({
            fetcher,
            params: {
              conferenceAlias,
              participantUuid,
              callUuid
            },
            host
          });
        } catch (reason2) {
          logger.warn({ reason: reason2 }, "Unable to disconnect a call");
        }
      }
      await release(reason);
    },
    funcName: "disconnect",
    requiresToken: true
  });
  const disconnect2 = async (...params) => {
    if (restartCallPromise) {
      await restartCallPromise;
    }
    disconnectPromise = disconnectRequest(...params);
    return disconnectPromise;
  };
  const subscribeSignals = () => [
    eventSignals.onPresentationStart.add(() => {
      void currentCall?.receivePresentation();
    }),
    eventSignals.onPresentationStop.add(() => {
      currentCall?.stopReceivingPresentation();
    }),
    eventSignals.onCallDisconnected.add(({ call_uuid: callUuid }) => {
      logger.debug("onCallDisconnected", { callUuid, currentCallUuid });
      currentCall?.disconnect();
      currentCallUuid = void 0;
    }),
    eventSignals.onParticipantCreate.add((participant) => {
      if (participant.uuid === currentParticipantUuid) {
        signals.onMe.emit(participant);
      }
      participants[participant.uuid] = participant;
      signals.onParticipantJoined.emit(participant);
      signals.onParticipants.emit(Object.values(participants));
    }),
    eventSignals.onParticipantUpdate.add((participant) => {
      if (participant.uuid === currentParticipantUuid) {
        if (participant.isMuted !== participants[participant.uuid]?.isMuted) {
          signals.onMyselfMuted.emit(participant.isMuted);
        }
        signals.onMe.emit(participant);
      } else if (participants[participant.uuid] && participant.raisedHand !== participants[participant.uuid]?.raisedHand) {
        signals.onRaiseHand.emit(participant);
      }
      participants[participant.uuid] = participant;
      signals.onParticipants.emit(Object.values(participants));
    }),
    eventSignals.onParticipantDelete.add((uuid2) => {
      const leftParticipant = participants[uuid2];
      delete participants[uuid2];
      if (leftParticipant) {
        signals.onParticipantLeft.emit(leftParticipant);
      }
      signals.onParticipants.emit(Object.values(participants));
    }),
    eventSignals.onParticipantSyncBegin.add(() => {
      participants = {};
    }),
    eventSignals.onMessage.add(
      ({ type, uuid: userId, payload: message, direct }) => {
        const meta = {
          at: new Date(),
          id: uuid(),
          displayName: (userId && participants[userId]?.displayName) ?? "User",
          userId,
          direct
        };
        const [payloadType] = (type ?? "").split(";");
        if (payloadType === "text/plain") {
          signals.onMessage.emit({
            ...meta,
            message
          });
        } else {
          try {
            signals.onApplicationMessage.emit({
              ...meta,
              message: JSON.parse(message)
            });
          } catch {
            logger.error(
              "Could not parse application message payload"
            );
          }
        }
      }
    ),
    eventSignals.onLayoutUpdate.add((event) => {
      signals.onRequestedLayout.emit({
        primaryScreen: {
          hostLayout: event.requested_layout?.primary_screen.chair_layout,
          guestLayout: event.requested_layout?.primary_screen.guest_layout
        }
      });
      signals.onLayoutUpdate.emit(event);
    }),
    eventSignals.onStageUpdate.add(
      (stages) => signals.onStage.emit(
        stages.map((stage) => ({
          userId: stage.participant_uuid,
          stageIndex: stage.stage_index,
          vad: stage.vad
        }))
      )
    ),
    eventSignals.onDisconnect.add(({ reason }) => {
      cleanupAndDisconnect(reason);
    }),
    eventSignals.onConferenceUpdate.add((status) => {
      conferenceStatus = status;
      signals.onConferenceStatus.emit(status);
    }),
    eventSignals.onRefer.add(signals.onTransfer.emit),
    eventSignals.onLiveCaptions.add((captions) => {
      signals.onLiveCaptions.emit({
        data: captions.data,
        isFinal: captions.is_final
      });
    }),
    eventSignals.onSplashScreen.add((result) => {
      if (!result || !("screen_key" in result)) {
        signals.onSplashScreen.emit();
        return;
      }
      const splashScreen = themeSchema?.[result.screen_key];
      if (splashScreen) {
        signals.onSplashScreen.emit({
          screenKey: result.screen_key,
          text: splashScreen.elements[0]?.text ?? "",
          background: getBackgroundUrl(splashScreen.background.path),
          displayDuration: result.display_duration * 1e3
        });
      }
    }),
    eventSignals.onNewOffer.add(({ sdp }) => {
      signals.onNewOffer.emit(sdp);
    }),
    eventSignals.onUpdateSdp.add(({ sdp }) => {
      signals.onUpdateSdp.emit(sdp);
    }),
    eventSignals.onNewCandidate.add(
      ({ candidate, mid: sdpMid, ufrag: usernameFragment }) => {
        signals.onIceCandidate.emit(
          new RTCIceCandidate({ candidate, sdpMid, usernameFragment })
        );
      }
    ),
    eventSignals.onPeerDisconnect.add(() => {
      callSignals.onSecureCheckCode.emit("");
      signals.onPeerDisconnect.emit();
    }),
    eventSignals.onRefreshToken.add(() => {
      void requestClient?.refreshToken();
    }),
    callSignals.onSecureCheckCode.add((code) => {
      secureCheckCode = code;
    }),
    callSignals.onRtcStats.add((stats) => {
      latestStats = {
        inbound: {
          audio: stats.inbound.audio ?? latestStats?.inbound.audio,
          video: stats.inbound.video ?? latestStats?.inbound.video,
          preso: stats.inbound.preso ?? latestStats?.inbound.preso
        },
        outbound: {
          audio: stats.outbound.audio ?? latestStats?.outbound.audio,
          video: stats.outbound.video ?? latestStats?.outbound.video,
          preso: stats.outbound.preso ?? latestStats?.outbound.preso
        }
      };
    }),
    callSignals.onCallConnected.add(signals.onConnected.emit)
  ];
  const present = (stream) => {
    void currentCall?.present(stream);
  };
  const stopPresenting = () => {
    currentCall?.stopPresenting();
  };
  const setStream = (stream) => {
    currentCall?.setStream(stream);
  };
  const setBandwidth = (bandwidth) => {
    currentCall?.setBandwidth(bandwidth);
  };
  const setLayout = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        layout,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API.transformLayout({
        fetcher,
        params: {
          conferenceAlias
        },
        host,
        body: {
          transforms: {
            layout
          }
        }
      });
    },
    funcName: "setLayout",
    requiresToken: true
  });
  const raiseHand = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        raise,
        conferenceAlias = currentConferenceAlias,
        host = currentHost,
        participantUuid = currentParticipantUuid
      } = reqParams[0];
      return API[raise ? "buzzParticipant" : "clearbuzzParticipant"]({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid
        },
        host
      });
    },
    funcName: "raiseHand",
    requiresToken: true
  });
  const spotlight = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        enable,
        participantUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API[enable ? "spotlightonParticipant" : "spotlightoffParticipant"]({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid
        },
        host
      });
    },
    funcName: "spotlight",
    requiresToken: true
  });
  const sendMessageRequest = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        payload,
        type,
        participantUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      if (currentCall && conferenceFeatureFlags?.isDirectMedia) {
        currentCall.sendDataChannelEvent({
          type: "message",
          body: {
            type,
            origin: participants[currentParticipantUuid]?.displayName || "User",
            uuid: currentParticipantUuid,
            payload
          }
        });
        return Promise.resolve({
          status: 200,
          data: { status: "success", result: true }
        });
      } else {
        const common = {
          fetcher,
          params: {
            conferenceAlias,
            participantUuid
          },
          host,
          body: {
            payload,
            type
          }
        };
        return participantUuid ? API.messageParticipant({
          ...common,
          params: {
            conferenceAlias,
            participantUuid
          }
        }) : API.messageConference({
          ...common,
          params: {
            conferenceAlias
          }
        });
      }
    },
    funcName: "sendMessage",
    requiresToken: true,
    retriable: true
  });
  const sendMessage = async (params) => {
    return sendMessageRequest({ ...params, type: "text/plain" });
  };
  const sendApplicationMessage = async (params) => {
    try {
      const payload = JSON.stringify(params.payload);
      return sendMessageRequest({
        ...params,
        payload,
        type: "application/json"
      });
    } catch (e) {
      logger.error("Could not stringify application message", e);
    }
  };
  const admit = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        participantUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API.unlockParticipant({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid
        },
        host
      });
    },
    funcName: "admit",
    requiresToken: true
  });
  const mute = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        mute: mute2,
        participantUuid = currentParticipantUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API[mute2 ? "muteParticipant" : "unmuteParticipant"]({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid
        },
        host
      });
    },
    funcName: "mute",
    requiresToken: true
  });
  const muteAllGuests = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        mute: mute2,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API[mute2 ? "muteguests" : "unmuteguests"]({
        fetcher,
        params: {
          conferenceAlias
        },
        host
      });
    },
    funcName: "muteAllGuests",
    requiresToken: true
  });
  const muteVideo = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        muteVideo: muteVideo2,
        participantUuid = currentParticipantUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API[muteVideo2 ? "videoMuteParticipant" : "videoUnmuteParticipant"]({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid
        },
        host
      });
    },
    funcName: "muteVideo",
    requiresToken: true
  });
  const lock = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        lock: lock2,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API[lock2 ? "lock" : "unlock"]({
        fetcher,
        params: {
          conferenceAlias
        },
        host
      });
    },
    funcName: "lock",
    requiresToken: true
  });
  const disconnectAll = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API.disconnect({
        fetcher,
        params: {
          conferenceAlias
        },
        host
      });
    },
    funcName: "disconnectAll",
    requiresToken: true
  });
  const kick = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        participantUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API.disconnectParticipant({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid
        },
        host
      });
    },
    funcName: "kick",
    requiresToken: true
  });
  const dial2 = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        destination,
        role,
        streaming,
        protocol = "auto",
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API.dial({
        fetcher,
        params: {
          conferenceAlias
        },
        body: {
          destination,
          role,
          protocol,
          streaming
        },
        host
      });
    },
    funcName: "dial",
    requiresToken: true
  });
  const transfer = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        destination,
        pin,
        role,
        participantUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      return API.transferParticipant({
        fetcher,
        body: {
          conference_alias: destination,
          pin,
          role
        },
        params: {
          conferenceAlias,
          participantUuid
        },
        host
      });
    },
    funcName: "transfer",
    requiresToken: true
  });
  const liveCaptions = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        enable,
        conferenceAlias = currentConferenceAlias,
        participantUuid = currentParticipantUuid
      } = reqParams[0];
      await API[enable ? "showLiveCaptions" : "hideLiveCaptions"]({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid
        },
        host: currentHost
      });
    },
    funcName: "liveCaptions",
    requiresToken: true
  });
  const setRole = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        role,
        participantUuid,
        conferenceAlias = currentConferenceAlias
      } = reqParams[0];
      await API.roleParticipant({
        fetcher,
        body: {
          role
        },
        params: {
          conferenceAlias,
          participantUuid
        },
        host: currentHost
      });
    },
    funcName: "setRole",
    requiresToken: true
  });
  const setPin = (pin = NONE) => {
    currentPin = pin;
  };
  const setConferenceExtension = (conferenceExtension) => {
    currentConferenceExtension = conferenceExtension;
  };
  const statistics2 = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        audio,
        video,
        presentation,
        conferenceAlias = currentConferenceAlias,
        participantUuid = currentParticipantUuid,
        callUuid = currentCallUuid,
        host = currentHost
      } = reqParams[0];
      if (!callUuid) {
        return;
      }
      await API.statistics({
        fetcher,
        body: {
          audio,
          video,
          presentation
        },
        params: {
          conferenceAlias,
          participantUuid,
          callUuid
        },
        host
      });
    },
    funcName: "statistics",
    requiresToken: true
  });
  const requestTheme = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      try {
        const response = await API.theme({
          fetcher,
          params: {
            conferenceAlias
          },
          host
        });
        if (response.status !== 200) {
          return;
        }
        return response.data.result;
      } catch (reason) {
        logger.error({ reason }, `Can't request theme.`);
      }
    },
    funcName: "requestTheme",
    requiresToken: true
  });
  const setTextOverlay = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        text,
        conferenceAlias = currentConferenceAlias,
        host = currentHost,
        participantUuid
      } = reqParams[0];
      return API.overlaytextParticipant({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid
        },
        body: {
          text
        },
        host
      });
    },
    funcName: "setTextOverlay",
    requiresToken: true
  });
  const sendDTMF = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        digits,
        participantUuid,
        callUuid,
        conferenceAlias = currentConferenceAlias,
        host = currentHost
      } = reqParams[0];
      const common = {
        fetcher,
        params: {
          conferenceAlias,
          participantUuid
        },
        body: {
          digits
        },
        host
      };
      return callUuid ? API.dtmf({
        ...common,
        params: {
          conferenceAlias,
          participantUuid,
          callUuid
        }
      }) : API.dtmfParticipant(common);
    },
    funcName: "sendDTMF",
    requiresToken: true
  });
  const setParticipantRoom = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const {
        roomId,
        conferenceAlias = currentConferenceAlias,
        host = currentHost,
        participantUuid
      } = reqParams[0];
      return API.room({
        fetcher,
        params: {
          conferenceAlias,
          participantUuid
        },
        body: {
          room_id: roomId
        },
        host
      });
    },
    funcName: "setParticipantRoom",
    requiresToken: true
  });
  const sendConferenceRequest = generateEndpoint({
    func: async (reqParams, fetcher) => {
      const { path, method, payload } = reqParams[0];
      const uri = `${currentHost}/api/client/v2/conferences/${currentConferenceAlias}/${path}`;
      try {
        const body = payload ? JSON.stringify(payload) : void 0;
        const res = await fetcher(uri, {
          method,
          body,
          headers: {
            "Content-Type": applicationJsonType
          }
        });
        const responseContentType = res.headers.get("Content-Type");
        if (responseContentType !== applicationJsonType) {
          logger.error(
            `Response Content-Type is not ${applicationJsonType}`
          );
          return { status: res.status, data: void 0 };
        }
        try {
          const data = await res.json();
          return { status: res.status, data };
        } catch (e) {
          logger.error("Could not get response data: ", e);
        }
      } catch (e) {
        logger.error("Could not stringify payload");
      }
    },
    funcName: "sendConferenceRequest",
    requiresToken: true
  });
  const getBackgroundUrl = (path) => `${currentHost}/api/client/v2/conferences/${currentConferenceAlias}/theme/${path}?token=${requestClient?.token}`;
  return {
    get participants() {
      return Object.values(participants);
    },
    get conferenceStatus() {
      return conferenceStatus;
    },
    get conferenceFeatureFlags() {
      return conferenceFeatureFlags;
    },
    get me() {
      return participants[currentParticipantUuid];
    },
    get secureCheckCode() {
      return secureCheckCode;
    },
    admit,
    call,
    dial: dial2,
    disconnect: disconnect2,
    disconnectAll,
    kick,
    liveCaptions,
    lock,
    mute,
    muteAllGuests,
    muteVideo,
    present,
    raiseHand,
    restartCall,
    sendMessage,
    sendApplicationMessage,
    setBandwidth,
    setConferenceExtension,
    setLayout,
    setPin,
    setRole,
    setStream,
    spotlight,
    stopPresenting,
    transfer,
    setTextOverlay,
    sendDTMF,
    setParticipantRoom,
    sendConferenceRequest
  };
};
export {
  CallType,
  callLivenessSignals,
  createCall,
  createCallSignals,
  createInfinityClient,
  createInfinityClientSignals
};
