import React from 'react';
import cx from 'classnames';
import {Trans, useTranslation} from 'react-i18next';

import {
    Cell,
    CenterLayout,
    FontVariant,
    Grid,
    List,
    Modal,
    Row,
    Spinner,
    Text,
    ThemeProvider,
} from '@pexip/components';

import type {NormalizedStats} from '../../types';
import {useResponsiveStatsGrid} from '../../hooks/useResponsiveStatsGrid';
import {SecureCheckCode} from '../SecureCheckCode/SecureCheckCode.view';

import styles from './StatsModal.module.scss';

export const StatsModal: React.FC<
    React.PropsWithChildren<{
        isOpen?: boolean;
        onClose?: () => void;
        secureCheckCode?: string;
        stats?: NormalizedStats;
    }>
> = ({isOpen = true, onClose, secureCheckCode, stats, children}) => {
    const {t} = useTranslation();
    return (
        <ThemeProvider colorScheme="light">
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                sizeModifier="medium"
                withCloseButton
            >
                <Row className="pt-1 mb-1 ml-3 mr-3">
                    <Text fontVariant={FontVariant.H5}>
                        <Trans t={t} i18nKey="stats.nerd-stats">
                            Nerd stats
                        </Trans>
                    </Text>
                </Row>

                <div className={styles.statsBox}>
                    {stats ? (
                        <>
                            {children}
                            <StatsRows stats={stats} />
                            {secureCheckCode && (
                                <SecureCheckCode
                                    secureCheckCode={secureCheckCode}
                                />
                            )}
                        </>
                    ) : (
                        <CenterLayout>
                            <Spinner />
                        </CenterLayout>
                    )}
                </div>
            </Modal>
        </ThemeProvider>
    );
};

export const StatsRows = ({stats}: {stats: NormalizedStats}) => {
    const {t} = useTranslation();
    const audioRowsFiltered = audioRows(stats).filter(
        ([_title, inbound, outbound]) => inbound != null || outbound != null,
    );

    const videoRowsFiltered = videoRows(stats).filter(
        ([_title, inbound, outbound]) => inbound != null || outbound != null,
    );

    const presoRowsFiltered = videoRows(stats, 'preso').filter(
        ([_title, inbound, outbound]) => inbound != null || outbound != null,
    );

    const {isGridXs} = useResponsiveStatsGrid();

    return (
        <>
            <List>
                <Grid className={cx({'mb-1': isGridXs})}>
                    <Cell xs={6}>
                        <Metric bold left>
                            <Trans t={t} i18nKey="stats.audio">
                                Audio
                            </Trans>
                        </Metric>
                    </Cell>
                    <Cell xs={3}>
                        <Metric bold>
                            <Trans t={t} i18nKey="stats.in">
                                In
                            </Trans>
                        </Metric>
                    </Cell>
                    <Cell xs={3}>
                        <Metric bold>
                            <Trans t={t} i18nKey="stats.out">
                                Out
                            </Trans>
                        </Metric>
                    </Cell>
                </Grid>

                {audioRowsFiltered.map(([key, inbound, outbound]) => (
                    <Grid
                        className={cx({'mb-1': isGridXs})}
                        key={`audio ${key}`}
                    >
                        <Cell xs={6}>
                            <Metric left>{t(`stats.[${key}]`)}</Metric>
                        </Cell>
                        <Cell xs={3}>
                            <Metric>{inbound}</Metric>
                        </Cell>
                        <Cell xs={3}>
                            <Metric>{outbound}</Metric>
                        </Cell>
                    </Grid>
                ))}
            </List>

            <List>
                <Grid className={cx({'mb-1': isGridXs})}>
                    <Cell xs={6}>
                        <Metric bold left>
                            <Trans t={t} i18nKey="stats.video">
                                Video
                            </Trans>
                        </Metric>
                    </Cell>
                    <Cell xs={3}>
                        <Metric bold>
                            <Trans t={t} i18nKey="stats.in">
                                In
                            </Trans>
                        </Metric>
                    </Cell>
                    <Cell xs={3}>
                        <Metric bold>
                            <Trans t={t} i18nKey="stats.out">
                                Out
                            </Trans>
                        </Metric>
                    </Cell>
                </Grid>

                {videoRowsFiltered.map(([key, inbound, outbound]) => (
                    <Grid
                        className={cx({'mb-1': isGridXs})}
                        key={`video ${key}`}
                    >
                        <Cell xs={6}>
                            <Metric left>{t(`stats.[${key}]`)}</Metric>
                        </Cell>
                        <Cell xs={3}>
                            <Metric>{inbound}</Metric>
                        </Cell>
                        <Cell xs={3}>
                            <Metric>{outbound}</Metric>
                        </Cell>
                    </Grid>
                ))}
            </List>

            <List>
                <Grid className={cx({'mb-1': isGridXs})}>
                    <Cell xs={6}>
                        <Metric bold left>
                            <Trans t={t} i18nKey="stats.preso">
                                Presentation
                            </Trans>
                        </Metric>
                    </Cell>
                    <Cell xs={3}>
                        <Metric bold>
                            <Trans t={t} i18nKey="stats.in">
                                In
                            </Trans>
                        </Metric>
                    </Cell>
                    <Cell xs={3}>
                        <Metric bold>
                            <Trans t={t} i18nKey="stats.out">
                                Out
                            </Trans>
                        </Metric>
                    </Cell>
                </Grid>

                {presoRowsFiltered.map(([key, inbound, outbound]) => (
                    <Grid
                        className={cx({'mb-1': isGridXs})}
                        key={`preso ${key}`}
                    >
                        <Cell xs={6}>
                            <Metric left>{t(`stats.[${key}]`)}</Metric>
                        </Cell>
                        <Cell xs={3}>
                            <Metric>{inbound}</Metric>
                        </Cell>
                        <Cell xs={3}>
                            <Metric>{outbound}</Metric>
                        </Cell>
                    </Grid>
                ))}
            </List>
        </>
    );
};

const percentage = (value: number | undefined) =>
    typeof value === 'number' ? `${(value * 100).toFixed(1)}%` : null;

const bitrate = (value: number | undefined) => {
    if (typeof value !== 'number') {
        return value;
    }
    if (value > 1000) {
        return `${Math.round(value / 1000)}Kbps`;
    }

    return Math.round(value);
};

const resolution = (
    resolution: string | undefined,
    framerate: number | undefined,
) => {
    if (resolution && framerate) {
        return `${resolution}p${Math.round(framerate)}`;
    }
    return resolution;
};

const secondsToMilli = (seconds: number | undefined) =>
    typeof seconds === 'number' ? `${Math.round(seconds * 1000)}ms` : seconds;

/*
t('stats.[packets-transmitted]', 'Packets transmitted')
t('stats.[packets-lost]', 'Packets lost')
t('stats.[recent-packet-loss]', 'Recent packet loss')
t('stats.[total-packet-loss]', 'Total packet loss')
t('stats.[jitter]', 'Jitter')
t('stats.[bitrate]', 'Bitrate')
t('stats.[codec]', 'Codec')
t('stats.[roundtrip-time]', 'Roundtrip time')
t('stats.[codec]', 'Codec')
t('stats.[resolution]', 'Resolution')
*/

const audioRows = (stats: NormalizedStats) =>
    [
        [
            'packets-transmitted',
            stats.inbound?.audio?.packetsTransmitted,
            stats.outbound?.audio?.packetsTransmitted,
        ],
        [
            'packets-lost',
            stats.inbound?.audio?.packetsLost,
            stats.outbound?.audio?.packetsLost,
        ],
        [
            'recent-packet-loss',
            percentage(stats.inbound?.audio?.recentPercentageLost),
            percentage(stats.outbound?.audio?.recentPercentageLost),
        ],
        [
            'total-packet-loss',
            percentage(stats.inbound?.audio?.totalPercentageLost),
            percentage(stats.outbound?.audio?.totalPercentageLost),
        ],
        [
            'jitter',
            secondsToMilli(stats.inbound?.audio?.jitter),
            secondsToMilli(stats.outbound?.audio?.jitter),
        ],
        [
            'bitrate',
            bitrate(stats.inbound?.audio?.bitrate),
            bitrate(stats.outbound?.audio?.bitrate),
        ],
        ['codec', stats.inbound?.audio?.codec, stats.outbound?.audio?.codec],
        [
            'roundtrip-time',
            secondsToMilli(stats.outbound?.audio?.roundTripTime),
            '',
        ],
    ] as const;

const videoRows = (
    stats: NormalizedStats,
    contentType: 'video' | 'preso' = 'video',
) =>
    [
        [
            'packets-transmitted',
            stats.inbound?.[contentType]?.packetsTransmitted,
            stats.outbound?.[contentType]?.packetsTransmitted,
        ],
        [
            'packets-lost',
            stats.inbound?.[contentType]?.packetsLost,
            stats.outbound?.[contentType]?.packetsLost,
        ],
        [
            'recent-packet-loss',
            percentage(stats.inbound?.[contentType]?.recentPercentageLost),
            percentage(stats.outbound?.[contentType]?.recentPercentageLost),
        ],
        [
            'total-packet-loss',
            percentage(stats.inbound?.[contentType]?.totalPercentageLost),
            percentage(stats.outbound?.[contentType]?.totalPercentageLost),
        ],
        [
            'bitrate',
            bitrate(stats.inbound?.[contentType]?.bitrate),
            bitrate(stats.outbound?.[contentType]?.bitrate),
        ],
        [
            'codec',
            stats.inbound?.[contentType]?.codec,
            stats.outbound?.[contentType]?.codec,
        ],
        [
            'resolution',
            resolution(
                stats.inbound?.[contentType]?.resolution,
                stats.inbound?.[contentType]?.framesPerSecond,
            ),
            resolution(
                stats.outbound?.[contentType]?.resolution,
                stats.outbound?.[contentType]?.framesPerSecond,
            ),
        ],
        [
            'roundtrip-time',
            secondsToMilli(stats.outbound?.[contentType]?.roundTripTime),
            '',
        ],
    ] as const;

const Metric = ({
    children,
    left = false,
    bold = false,
}: {
    children: React.ReactNode;
    left?: boolean;
    bold?: boolean;
}) => {
    const {isGridXs} = useResponsiveStatsGrid();

    return (
        <div
            className={cx(styles.metricCell, {
                'text-left': left,
                'text-right': !left,
            })}
        >
            <Text
                fontVariant={
                    isGridXs
                        ? bold
                            ? FontVariant.XSmallBold
                            : FontVariant.XSmall
                        : bold
                        ? FontVariant.BodyBold
                        : FontVariant.Body
                }
            >
                {children}
            </Text>
        </div>
    );
};
