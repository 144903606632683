export type DeviceOption = {
    groupId: string;
    kind: string;
    label: string;
    id: string;
};

/**
 * Optimizes the device list to be ready for `Select`.
 */
export const normalizeDeviceOptions = (
    devices: MediaDeviceInfo[],
): DeviceOption[] =>
    devices.map(({deviceId: id, groupId, kind, label}) => ({
        groupId,
        kind,
        label,
        id,
    }));

/**
 * Returns device rejected list if all device `label` is empty, otherwise returns the given device list.
 */
export const buildDevicePermissionRejected = (
    devices: DeviceOption[],
    label: string,
): DeviceOption[] => {
    if (devices.length > 0 && devices.every(({label}) => !label)) {
        return [
            {
                groupId: '',
                kind: '',
                label,
                id: '',
            },
        ];
    }

    return devices;
};
