// src/index.ts
import { createSignal } from "@pexip/signal";

// src/logger.ts
import log from "@pexip/logger";
var logger = log.child({ name: "plugin-api" });

// src/typeGuards.ts
function isEvent(data) {
  return Boolean(
    data && typeof data === "object" && "chanId" in data && "event" in data
  );
}
function isRPCCall(data) {
  if (data && typeof data === "object" && "chanId" in data && "rpc" in data) {
    return true;
  }
  return false;
}
function isRPCReply(data) {
  if (data && typeof data === "object" && "chanId" in data && "replyTo" in data) {
    return true;
  }
  return false;
}
function isRPCCallType(type, data) {
  if (typeof data !== "object" || !data) {
    return false;
  }
  return data.rpc === type && typeof data.id === "string" && "payload" in data;
}

// src/utils.ts
import { v4 } from "uuid";
function generateId(readableName) {
  return `${readableName ? `${readableName}-` : ""}${v4()}`;
}

// src/channel.ts
var Channel = class {
  constructor(target, chanId) {
    this.target = target;
    this.chanId = chanId;
    globalThis.addEventListener("message", this.onMessage);
  }
  pendingCalls = /* @__PURE__ */ new Map();
  eventListeners = /* @__PURE__ */ new Set();
  addEventListener(listener) {
    this.eventListeners.add(listener);
  }
  removeEventListener(listener) {
    this.eventListeners.delete(listener);
  }
  emitEvent(event) {
    this.target.postMessage({ chanId: this.chanId, ...event }, "*");
  }
  callRPC(method, payload, transfer) {
    const id = generateId();
    logger.debug(
      { payload, id },
      `'${method}' called for channel ${this.chanId}`
    );
    return new Promise((resolve, reject) => {
      this.pendingCalls.set(id, [resolve, reject]);
      this.target.postMessage(
        {
          rpc: method,
          payload,
          id,
          chanId: this.chanId
        },
        "*",
        transfer
      );
    });
  }
  replyRPC(event) {
    this.emitEvent(event);
  }
  sendEvent(event) {
    this.emitEvent(event);
  }
  unregister() {
    globalThis.removeEventListener("message", this.onMessage);
  }
  onMessage = (evt) => {
    if (evt.data.chanId !== this.chanId) {
      return;
    }
    const data = evt.data;
    logger.debug({ evt }, `Message received for channel ${this.chanId}`);
    if (isRPCReply(data)) {
      const [resolve, _reject] = this.pendingCalls.get(data.replyTo) ?? [];
      if (!resolve) {
        logger.debug({ evt }, "Resolve fn doesnt exist");
        return;
      }
      this.pendingCalls.delete(data.replyTo);
      resolve(data.payload);
    }
    if (isEvent(data)) {
      this.eventListeners.forEach((listener) => {
        void listener(data);
      });
    }
  };
};

// src/index.ts
async function registerPlugin(meta) {
  const channel = new Channel(window.parent, generateId(meta.id));
  const reply = await channel.callRPC("syn", meta);
  if (!reply.ack) {
    throw new Error(`Can't register a plugin. ${JSON.stringify(reply)}`);
  }
  const buttons = /* @__PURE__ */ new Map();
  const participants = /* @__PURE__ */ new Map();
  const forms = /* @__PURE__ */ new Map();
  const prompts = /* @__PURE__ */ new Map();
  const authenticatedWithConference = createSignal({
    allowEmittingWithoutObserver: true
  });
  const conferenceStatus = createSignal({
    allowEmittingWithoutObserver: true
  });
  const connected = createSignal({
    allowEmittingWithoutObserver: true
  });
  const disconnected = createSignal({
    allowEmittingWithoutObserver: true
  });
  const me = createSignal({
    allowEmittingWithoutObserver: true
  });
  const infinityParticipants = createSignal({
    allowEmittingWithoutObserver: true
  });
  const participantJoined = createSignal({
    allowEmittingWithoutObserver: true
  });
  const participantLeft = createSignal({
    allowEmittingWithoutObserver: true
  });
  const raiseHand = createSignal({
    allowEmittingWithoutObserver: true
  });
  const message = createSignal({
    allowEmittingWithoutObserver: true
  });
  const directMessage = createSignal(
    {
      allowEmittingWithoutObserver: true
    }
  );
  const applicationMessage = createSignal({
    allowEmittingWithoutObserver: true
  });
  const transfer = createSignal({
    allowEmittingWithoutObserver: true
  });
  const stage = createSignal({
    allowEmittingWithoutObserver: true
  });
  const presentationConnectionStateChange = createSignal({
    allowEmittingWithoutObserver: true
  });
  const layoutUpdate = createSignal({
    allowEmittingWithoutObserver: true
  });
  channel.addEventListener((event) => {
    if (event.event === "ui:button:click") {
      const btn = buttons.get(event.payload.buttonId);
      btn?.onClick.emit(event.payload.input);
    } else if (event.event === "ui:form:input") {
      const form = forms.get(event.payload.modalId);
      form?.onInput.emit(event.payload.input);
    } else if (event.event === "ui:prompt:input") {
      const prompt = prompts.get(event.payload.modalId);
      prompt?.onInput.emit(event.payload.input);
    } else if (event.event === "participant:disconnected") {
      const participant = participants.get(event.payload.participantUuid);
      if (participant) {
        participants.delete(event.payload.participantUuid);
        participant.onDisconnect.emit();
      }
    } else if (event.event === "event:conferenceStatus") {
      conferenceStatus.emit(event.payload);
    } else if (event.event === "event:connected") {
      connected.emit(event.payload);
    } else if (event.event === "event:disconnected") {
      disconnected.emit(event.payload);
    } else if (event.event === "event:me") {
      me.emit(event.payload);
    } else if (event.event === "event:message") {
      message.emit(event.payload);
    } else if (event.event === "event:directMessage") {
      directMessage.emit(event.payload);
    } else if (event.event === "event:applicationMessage") {
      applicationMessage.emit(event.payload);
    } else if (event.event === "event:transfer") {
      transfer.emit(event.payload);
    } else if (event.event === "event:stage") {
      stage.emit(event.payload);
    } else if (event.event === "event:participants") {
      infinityParticipants.emit(event.payload);
    } else if (event.event === "event:participantLeft") {
      participantLeft.emit(event.payload);
    } else if (event.event === "event:participantJoined") {
      participantJoined.emit(event.payload);
    } else if (event.event === "event:raiseHand") {
      raiseHand.emit(event.payload);
    } else if (event.event === "event:presentationConnectionStateChange") {
      presentationConnectionStateChange.emit(event.payload);
    } else if (event.event === "event:layoutUpdate") {
      layoutUpdate.emit(event.payload);
    }
  });
  const removeElement = async (id, successCb) => {
    const result = await channel.callRPC("ui:removeElement", { id });
    if (result.status === "failed") {
      return Promise.reject({ reason: result.reason });
    }
    if (successCb) {
      successCb();
    }
  };
  return {
    ui: {
      addButton: async (payload) => {
        const btnMeta = await channel.callRPC("ui:button:add", payload);
        if (btnMeta.status === "failed") {
          return Promise.reject({ reason: btnMeta.reason });
        }
        const buttonElement = {
          onClick: createSignal({ allowEmittingWithoutObserver: true }),
          update: async (payload2) => {
            const result = await channel.callRPC(
              "ui:button:update",
              {
                ...payload2,
                id: btnMeta.id
              }
            );
            if (result.status === "failed") {
              return Promise.reject({ reason: result.reason });
            }
            return result.data;
          },
          remove: () => removeElement(
            btnMeta.id,
            () => buttons.delete(btnMeta.id)
          )
        };
        buttons.set(btnMeta.id, buttonElement);
        return buttonElement;
      },
      addForm: async (payload) => {
        const formMeta = await channel.callRPC("ui:form:open", payload);
        if (formMeta.status === "failed") {
          return Promise.reject({ reason: formMeta.reason });
        }
        const form = {
          onInput: createSignal(),
          remove: () => removeElement(
            formMeta.id,
            () => forms.delete(formMeta.id)
          )
        };
        forms.set(formMeta.id, form);
        return form;
      },
      showForm: async (payload) => {
        const formMeta = await channel.callRPC("ui:form:open", payload);
        if (formMeta.status === "failed") {
          return Promise.reject({ reason: formMeta.reason });
        }
        return new Promise((resolve) => {
          const eventListener = (event) => {
            if (event.event === "ui:form:input" && event.payload.modalId === formMeta.id) {
              resolve(event.payload.input);
              void removeElement(formMeta.id);
              channel.removeEventListener(eventListener);
            }
          };
          channel.addEventListener(eventListener);
        });
      },
      addPrompt: async (payload) => {
        const promptMeta = await channel.callRPC(
          "ui:prompt:open",
          payload
        );
        if (promptMeta.status === "failed") {
          return Promise.reject({ reason: promptMeta.reason });
        }
        const prompt = {
          onInput: createSignal(),
          remove: () => removeElement(
            promptMeta.id,
            () => prompts.delete(promptMeta.id)
          )
        };
        prompts.set(promptMeta.id, prompt);
        return prompt;
      },
      showPrompt: async (payload) => {
        const promptMeta = await channel.callRPC(
          "ui:prompt:open",
          payload
        );
        if (promptMeta.status === "failed") {
          return Promise.reject({ reason: promptMeta.reason });
        }
        return new Promise((resolve) => {
          const eventListener = (event) => {
            if (event.event === "ui:prompt:input" && event.payload.modalId === promptMeta.id) {
              resolve(event.payload.input);
              void removeElement(promptMeta.id);
              channel.removeEventListener(eventListener);
            }
          };
          channel.addEventListener(eventListener);
        });
      },
      showToast: async (payload) => {
        const toastMeta = await channel.callRPC(
          "ui:toast:show",
          payload
        );
        if (toastMeta.status === "failed") {
          return Promise.reject({ reason: toastMeta.reason });
        }
      }
    },
    conference: {
      dialOut: async (payload) => {
        let result = void 0;
        const joinedParticipants = /* @__PURE__ */ new Map();
        let detachParticipantJoinedObserver = () => void 0;
        const participantJoinedPromise = new Promise((resolve) => {
          detachParticipantJoinedObserver = participantJoined.add(
            (participant2) => {
              if (!result) {
                joinedParticipants.set(
                  participant2.uuid,
                  participant2
                );
              } else if (result.data.result.includes(
                participant2.uuid
              )) {
                resolve(participant2);
              }
            }
          );
        });
        result = await channel.callRPC("conference:dialOut", payload);
        const participantUuid = result?.data.result[0];
        if (!participantUuid) {
          throw Error("Could not dial out to specified uri");
        }
        const dialedParticipant = joinedParticipants.get(participantUuid) ?? await participantJoinedPromise;
        detachParticipantJoinedObserver();
        const participant = {
          ...dialedParticipant,
          onDisconnect: createSignal({
            allowEmittingWithoutObserver: true
          }),
          disconnect: async () => {
            participants.delete(participantUuid);
            return channel.callRPC("participant:disconnect", {
              participantUuid
            });
          }
        };
        participants.set(participantUuid, participant);
        return participant;
      },
      sendMessage: async (payload) => {
        return await channel.callRPC("conference:sendMessage", payload);
      },
      sendApplicationMessage: async (payload) => {
        return await channel.callRPC(
          "conference:sendApplicationMessage",
          payload
        );
      },
      lock: async (payload) => {
        return await channel.callRPC("conference:lock", payload);
      },
      muteAllGuests: async (payload) => {
        return await channel.callRPC(
          "conference:muteAllGuests",
          payload
        );
      },
      setBandwidth: async (payload) => {
        return await channel.callRPC(
          "conference:setBandwidth",
          payload
        );
      },
      setLayout: async (payload) => {
        return await channel.callRPC("conference:setLayout", payload);
      },
      disconnectAll: async (payload) => {
        return await channel.callRPC(
          "conference:disconnectAll",
          payload
        );
      },
      sendRequest: async (payload) => {
        return await channel.callRPC("conference:sendRequest", payload);
      },
      transfer: async (payload) => {
        return await channel.callRPC("participant:transfer", payload);
      },
      mute: async (payload) => {
        return await channel.callRPC("participant:mute", payload);
      },
      muteVideo: async (payload) => {
        return await channel.callRPC("participant:muteVideo", payload);
      },
      spotlight: async (payload) => {
        return await channel.callRPC("participant:spotlight", payload);
      },
      admit: async (payload) => {
        return await channel.callRPC("participant:admit", payload);
      },
      raiseHand: async (payload) => {
        return await channel.callRPC("participant:raiseHand", payload);
      },
      setRole: async (payload) => {
        return await channel.callRPC("participant:setRole", payload);
      },
      setTextOverlay: async (payload) => {
        return await channel.callRPC(
          "participant:setTextOverlay",
          payload
        );
      },
      sendDTMF: async (payload) => {
        return await channel.callRPC("participant:sendDTMF", payload);
      },
      setParticipantRoom: async (payload) => {
        return await channel.callRPC("participant:setRoom", payload);
      },
      disconnect: async (payload) => {
        const participantUuid = payload.participantUuid;
        participants.delete(participantUuid);
        return await channel.callRPC("participant:disconnect", {
          participantUuid
        });
      }
    },
    events: {
      authenticatedWithConference,
      conferenceStatus,
      connected,
      disconnected,
      me,
      message,
      directMessage,
      applicationMessage,
      transfer,
      stage,
      participants: infinityParticipants,
      participantJoined,
      participantLeft,
      raiseHand,
      presentationConnectionStateChange,
      layoutUpdate
    }
  };
}
export {
  Channel,
  isEvent,
  isRPCCall,
  isRPCCallType,
  isRPCReply,
  registerPlugin
};
