export enum TestId {
    AudioTestOutput = 'audio-test-output',
    ButtonAddParticipant = 'button-add-participant',
    ButtonAreYouHostNo = 'button-are-you-host-no',
    ButtonAreYouHostYes = 'button-are-you-host-yes',
    ButtonAudioMeter = 'button-audio-meter',
    ButtonAudioMeterMuted = 'button-audio-meter-muted',
    ButtonBlockedPermissions = 'button-blocked-permissions',
    ButtonCameraStepCantSee = 'button-camera-step-cant-see',
    ButtonCameraStepNo = 'button-camera-step-no',
    ButtonCameraStepYes = 'button-camera-step-yes',
    ButtonChooseAnother = 'button-choose-another',
    ButtonDisconnectAll = 'button-disconnect-all',
    ButtonFlipCamera = 'button-flip-camera',
    ButtonFreeDialNext = 'button-free-dial-next',
    ButtonGetStarted = 'button-get-started',
    ButtonJoinMeeting = 'button-join-meeting',
    ButtonLeave = 'button-leave',
    ButtonLock = 'button-lock',
    ButtonLowerHand = 'button-lower-hand',
    ButtonMeetingDeviceSettings = 'button-meeting-device-settings',
    ButtonMicrophoneStepCantHear = 'button-speaker-step-cant-hear',
    ButtonMicrophoneStepNo = 'button-speaker-step-no',
    ButtonMicrophoneStepYes = 'button-speaker-step-yes',
    ButtonOneTimeConfirmationModalNo = 'button-one-time-confirmation-modal-no',
    ButtonOneTimeConfirmationModalYes = 'button-one-time-confirmation-modal-yes',
    ButtonRaiseHand = 'button-raise-hand',
    ButtonRejoin = 'button-rejoin',
    ButtonReloadPage = 'button-reload-page',
    ButtonSetExtension = 'button-set-extension',
    ButtonSetName = 'button-set-name',
    ButtonSetPin = 'button-set-pin',
    ButtonSettingsCancel = 'button-settings-cancel',
    ButtonSettingsSave = 'button-settings-save',
    ButtonSpeakerStepCantHear = 'button-speaker-step-cant-hear',
    ButtonSpeakerStepNo = 'button-speaker-step-no',
    ButtonSpeakerStepYes = 'button-speaker-step-yes',
    ButtonUnlock = 'button-unlock',
    ErrorMeetingScreen = 'error-meeting-screen',
    ErrorScreen = 'error-screen',
    ErrorScreenCode = 'error-screen-code',
    InMeetingVideoWrapper = 'in-meeting-video-wrapper',
    InputExtension = 'input-extension',
    InputFreeDialId = 'input-free-dial-id',
    InputName = 'input-name',
    InputPin = 'input-pin',
    JoinAsGuestLink = 'join-as-guest-link',
    LinkAboutPexip = 'link-about-pexip',
    LinkDownloadLog = 'link-download-log',
    LiveCaptions = 'live-captions',
    MediaModal = 'media-modal',
    ModalSettings = 'modal-settings',
    NotFoundMessage = 'not-found-message',
    OneTimeConfirmationModal = 'one-time-confirmation-modal',
    ParticipantPanelExternal = 'participant-panel-external',
    ParticipantPanelHandRaised = 'participant-panel-hand-raised',
    ParticipantPanelInMeeting = 'participant-panel-in-meeting',
    ParticipantPanelWaitingInLobby = 'participant-panel-waiting-in-lobby',
    PermissionsRejectedHeader = 'permissions-rejected-header',
    SelectEffectBlur = 'select-effect-blur',
    SelectEffectNone = 'select-effect-none',
    SelectEffectOverlay = 'select-effect-overlay',
    StepHeaderCamera = 'step-header-camera',
    StepHeaderMicrophone = 'step-header-microphone',
    StepHeaderSpeaker = 'step-header-speaker',
    TermsAndServicesLink = 'terms-and-services-link',
    ToastAllGuestsMutedByHost = 'toast-all-guests-muted-by-host',
    ToastAllGuestsUnmutedByHost = 'toast-all-guests-unmuted-by-host',
    ToggleLiveCaptions = 'toggle-live-captions',
    UserMenuAboutPexipModal = 'user-menu-about-pexip-model',
    UserMenuAddParticipant = 'user-menu-add-participant',
    UserMenuButton = 'user-menu-button',
    UserNamePage = 'user-name-page',
    UserNameStep = 'user-name-step',
    UserNameStepInputForm = 'user-name-step-input-form',
    UserNameStepTextInput = 'user-name-step-text-input',
}
