import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {IconTypes, Bar} from '@pexip/components';
import type {Quality} from '@pexip/peer-connection-stats';

import {TransparentCallQualityIndicator} from '../../CallQualityIndicator/CallQualityIndicatorButton.view';
import type {AutoHideButtonCallbacks} from '../../../types';
import {SelfviewButton} from '../../SelfviewButton/SelfviewButton.view';
import {stopMouseEventPropagation} from '../../../utils/domEventsHandles';

import styles from './InMeetingSelfview.module.scss';

export const InMeetingSelfviewFooter: React.FC<{
    autoHideProps: AutoHideButtonCallbacks;
    isAudioInputMuted?: boolean;
    isAudioInputMissing?: boolean;
    isMobileDevice?: boolean;
    quality: Quality;
    onCallQualityClick: () => void;
    onToggleAudioClick: () => void;
    toggleFacingMode?: () => void;
}> = ({
    autoHideProps,
    isAudioInputMuted,
    isAudioInputMissing,
    isMobileDevice,
    quality,
    onCallQualityClick,
    onToggleAudioClick,
    toggleFacingMode,
}) => {
    const {t} = useTranslation();

    return (
        <Bar className={styles.footer} position="absoluteBottom">
            <SelfviewButton
                ariaLabel={
                    isAudioInputMuted
                        ? t('media.unmute-microphone', 'Unmute microphone')
                        : t('media.mute-microphone', 'Mute microphone')
                }
                onClick={onToggleAudioClick}
                hasError={isAudioInputMuted}
                isDisabled={isAudioInputMissing}
                source={
                    isAudioInputMuted
                        ? IconTypes.IconMicrophoneOff
                        : IconTypes.IconMicrophoneOn
                }
                onPointerDownCapture={stopMouseEventPropagation(true)}
                isMobileDevice={isMobileDevice}
                className={cx(isMobileDevice && 'mr-1')}
                {...autoHideProps}
            />
            {isMobileDevice && (
                <SelfviewButton
                    onClick={toggleFacingMode}
                    source={IconTypes.IconFlipCamera}
                    isMobileDevice={isMobileDevice}
                    iconClassName={styles.flipCameraIcon}
                    {...autoHideProps}
                />
            )}
            <SelfviewButton
                onClick={onCallQualityClick}
                isMobileDevice={isMobileDevice}
                source={IconTypes.IconConnectionIndicatorHigh}
                className={cx(isMobileDevice && 'ml-1')}
                {...autoHideProps}
            >
                <TransparentCallQualityIndicator
                    quality={quality}
                    isPhone={isMobileDevice}
                    tooltipText={t(
                        'meeting.connection-quality',
                        'Connection quality',
                    )}
                    tooltipPosition="topStart"
                    onClick={onCallQualityClick}
                    tooltipClassName={styles.callQualityIndicatorTooltip}
                    stopPointerDownEventPropagation
                    {...autoHideProps}
                />
            </SelfviewButton>
        </Bar>
    );
};
