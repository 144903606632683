import React from 'react';
import {useTranslation} from 'react-i18next';

import type {InMeetingUI} from '@pexip/media-components';

import type {UserMenuViewProps} from '../views/UserMenu/UserMenu.view';
import {UserMenuView} from '../views/UserMenu/UserMenu.view';
import {DIRTY, VERSION} from '../constants';
import {useUserMenuContent} from '../hooks/useUserMenuContent';
import {useConfig} from '../config';

export const UserMenu: React.FC<
    Pick<UserMenuViewProps, 'autoHideProps' | 'variant'> & {
        isInMeeting?: boolean;
        inMeetingUI?: InMeetingUI;
    }
> = ({isInMeeting = false, inMeetingUI, ...props}) => {
    const {i18n} = useTranslation();
    const {menuContent, isAboutOpen, setAboutOpen} = useUserMenuContent(
        isInMeeting,
        inMeetingUI,
    );
    const [termsAndConditions] = useConfig('termsAndConditions');
    return (
        <UserMenuView
            isAboutOpen={isAboutOpen}
            version={`${VERSION}${DIRTY}`}
            menuContent={menuContent}
            setAboutOpen={setAboutOpen}
            termsOfServiceUrl={
                termsAndConditions?.[i18n.language] ?? termsAndConditions?.en
            }
            {...props}
        />
    );
};
