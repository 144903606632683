// src/logger.ts
import log from "@pexip/logger";
var logger = log.child({ name: "signal" });

// src/buffer.ts
var createBuffer = (size) => {
  let buffer = new Array();
  return {
    add: (value) => {
      if (size === 0) {
        return [];
      } else if (buffer.length < size) {
        buffer.splice(size, 0, value);
      } else {
        buffer = [...buffer.slice(1), value];
      }
    },
    entries: () => buffer.slice(0),
    forEach: (cb) => {
      buffer.forEach(cb);
    }
  };
};

// src/signal.ts
var NAMELESS_OBSERVER = "Anonymous";
var NAMELESS_SIGNAL = "AnonymousSignal";
function getName(observer) {
  return observer.name || NAMELESS_OBSERVER;
}
function createSignal(options = {}) {
  const {
    allowEmittingWithoutObserver = false,
    variant = "generic",
    bufferSize = 2,
    name = NAMELESS_SIGNAL
  } = options;
  const observers = /* @__PURE__ */ new Set();
  const contexts = /* @__PURE__ */ new WeakMap();
  const onces = /* @__PURE__ */ new WeakSet();
  const buffers = variant !== "generic" ? createBuffer(
    variant === "behavior" ? 1 : bufferSize
  ) : void 0;
  function emitOne(observer, subject) {
    try {
      const context = contexts.get(observer);
      logger.trace(
        { signal: name, subject, observer, context },
        `Emitting a subject to observer ${getName(observer)}`
      );
      observer.call(context, subject);
      if (onces.has(observer)) {
        remove(observer);
      }
    } catch (e) {
      logger.error(
        { signal: name, error: e, observer, subject },
        `emit with error for observer ${getName(observer)}`
      );
      if (e instanceof RangeError) {
        throw new RangeError(
          `RangeError: Possible recursive call when calling ${getName(
            observer
          )} for ${name}`
        );
      }
      throw e;
    }
  }
  const add = (observer, context) => {
    if (observers.has(observer)) {
      const msg = `Observer ${getName(observer)} has already been added!`;
      logger.error({ signal: name, observer }, msg);
      throw new Error(`DuplicatedObserver: ${msg}`);
    }
    logger.trace(
      { signal: name, observer },
      `Adding ${getName(observer)} to ${name}`
    );
    observers.add(observer);
    if (context) {
      contexts.set(observer, context);
    }
    buffers?.forEach((subject) => {
      emitOne(observer, subject);
    });
    return () => remove(observer);
  };
  const addOnce = (observer, context) => {
    if (onces.has(observer)) {
      const msg = `${getName(
        observer
      )} has already been added once to ${name}!`;
      logger.error({ signal: name, observer }, msg);
      throw new Error(`NoOnceAgain: ${msg}`);
    }
    onces.add(observer);
    return add(observer, context);
  };
  const remove = (observer) => {
    if (!observers.delete(observer)) {
      logger.error(
        { signal: name, observer },
        `Unable to remove observer ${getName(observer)}`
      );
      throw new Error(`UnableToRemove: ${getName(observer)}`);
    }
    onces.delete(observer);
    contexts.delete(observer);
    logger.trace(
      { signal: name, observer },
      `Removed ${getName(observer)} from ${name}`
    );
  };
  const size = () => observers.size;
  function emit(subject) {
    if (buffers) {
      buffers.add(subject);
    } else if (!observers.size && !allowEmittingWithoutObserver) {
      logger.warn(
        { signal: name, subject },
        `Emitting ${name} without any observer! This may be a mistake.`
      );
    }
    observers.forEach((obs) => {
      emitOne(obs, subject);
    });
  }
  return {
    name,
    get size() {
      return size();
    },
    add,
    addOnce,
    remove,
    emit
  };
}

// src/combine.ts
var combine = (...signals) => {
  const latest = Array.from(
    signals,
    (_) => void 0
  );
  let detachCombinedSignals = [];
  const attach = () => {
    detachCombinedSignals = signals.map(
      (signal, i) => signal.add((value) => {
        latest[i] = value;
        combinedSignal.emit(latest);
      })
    );
  };
  const detach = () => {
    detachCombinedSignals.forEach((detach2) => detach2());
  };
  const combinedSignal = createSignal();
  const combinedSignalProxy = new Proxy(combinedSignal, {
    get: (target, p, args) => {
      switch (p) {
        case "add":
          return (...args2) => {
            const value = target[p](...args2);
            if (detachCombinedSignals.length === 0 && combinedSignal.size > 0) {
              attach();
            }
            return value;
          };
        case "remove":
          return (...args2) => {
            const value = target[p](...args2);
            if (combinedSignal.size === 0) {
              detach();
            }
            return value;
          };
        default:
          return Reflect.get(target, p, args);
      }
    }
  });
  return combinedSignalProxy;
};
export {
  combine,
  createSignal
};
