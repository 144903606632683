import {ValidationError, NonJSONResponseError} from '../errors';

import type {Endpoints} from './types';
import {
    validateAckBody,
    validateAck200,
    validateAck403,
    validateDisconnectCall200,
    validateDisconnectCall403,
    validateDisconnectCall502,
    validateDisconnectCall503,
    validateDtmfBody,
    validateDtmf200,
    validateDtmf403,
    validateNewCandidateBody,
    validateNewCandidate200,
    validateNewCandidate403,
    validateNewCandidate502,
    validateUpdateBody,
    validateUpdate200,
    validateUpdate403,
    validateUpdate502,
    validateUpdate503,
    validateUpdate520,
    validateStatisticsBody,
    validateStatistics200,
    validateStatistics403,
} from './validation';

type AckResponse =
    | {status: 200; data: Endpoints['ack']['200']}
    | {status: 403; data: Endpoints['ack']['403']};
export async function ack({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: Endpoints['ack']['Params'];
    body: Endpoints['ack']['Body'];
    host: string;
}): Promise<AckResponse> {
    if (!validateAckBody(body)) {
        throw new ValidationError(validateAckBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/calls/${encodeURIComponent(params.callUuid)}/ack`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateAck200(data)) {
                throw new ValidationError(validateAck200.errors);
            }
            return {status: res.status, data: data as Endpoints['ack']['200']};
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateAck403(data)) {
                throw new ValidationError(validateAck403.errors);
            }
            return {status: res.status, data: data as Endpoints['ack']['403']};
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type AckMap = Endpoints['ack'];

type DisconnectCallResponse =
    | {status: 200; data: Endpoints['disconnect_call']['200']}
    | {status: 403; data: Endpoints['disconnect_call']['403']}
    | {status: 502; data: Endpoints['disconnect_call']['502']}
    | {status: 503; data: Endpoints['disconnect_call']['503']};
export async function disconnectCall({
    fetcher,
    params,
    host,
}: {
    fetcher: typeof fetch;
    params: Endpoints['disconnect_call']['Params'];
    host: string;
}): Promise<DisconnectCallResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/calls/${encodeURIComponent(params.callUuid)}/disconnect`,
        {
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateDisconnectCall200(data)) {
                throw new ValidationError(validateDisconnectCall200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['disconnect_call']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateDisconnectCall403(data)) {
                throw new ValidationError(validateDisconnectCall403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['disconnect_call']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 502) {
        const data = await res.text();
        if (!validateDisconnectCall502(data)) {
            throw new ValidationError(validateDisconnectCall502.errors);
        }
        return {status: res.status, data};
    }

    if (res.status === 503) {
        const data = await res.text();
        if (!validateDisconnectCall503(data)) {
            throw new ValidationError(validateDisconnectCall503.errors);
        }
        return {status: res.status, data};
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type DisconnectCallMap = Endpoints['disconnect_call'];

type DtmfResponse =
    | {status: 200; data: Endpoints['dtmf']['200']}
    | {status: 403; data: Endpoints['dtmf']['403']};
export async function dtmf({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: Endpoints['dtmf']['Params'];
    body: Endpoints['dtmf']['Body'];
    host: string;
}): Promise<DtmfResponse> {
    if (!validateDtmfBody(body)) {
        throw new ValidationError(validateDtmfBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/calls/${encodeURIComponent(params.callUuid)}/dtmf`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateDtmf200(data)) {
                throw new ValidationError(validateDtmf200.errors);
            }
            return {status: res.status, data: data as Endpoints['dtmf']['200']};
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateDtmf403(data)) {
                throw new ValidationError(validateDtmf403.errors);
            }
            return {status: res.status, data: data as Endpoints['dtmf']['403']};
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type DtmfMap = Endpoints['dtmf'];

type NewCandidateResponse =
    | {status: 200; data: Endpoints['new_candidate']['200']}
    | {status: 403; data: Endpoints['new_candidate']['403']}
    | {status: 502; data: Endpoints['new_candidate']['502']};
export async function newCandidate({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: Endpoints['new_candidate']['Params'];
    body: Endpoints['new_candidate']['Body'];
    host: string;
}): Promise<NewCandidateResponse> {
    if (!validateNewCandidateBody(body)) {
        throw new ValidationError(validateNewCandidateBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/calls/${encodeURIComponent(params.callUuid)}/new_candidate`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateNewCandidate200(data)) {
                throw new ValidationError(validateNewCandidate200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['new_candidate']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateNewCandidate403(data)) {
                throw new ValidationError(validateNewCandidate403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['new_candidate']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 502) {
        const data = await res.text();
        if (!validateNewCandidate502(data)) {
            throw new ValidationError(validateNewCandidate502.errors);
        }
        return {status: res.status, data};
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type NewCandidateMap = Endpoints['new_candidate'];

type UpdateResponse =
    | {status: 200; data: Endpoints['update']['200']}
    | {status: 403; data: Endpoints['update']['403']}
    | {status: 502; data: Endpoints['update']['502']}
    | {status: 503; data: Endpoints['update']['503']}
    | {status: 520; data: Endpoints['update']['520']};
export async function update({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: Endpoints['update']['Params'];
    body: Endpoints['update']['Body'];
    host: string;
}): Promise<UpdateResponse> {
    if (!validateUpdateBody(body)) {
        throw new ValidationError(validateUpdateBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/calls/${encodeURIComponent(params.callUuid)}/update`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateUpdate200(data)) {
                throw new ValidationError(validateUpdate200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['update']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateUpdate403(data)) {
                throw new ValidationError(validateUpdate403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['update']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 502) {
        const data = await res.text();
        if (!validateUpdate502(data)) {
            throw new ValidationError(validateUpdate502.errors);
        }
        return {status: res.status, data};
    }

    if (res.status === 503) {
        const data = await res.text();
        if (!validateUpdate503(data)) {
            throw new ValidationError(validateUpdate503.errors);
        }
        return {status: res.status, data};
    }

    if (res.status === 520) {
        const data = await res.text();
        if (!validateUpdate520(data)) {
            throw new ValidationError(validateUpdate520.errors);
        }
        return {status: res.status, data};
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type UpdateMap = Endpoints['update'];

type StatisticsResponse =
    | {status: 200; data: Endpoints['statistics']['200']}
    | {status: 403; data: Endpoints['statistics']['403']};
export async function statistics({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: Endpoints['statistics']['Params'];
    body: Endpoints['statistics']['Body'];
    host: string;
}): Promise<StatisticsResponse> {
    if (!validateStatisticsBody(body)) {
        throw new ValidationError(validateStatisticsBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/calls/${encodeURIComponent(params.callUuid)}/statistics`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateStatistics200(data)) {
                throw new ValidationError(validateStatistics200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['statistics']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateStatistics403(data)) {
                throw new ValidationError(validateStatistics403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['statistics']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type StatisticsMap = Endpoints['statistics'];
