import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {logger} from './logger';
import {mergeTranslation} from './utils/mergeTranslation';

export const brandingLngs = new Map<string, string>();

const importBackend = {
    type: 'backend' as const,
    read<Namespace extends string>(
        language: string,
        namespace: Namespace,
        callback: (errorValue: unknown, translations: null | unknown) => void,
    ) {
        const defaultTranslationsImport = fetch(
            new URL(`./locales/${language}/${namespace}.json`, import.meta.url),
        )
            .then(req => req.json())
            .catch(() => {
                logger.warn(
                    {language, namespace, brandingLngs},
                    'failed to load built-in language',
                );
                return {};
            });

        if (brandingLngs.has(language)) {
            return Promise.all([
                defaultTranslationsImport,
                fetch(`${brandingLngs.get(language)}`).then(r => r.json()),
            ])
                .then(([defaultTranslations, brandedTranslations]) => {
                    callback(
                        null,
                        mergeTranslation(
                            defaultTranslations,
                            brandedTranslations,
                        ),
                    );
                })
                .catch(err => callback(err, null));
        }

        defaultTranslationsImport
            .then(resources => callback(null, resources))
            .catch(err => callback(err, null));
    },
};

export const DEFAULT_SUPPORTED_LANGUAGES = [
    'en',
    'cs',
    'cy',
    'da',
    'de',
    'es-ES',
    'es-US',
    'fr-CA',
    'fr-FR',
    'ga',
    'id',
    'it',
    'ja',
    'ko',
    'nb',
    'nl',
    'pl',
    'pt-BR',
    'sv',
    'th',
    'vi',
    'zh-Hans',
    'zh-Hant',
];

export function initI18next(supportedLngs: string[]) {
    return i18n
        .use(LanguageDetector)
        .use(importBackend)
        .use(initReactI18next)
        .init({
            supportedLngs,
            fallbackLng: {
                nn: ['nb'],
                fr: ['fr-FR'],
                pt: ['pt-BR'],
                zh: ['zh-Hans'],
                'en-GB': ['en'],
                'en-US': ['en'],
                default: ['en'],
            },
            detection: {
                order: ['querystring', 'navigator'],
                caches: [],
            },
            interpolation: {
                escapeValue: false,
            },
            react: {
                bindI18nStore: 'added',
                useSuspense: false,
            },
        });
}
