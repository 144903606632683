import React, {useCallback, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {Button, FontVariant, Text} from '@pexip/components';
import type {MenuItem} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import type {InMeetingParticipant} from '../../types';
import {ParticipantRowActions} from '../ParticipantRowActions/ParticipantRowActions.view';
import type {RowActionState} from '../ParticipantRowActions/ParticipantRowActions.module';

import {MultiCallList} from './MultiCallList.view';

import styles from './ParticipantRow.module.scss';

const ParticipantAdmit: React.FC<{admit?: () => void}> = ({admit}) => {
    const {t} = useTranslation();
    const [isLoading, setLoading] = useState(false);

    const handleAdmit = () => {
        setLoading(true);
        admit?.();
    };

    return (
        <Button
            aria-label={t('meeting.admit-participant', 'Admit participant')}
            onClick={handleAdmit}
            variant="secondary"
            isLoading={isLoading}
            isDisabled={isLoading}
            size="small"
            data-testid={TestId.ButtonAdmitParticipant}
            className="mr-1"
        >
            <Trans t={t} i18nKey="meeting.admit">
                Admit
            </Trans>
        </Button>
    );
};

const ParticipantDeny: React.FC<{kick?: () => void}> = ({kick}) => {
    const {t} = useTranslation();
    const [isLoading, setLoading] = useState(false);

    const handleKick = () => {
        setLoading(true);
        kick?.();
    };

    return (
        <Button
            aria-label={t('meeting.deny-participant', 'Deny participant')}
            onClick={handleKick}
            variant="secondary"
            isLoading={isLoading}
            isDisabled={isLoading}
            size="small"
            data-testid={TestId.ButtonDenyParticipant}
        >
            <Trans t={t} i18nKey="meeting.deny">
                Deny
            </Trans>
        </Button>
    );
};

export const ParticipantRow: React.FC<
    Pick<RowActionState, 'isSpeaking'> & {
        displayName: string;
        handleRemoveUserRequest: (
            participant: InMeetingParticipant,
            displayName: string,
        ) => void;
        handleTransfer?: (identity: string) => void;
        isCurrentPeer: boolean;
        canPerformHostActions?: boolean;
        isHost: boolean;
        participant: InMeetingParticipant;
        additionalMenuContent: MenuItem[];
    }
> = ({
    displayName,
    handleRemoveUserRequest,
    handleTransfer,
    isCurrentPeer,
    canPerformHostActions = true,
    isHost,
    isSpeaking,
    participant,
    additionalMenuContent,
}) => {
    const [isHover, setIsHover] = useState(false);

    const onMouseEnter = useCallback(() => setIsHover(true), []);
    const onMouseLeave = useCallback(() => setIsHover(false), []);

    const {calls} = participant;

    const shouldDisplayChildCalls = useMemo(
        () => Boolean(calls && calls?.length > 1),
        [calls],
    );

    return (
        <div>
            <div
                key={participant.identity}
                className={styles.participantRow}
                onMouseEnter={onMouseEnter}
                onFocus={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onBlur={onMouseLeave}
                data-testid={TestId.ParticipantRow}
            >
                <div className={styles.participant}>
                    <Text
                        fontVariant={FontVariant.Body}
                        isTruncated
                        title={displayName}
                        translate="no"
                    >
                        {displayName}
                    </Text>
                </div>
                <div className={styles.actionsWrapper}>
                    <div className={styles.scrim} />
                    {participant.participantType === 'admit' ? (
                        <>
                            <ParticipantAdmit admit={participant?.admit} />
                            <ParticipantDeny kick={participant?.kick} />
                        </>
                    ) : (
                        <ParticipantRowActions
                            participant={participant}
                            canPerformHostActions={canPerformHostActions}
                            isHost={isHost}
                            isSpeaking={
                                shouldDisplayChildCalls ? false : isSpeaking
                            }
                            isHover={isHover}
                            isCurrentPeer={isCurrentPeer}
                            handleRemoveUserRequest={handleRemoveUserRequest}
                            handleTransfer={handleTransfer}
                            displayName={displayName}
                            hasChildCalls={shouldDisplayChildCalls}
                            onMouseLeave={onMouseLeave}
                            additionalMenuContent={additionalMenuContent}
                        />
                    )}
                </div>
            </div>
            {shouldDisplayChildCalls && (
                <MultiCallList calls={participant.calls} />
            )}
        </div>
    );
};
