import type {Dispatch, SetStateAction} from 'react';
import {useState, useEffect} from 'react';

import type {PreviewStreamController} from '@pexip/media';
import {DeniedDevices, UserMediaStatus} from '@pexip/media';
import {pipe} from '@pexip/utils';

import type {
    DeviceError,
    DeviceErrors,
    DeniedDevice,
    DeviceStatusInfo,
} from '../types';

import {useDeviceStatusInfo} from './useDeviceStatusInfo';

export const useDeviceErrorMessageState = () => {
    const [videoInputError, setVideoInputError] = useState<DeviceError>({
        title: '',
    });
    const [audioInputError, setAudioInputError] = useState<DeviceError>({
        title: '',
    });

    return {
        videoInputError,
        setVideoInputError,
        audioInputError,
        setAudioInputError,
    };
};

export const useSubscribeToInputError = (
    setAudioInputError: Dispatch<SetStateAction<DeviceError>>,
    setVideoInputError: Dispatch<SetStateAction<DeviceError>>,
    controller?: PreviewStreamController,
) => {
    useEffect(() => {
        return controller?.onAudioInputError(() => {
            setAudioInputError({
                title: 'Can’t use audio input device. Please select another one.',
            });
        });
    }, [controller, setAudioInputError]);

    useEffect(() => {
        return controller?.onVideoInputError(() => {
            setVideoInputError({
                title: 'Can’t use video input device. Please select another one.',
            });
        });
    }, [controller, setVideoInputError]);
};

export const buildErrorMessages = (
    deviceStatusInfo: DeviceStatusInfo,
    deviceErrors: DeviceErrors = {
        videoError: {
            title: '',
            description: '',
        },
        audioError: {
            title: '',
            description: '',
        },
    },
): DeviceErrors => {
    const {videoError, audioError} = deviceErrors;

    if (deviceStatusInfo.distinctiveTitles) {
        videoError.title = deviceStatusInfo.distinctiveTitles.videoError.title;
        videoError.description =
            deviceStatusInfo.distinctiveTitles.videoError.description;
        audioError.title = deviceStatusInfo.distinctiveTitles.audioError.title;
        audioError.description =
            deviceStatusInfo.distinctiveTitles.audioError.description;
    } else {
        if (deviceStatusInfo.error === 'video') {
            videoError.title = deviceStatusInfo.title;
            videoError.description = deviceStatusInfo.message;
        } else if (deviceStatusInfo.error === 'audio') {
            audioError.title = deviceStatusInfo.title;
            audioError.description = deviceStatusInfo.message;
        }
    }

    return {videoError, audioError};
};

const addDeniedDevice =
    (streamStatus: UserMediaStatus) =>
    (deviceErrors: DeviceErrors): DeviceErrors => {
        const {videoError, audioError} = deviceErrors;

        if (streamStatus === UserMediaStatus.PermissionsRejectedAudioInput) {
            audioError.deniedDevice = DeniedDevices.Microphone;
        }

        if (streamStatus === UserMediaStatus.PermissionsRejectedVideoInput) {
            videoError.deniedDevice = DeniedDevices.Camera;
        }

        if (streamStatus === UserMediaStatus.PermissionsRejected) {
            audioError.deniedDevice = DeniedDevices.Microphone;
            videoError.deniedDevice = DeniedDevices.Camera;
        }

        return {videoError, audioError};
    };

export const getDeviceErrorMessageSetter =
    (
        deviceStatusInfo: DeviceStatusInfo,
        setAudioInputError: Dispatch<SetStateAction<DeviceError>>,
        setVideoInputError: Dispatch<SetStateAction<DeviceError>>,
    ) =>
    (deviceErrors: DeviceErrors) => {
        const {videoError, audioError} = deviceErrors;

        const setVideoError = () => setVideoInputError(videoError);
        const setAudioError = () => setAudioInputError(audioError);

        const setError: Record<DeniedDevice, () => void> = {
            ['audio']: setAudioError,
            ['video']: setVideoError,
            ['audio-video']: () => {
                setAudioError();
                setVideoError();
            },
        };

        if (deviceStatusInfo.error) {
            return setError[deviceStatusInfo.error];
        }
    };

/**
 * Sets `DeviceError` when device is missing or its permissions are rejected.
 */
export const useDeviceErrorMessage = (
    setAudioInputError: Dispatch<SetStateAction<DeviceError>>,
    setVideoInputError: Dispatch<SetStateAction<DeviceError>>,
    streamStatus: UserMediaStatus,
) => {
    const deviceStatusInfo = useDeviceStatusInfo(streamStatus);

    useEffect(() => {
        const pipeline = pipe(
            buildErrorMessages,
            addDeniedDevice(streamStatus),
        );

        const {videoError, audioError} = pipeline(deviceStatusInfo);

        const setErrorMessage = getDeviceErrorMessageSetter(
            deviceStatusInfo,
            setAudioInputError,
            setVideoInputError,
        )({videoError, audioError});

        setErrorMessage?.();

        return () => {
            setAudioInputError({
                title: '',
            });
            setVideoInputError({
                title: '',
            });
        };
    }, [
        deviceStatusInfo,
        setAudioInputError,
        setVideoInputError,
        streamStatus,
    ]);
};
