import React, {useCallback, useRef, useState} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {
    VideoWrapper,
    Spinner,
    Video,
    IconTypes,
    Icon,
    Button,
} from '@pexip/components';
import {isMobileDevice} from '@pexip/media-components';

import {TestId} from '../../../test/testIds';
import {StreamStatus} from '../../types';

import styles from './MeetingVideo.module.scss';

const getWrapperTestId = (isPresentation: boolean, isPip: boolean) => {
    if (isPresentation) {
        return isPip ? TestId.PresentationPip : TestId.PresentationFull;
    }
    return isPip ? TestId.MeetingVideoPip : TestId.MeetingVideoFull;
};

export const MeetingVideo: React.FC<{
    mediaStream?: MediaStream;
    selectedAudioOutputDeviceId?: string;
    className?: string;
    isPresentation?: boolean;
    status: StreamStatus;
    isFullHeight?: boolean;
    isFullWidth?: boolean;
    handleVideoClick?: () => void;
    onVideoPlaying?: () => void;
    onPictureInPictureChange?: (isPip: boolean) => void;
    onDoubleClick?: () => void;
    isContainerWide?: boolean;
}> = ({
    mediaStream,
    selectedAudioOutputDeviceId,
    isPresentation = false,
    status,
    isFullHeight,
    isFullWidth,
    handleVideoClick,
    onVideoPlaying,
    onPictureInPictureChange,
    onDoubleClick,
    isContainerWide = false,
    ...props
}) => {
    const {t} = useTranslation();
    const videoComponentRef = useRef<React.ElementRef<typeof Video>>(null);
    const [videoStatus, setVideoStatus] = useState<
        'loading' | 'playing' | 'failed'
    >('loading');

    const isExpanded = status === StreamStatus.Expanded;
    const isExternal = status === StreamStatus.External;
    const isPip = status === StreamStatus.Pip;
    const wrapperTestId = getWrapperTestId(isPresentation, isPip);

    const handlePlaying = useCallback(() => {
        setVideoStatus(() => {
            onVideoPlaying?.();
            return 'playing';
        });
    }, [onVideoPlaying]);
    const handleFailedToResume = useCallback(() => {
        setVideoStatus('failed');
    }, []);
    const handleResume = useCallback(() => {
        videoComponentRef.current?.resume();
    }, []);

    return (
        <VideoWrapper
            className={cx(styles.videoWrapper, {
                [styles.centeredInWideContainer]: isContainerWide,
                [styles.expanded]: isExpanded,
            })}
            isFullHeight={isFullHeight}
            isFullWidth={isFullWidth}
            borderRadius={isExternal ? 'none' : 'box'}
            onDoubleClick={onDoubleClick}
            background={isExternal ? 'black' : 'none'}
            flexDirection="row"
            data-testid={wrapperTestId}
            {...props}
        >
            <Video
                ref={videoComponentRef}
                srcObject={mediaStream}
                onPlaying={handlePlaying}
                onFailedToResume={handleFailedToResume}
                onPictureInPictureChange={onPictureInPictureChange}
                onClick={handleVideoClick}
                disablePictureInPicture={isMobileDevice()}
                sinkId={
                    isPresentation ? undefined : selectedAudioOutputDeviceId
                }
                muted={isPresentation}
                data-testid={
                    isPresentation
                        ? TestId.VideoPresentation
                        : TestId.VideoMeeting
                }
                aria-label={
                    isPresentation
                        ? t(
                              'meeting.video-label-presentation',
                              'Live video of another participant’s presentation',
                          )
                        : t('meeting.video-label', 'Live video of the meeting')
                }
            />

            {(!mediaStream || videoStatus === 'loading') && (
                <Spinner
                    className={styles.overlay}
                    colorScheme="dark"
                    sizeModifier={isPip ? 'small' : 'medium'}
                />
            )}
            {videoStatus === 'failed' && (
                <Button
                    onClick={handleResume}
                    enhancerStart={<Icon source={IconTypes.IconPlayRound} />}
                    className={styles.overlay}
                >
                    {t('meeting.error', 'Click to resume the meeting video')}
                </Button>
            )}
        </VideoWrapper>
    );
};

export type MeetingVideoProps = React.ComponentProps<typeof MeetingVideo>;
