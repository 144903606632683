import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {Button, Icon, IconTypes, Text, Tooltip} from '@pexip/components';
import {noop} from '@pexip/utils';

import type {ParticipantCall} from '../../types';
import {SpeakingIndicator} from '../SpeakingIndicator/SpeakingIndicator.view';
import {TestId} from '../../../test/testIds';

import styles from './MultiCallList.module.scss';

/*
t('common.client-name[web-app]', 'Web App')
t('common.client-name[pexip-room]', 'Pexip Room')
t('common.client-name[mobile-app]', 'Mobile App')
t('common.client-name[session]', 'Session')
*/

const getClientName = (name?: string) => {
    if (
        !name ||
        !['web-app', 'pexip-room', 'mobile-app', 'session'].includes(name)
    ) {
        return 'session';
    }
    return name as 'session' | 'web-app' | 'pexip-room' | 'mobile-app';
};

export const MultiCallList: React.FC<{
    calls?: ParticipantCall[];
}> = ({calls}) => {
    const {t} = useTranslation();

    if (!calls) {
        return null;
    }

    return (
        <div className={styles.rows}>
            {calls?.map(call => (
                <div className={styles.row} key={call.identitySession}>
                    <div className={styles.lineWrapper}>
                        <div className={styles.lineTop}></div>
                        <div className={styles.lineBottom}></div>
                    </div>
                    <div className={styles.textAndIndicatorWrapper}>
                        <div className={styles.textWrapper}>
                            <Text className={styles.text} isTruncated>
                                {t(
                                    `common.client-name[${getClientName(
                                        call.callDisplayName?.key,
                                    )}]`,
                                    call.callDisplayName?.name,
                                )}{' '}
                                #{call.callDisplayName?.count}
                                {call.callDisplayName?.isMe && (
                                    <>
                                        {' ('}
                                        <Trans t={t} i18nKey="common.me">
                                            me
                                        </Trans>
                                        {')'}
                                    </>
                                )}
                            </Text>
                        </div>
                        <div className={styles.indicatorsWrapper}>
                            {call.isPresenting && (
                                <Tooltip
                                    text={t(
                                        'media.presentation.presenting',
                                        'Presenting',
                                    )}
                                    position="top"
                                >
                                    <Button
                                        className={styles.indicator}
                                        isActive
                                        modifier="square"
                                        onClick={noop}
                                        size="compact"
                                        variant="transparent"
                                        data-testid={
                                            TestId.ButtonPeerPresenting
                                        }
                                        noHover
                                    >
                                        <Icon
                                            className={styles.muteButtonIcon}
                                            source={
                                                IconTypes.IconPresentationOn
                                            }
                                            size="small"
                                        />
                                    </Button>
                                </Tooltip>
                            )}
                            {!call.video && (
                                <Tooltip
                                    text={t('media.muted', 'Muted')}
                                    position="top"
                                >
                                    <Button
                                        className={styles.indicator}
                                        isActive
                                        modifier="square"
                                        onClick={noop}
                                        size="compact"
                                        variant="transparent"
                                        data-testid={
                                            TestId.ButtonPeerMutedCamera
                                        }
                                        noHover
                                    >
                                        <Icon
                                            className={styles.muteButtonIcon}
                                            source={IconTypes.IconVideoOff}
                                            size="small"
                                        />
                                    </Button>
                                </Tooltip>
                            )}
                            <div className={styles.audioIndicators}>
                                {call?.isSpeaking && call.audio && (
                                    <SpeakingIndicator
                                        data-testid={TestId.SpeakingIndicator}
                                        className={styles.indicator}
                                    />
                                )}
                                {!call.audio && (
                                    <Tooltip
                                        text={t('media.muted', 'Muted')}
                                        position="top"
                                    >
                                        <Button
                                            className={styles.indicator}
                                            isActive
                                            modifier="square"
                                            onClick={noop}
                                            size="compact"
                                            variant="transparent"
                                            data-testid={TestId.ButtonPeerMuted}
                                            noHover={true}
                                        >
                                            <Icon
                                                className={
                                                    styles.muteButtonIcon
                                                }
                                                source={
                                                    IconTypes.IconMicrophoneOff
                                                }
                                                size="small"
                                            />
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
