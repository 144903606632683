import {useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {downloadLog, getFileName} from '@pexip/logger';
import type {InMeetingUI} from '@pexip/media-components';

import {TestId} from '../../test/testIds';

import {useMe} from './useMe';

const downloadLogWithName = () => downloadLog(getFileName('infinity-connect'));

export const useUserMenuContent = (
    isInMeeting: boolean,
    inMeetingUI?: InMeetingUI,
) => {
    const [isAboutOpen, setAboutOpen] = useState(false);
    const {t} = useTranslation();
    const me = useMe();
    const canControl = me?.canControl;
    const isAddParticipantOpen = inMeetingUI?.panelsState.openAddParticipant;
    const toggleInMeetingPanels = inMeetingUI?.togglePanels;

    const menuContent = useMemo(() => {
        let menu = [
            {
                label: t('common.download-log', 'Download log'),
                testId: TestId.LinkDownloadLog,
                clickAction: downloadLogWithName,
                role: 'menuitem',
            },
            {
                label: t('common.about-pexip', 'About Pexip'),
                testId: TestId.LinkAboutPexip,
                clickAction: () => setAboutOpen(true),
                role: 'menuitem',
            },
        ];

        if (isInMeeting && canControl) {
            menu = [
                {
                    label: t('add-participant.submit', 'Add participant'),
                    testId: TestId.UserMenuAddParticipant,
                    clickAction: () => {
                        !isAddParticipantOpen && toggleInMeetingPanels?.('add');
                    },
                    role: 'menuitem',
                },
                ...menu,
            ];
        }
        return [menu];
    }, [
        isInMeeting,
        canControl,
        isAddParticipantOpen,
        toggleInMeetingPanels,
        t,
    ]);

    return {
        menuContent,
        isAboutOpen,
        setAboutOpen,
    };
};
