import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    Accordion,
    Button,
    FontVariant,
    IconTypes,
    Row,
    Text,
    TextLink,
} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';

import {DeviceSelect} from '../DeviceSelect/DeviceSelect.view';
import {TestId} from '../../../test/testIds';

import styles from './MicrophoneMonitorModal.module.scss';

export const PlayingBackDeviceSelection: React.FC<{
    audioInputs: MediaDeviceInfoLike[];
    audioOutputs: MediaDeviceInfoLike[];
    selectedAudioInput?: MediaDeviceInfoLike;
    selectedAudioOutput?: MediaDeviceInfoLike;
    onAudioInputChange: (device: MediaDeviceInfoLike) => void;
    onOutputInputChange: (device: MediaDeviceInfoLike) => void;
    tryAgain?: () => void;
}> = ({
    audioInputs,
    audioOutputs,
    selectedAudioInput,
    selectedAudioOutput,
    onOutputInputChange,
    onAudioInputChange,
    tryAgain,
}) => {
    const {t} = useTranslation();

    return (
        <Row className={styles.playingBackDeviceSelectionContent}>
            <Accordion
                className={styles.tryThis}
                title={t(
                    'media.if-you-dont-hear-yourself-try-this',
                    `If you don't hear yourself, try this`,
                )}
                dataTestId={TestId.ButtonTestAudioInputTryAgain}
            >
                <Row>
                    <ol className={styles.tryThisOrderedList}>
                        <li>
                            <Text>
                                {t(
                                    'media.make-sure-you-have-the-correct-microphone',
                                    'Make sure you have the correct microphone',
                                )}
                            </Text>
                            <Row>
                                <DeviceSelect
                                    isDisabled={false}
                                    onDeviceChange={onAudioInputChange}
                                    mediaDeviceInfoLike={selectedAudioInput}
                                    iconType={IconTypes.IconMicrophoneOn}
                                    label={t(
                                        'settings.device-select.select-microphone',
                                        'Select microphone',
                                    )}
                                    devices={audioInputs}
                                    className={styles.audioSelect}
                                    data-testid={
                                        TestId.SelectTestAudioInputPlayback
                                    }
                                />
                            </Row>
                            {audioOutputs.length > 0 && (
                                <>
                                    <Text>
                                        {t(
                                            'media.make-sure-you-have-the-correct-speaker',
                                            'Make sure you have the correct speaker',
                                        )}
                                    </Text>
                                    <Row>
                                        <DeviceSelect
                                            isDisabled={false}
                                            onDeviceChange={onOutputInputChange}
                                            mediaDeviceInfoLike={
                                                selectedAudioOutput
                                            }
                                            iconType={IconTypes.IconSpeakerOn}
                                            label={t(
                                                'settings.device-select.select-speaker',
                                                'Select speaker',
                                            )}
                                            devices={audioOutputs}
                                            className={styles.audioSelect}
                                            data-testid={
                                                TestId.SelectTestAudioInputSpeakerPlayback
                                            }
                                        />
                                    </Row>
                                </>
                            )}
                            <Row className="justify-content-end">
                                <Button
                                    onClick={tryAgain}
                                    data-testid={
                                        TestId.ButtonTestAudioInputAgain
                                    }
                                >
                                    {t('common.try-again', 'Try again')}
                                </Button>
                            </Row>
                        </li>
                        <li>
                            {t(
                                'media.make-sure-the-volume-is-turned-up-on-your-device',
                                'Make sure the volume is turned up on your device.',
                            )}
                        </li>
                        <li>
                            {t(
                                'media.do you have headphones connected to-your-device',
                                'Do you have headphones connected to your device? Make sure the microphone of the headphones is near your mouth.',
                            )}
                        </li>
                        <li>
                            {t(
                                'media.you-may-need-to-connect-speakers-or-headphones',
                                'If you are using a desktop computer, you may need to connect speakers or headphones.',
                            )}
                        </li>
                    </ol>
                </Row>
                <Row>
                    <Text>
                        <Trans t={t} i18nKey="media.still-having-issues">
                            Still having issues?{' '}
                            <TextLink
                                variant="standard"
                                isUnderlined
                                fontVariant={FontVariant.BodyBold}
                            >
                                Troubleshooting guide
                            </TextLink>
                        </Trans>
                    </Text>
                </Row>
            </Accordion>
        </Row>
    );
};
