// src/media.ts
import {
  subscribe,
  MediaEventType,
  MediaDeviceFailure as MediaDeviceFailure2,
  createStreamTrackEventSubscriptions,
  getDevices as getDevices2,
  isRequestedResolution as isRequestedResolution2,
  mergeConstraints,
  shouldRequestDevice
} from "@pexip/media-control";
import { createAsyncQueue, isEmpty as isEmpty4 } from "@pexip/utils";

// src/status.ts
import { getInputDevicePermissionState } from "@pexip/media-control";

// src/types.ts
var UserMediaStatus = /* @__PURE__ */ ((UserMediaStatus2) => {
  UserMediaStatus2["Initial"] = "initial";
  UserMediaStatus2["InitialPermissionsGranted"] = "initial-permissions-granted";
  UserMediaStatus2["InitialPermissionsNotGranted"] = "initial-permissions-not-granted";
  UserMediaStatus2["InitialPermissionsVideoInputDenied"] = "initial-permissions-videoinput-denied";
  UserMediaStatus2["InitialPermissionsAudioInputDenied"] = "initial-permissions-audioinput-denied";
  UserMediaStatus2["InitialPermissionsVideoInputGranted"] = "initial-permissions-videoinput-granted";
  UserMediaStatus2["InitialPermissionsAudioInputGranted"] = "initial-permissions-audioinput-granted";
  UserMediaStatus2["InitialPermissionsGrantedVideoInputDenied"] = "initial-permissions-audioinput-granted-videoinput-denied";
  UserMediaStatus2["InitialPermissionsGrantedAudioInputDenied"] = "initial-permissions-videoinput-granted-audioinput-denied";
  UserMediaStatus2["NoDevicesFound"] = "no-devices-found";
  UserMediaStatus2["NoVideoDevicesFound"] = "no-video-devices-found";
  UserMediaStatus2["NoAudioDevicesFound"] = "no-audio-devices-found";
  UserMediaStatus2["AudioDeviceNotFound"] = "audio-device-not-found";
  UserMediaStatus2["VideoDeviceNotFound"] = "video-device-not-found";
  UserMediaStatus2["AudioVideoDevicesNotFound"] = "audio-video-devices-not-found";
  UserMediaStatus2["PermissionsGranted"] = "permissions-granted";
  UserMediaStatus2["PermissionsGrantedFallback"] = "permissions-granted-fallback-devices";
  UserMediaStatus2["PermissionsGrantedFallbackAudioinput"] = "permissions-granted-fallback-audioinput";
  UserMediaStatus2["PermissionsGrantedFallbackVideoinput"] = "permissions-granted-fallback-videoinput";
  UserMediaStatus2["PermissionsRejected"] = "permissions-rejected";
  UserMediaStatus2["PermissionsRejectedAudioInput"] = "permissions-rejected-audioinput";
  UserMediaStatus2["PermissionsRejectedVideoInput"] = "permissions-rejected-videoinput";
  UserMediaStatus2["PermissionsOnlyAudioinput"] = "permissions-only-audioinput";
  UserMediaStatus2["PermissionsOnlyAudioinputNoVideoDevices"] = "permissions-only-audioinput-no-video-devices";
  UserMediaStatus2["PermissionsOnlyAudioinputFallback"] = "permissions-only-fallback-audioinput";
  UserMediaStatus2["PermissionsOnlyAudioinputFallbackNoVideoDevices"] = "permissions-only-fallback-audioinput-no-video-devices";
  UserMediaStatus2["PermissionsOnlyVideoinput"] = "permissions-only-videoinput";
  UserMediaStatus2["PermissionsOnlyVideoinputNoAudioDevices"] = "permissions-only-videoinput-no-audio-devices";
  UserMediaStatus2["PermissionsOnlyVideoinputFallback"] = "permissions-only-fallback-videoinput";
  UserMediaStatus2["PermissionsOnlyVideoinputFallbackNoAudioDevices"] = "permissions-only-fallback-videoinput-no-audio-devices";
  UserMediaStatus2["AudioDeviceInUse"] = "audio-device-in-use";
  UserMediaStatus2["VideoDeviceInUse"] = "video-device-in-use";
  UserMediaStatus2["DevicesInUse"] = "devices-in-use";
  UserMediaStatus2["Overconstrained"] = "overconstrained";
  UserMediaStatus2["VideoOverconstrained"] = "video-overconstrained";
  UserMediaStatus2["AudioOverconstrained"] = "audio-overconstrained";
  UserMediaStatus2["InvalidConstraints"] = "invalid-constraints";
  UserMediaStatus2["InvalidVideoConstraints"] = "invalid-video-constraints";
  UserMediaStatus2["InvalidAudioConstraints"] = "invalid-audio-constraints";
  UserMediaStatus2["NotSupportedError"] = "not-supported-error";
  UserMediaStatus2["NotSupportedErrorOnlyVideoInput"] = "not-supported-error-only-video";
  UserMediaStatus2["NotSupportedErrorOnlyAudioInput"] = "not-supported-error-only-audio";
  UserMediaStatus2["UnknownError"] = "unknown-error";
  UserMediaStatus2["UnknownErrorOnlyAudioinput"] = "unknown-error-only-audioinput";
  UserMediaStatus2["UnknownErrorOnlyVideoinput"] = "unknown-error-only-videoinput";
  return UserMediaStatus2;
})(UserMediaStatus || {});
var DeniedDevices = /* @__PURE__ */ ((DeniedDevices2) => {
  DeniedDevices2["Microphone"] = "microphone";
  DeniedDevices2["Camera"] = "camera";
  DeniedDevices2["Both"] = "microphone-and-camera";
  return DeniedDevices2;
})(DeniedDevices || {});

// src/status.ts
var isFallbackVideo = (status) => [
  "permissions-only-fallback-videoinput" /* PermissionsOnlyVideoinputFallback */,
  "permissions-only-fallback-videoinput-no-audio-devices" /* PermissionsOnlyVideoinputFallbackNoAudioDevices */,
  "permissions-granted-fallback-devices" /* PermissionsGrantedFallback */,
  "permissions-granted-fallback-videoinput" /* PermissionsGrantedFallbackVideoinput */
].includes(status);
var isFallbackAudio = (status) => [
  "permissions-only-fallback-audioinput" /* PermissionsOnlyAudioinputFallback */,
  "permissions-only-fallback-audioinput-no-video-devices" /* PermissionsOnlyAudioinputFallbackNoVideoDevices */,
  "permissions-granted-fallback-devices" /* PermissionsGrantedFallback */,
  "permissions-granted-fallback-audioinput" /* PermissionsGrantedFallbackAudioinput */
].includes(status);
var isFallback = (status) => status === "permissions-granted-fallback-devices" /* PermissionsGrantedFallback */ || isFallbackAudio(status) || isFallbackVideo(status);
var hasNoDevice = (status) => "no-devices-found" /* NoDevicesFound */ === status;
var hasNoAudioDevices = (status) => hasNoDevice(status) || status === "no-audio-devices-found" /* NoAudioDevicesFound */ || isGrantedOnlyVideoNoAudioDevices(status);
var hasNoVideoDevices = (status) => hasNoDevice(status) || status === "no-video-devices-found" /* NoVideoDevicesFound */ || isGrantedOnlyAudioNoVideoDevices(status);
var isGrantedOnlyVideoNoAudioDevices = (status) => [
  "permissions-only-videoinput-no-audio-devices" /* PermissionsOnlyVideoinputNoAudioDevices */,
  "permissions-only-fallback-videoinput-no-audio-devices" /* PermissionsOnlyVideoinputFallbackNoAudioDevices */
].includes(status);
var isGrantedOnlyVideo = (status) => [
  "permissions-only-videoinput" /* PermissionsOnlyVideoinput */,
  "permissions-only-fallback-videoinput" /* PermissionsOnlyVideoinputFallback */,
  "permissions-rejected-audioinput" /* PermissionsRejectedAudioInput */,
  "audio-device-in-use" /* AudioDeviceInUse */
].includes(status);
var isGrantedOnlyAudioNoVideoDevices = (status) => [
  "permissions-only-audioinput-no-video-devices" /* PermissionsOnlyAudioinputNoVideoDevices */,
  "permissions-only-fallback-audioinput-no-video-devices" /* PermissionsOnlyAudioinputFallbackNoVideoDevices */
].includes(status);
var isGrantedOnlyAudio = (status) => [
  "permissions-only-audioinput" /* PermissionsOnlyAudioinput */,
  "permissions-only-fallback-audioinput" /* PermissionsOnlyAudioinputFallback */,
  "permissions-rejected-videoinput" /* PermissionsRejectedVideoInput */,
  "video-device-in-use" /* VideoDeviceInUse */
].includes(status);
var areBothGranted = (status) => [
  "permissions-granted" /* PermissionsGranted */,
  "permissions-granted-fallback-devices" /* PermissionsGrantedFallback */,
  "permissions-granted-fallback-audioinput" /* PermissionsGrantedFallbackAudioinput */,
  "permissions-granted-fallback-videoinput" /* PermissionsGrantedFallbackVideoinput */
].includes(status);
var isGranted = (status) => areBothGranted(status) || isGrantedOnlyAudio(status) || isGrantedOnlyAudioNoVideoDevices(status) || isGrantedOnlyVideoNoAudioDevices(status) || isGrantedOnlyVideo(status);
var isOnlyAudioError = (status) => [
  "permissions-rejected-audioinput" /* PermissionsRejectedAudioInput */,
  "audio-device-in-use" /* AudioDeviceInUse */,
  "audio-overconstrained" /* AudioOverconstrained */,
  "invalid-audio-constraints" /* InvalidAudioConstraints */,
  "not-supported-error-only-audio" /* NotSupportedErrorOnlyAudioInput */
].includes(status);
var isOnlyVideoError = (status) => [
  "permissions-rejected-videoinput" /* PermissionsRejectedVideoInput */,
  "video-device-in-use" /* VideoDeviceInUse */,
  "video-overconstrained" /* VideoOverconstrained */,
  "invalid-video-constraints" /* InvalidVideoConstraints */,
  "not-supported-error-only-video" /* NotSupportedErrorOnlyVideoInput */
].includes(status);
var isRejected = (status) => "permissions-rejected" /* PermissionsRejected */ === status;
var isOverConstrained = (status) => [
  "overconstrained" /* Overconstrained */,
  "video-overconstrained" /* VideoOverconstrained */,
  "audio-overconstrained" /* AudioOverconstrained */
].includes(status);
var isUnknownError = (status) => [
  "unknown-error" /* UnknownError */,
  "unknown-error-only-videoinput" /* UnknownErrorOnlyVideoinput */,
  "unknown-error-only-audioinput" /* UnknownErrorOnlyAudioinput */
].includes(status);
var isInitial = (status) => status === "initial" /* Initial */;
var isInitialPermissions = (status) => [
  "initial-permissions-audioinput-denied" /* InitialPermissionsAudioInputDenied */,
  "initial-permissions-audioinput-granted" /* InitialPermissionsAudioInputGranted */,
  "initial-permissions-granted" /* InitialPermissionsGranted */,
  "initial-permissions-videoinput-granted-audioinput-denied" /* InitialPermissionsGrantedAudioInputDenied */,
  "initial-permissions-audioinput-granted-videoinput-denied" /* InitialPermissionsGrantedVideoInputDenied */,
  "initial-permissions-not-granted" /* InitialPermissionsNotGranted */,
  "initial-permissions-videoinput-denied" /* InitialPermissionsVideoInputDenied */,
  "initial-permissions-videoinput-granted" /* InitialPermissionsVideoInputGranted */
].includes(status);
var isInitialPermissionsNotGranted = (status) => [
  "initial-permissions-audioinput-denied" /* InitialPermissionsAudioInputDenied */,
  "initial-permissions-audioinput-granted" /* InitialPermissionsAudioInputGranted */,
  "initial-permissions-not-granted" /* InitialPermissionsNotGranted */,
  "initial-permissions-videoinput-denied" /* InitialPermissionsVideoInputDenied */,
  "initial-permissions-videoinput-granted" /* InitialPermissionsVideoInputGranted */
].includes(status);
var isInitialPermissionsGranted = (status) => [
  "initial-permissions-granted" /* InitialPermissionsGranted */,
  "initial-permissions-videoinput-granted-audioinput-denied" /* InitialPermissionsGrantedAudioInputDenied */,
  "initial-permissions-audioinput-granted-videoinput-denied" /* InitialPermissionsGrantedVideoInputDenied */
].includes(status);
var isAudioDeviceInUse = (status) => status === "audio-device-in-use" /* AudioDeviceInUse */;
var isDeviceInUse = (status) => [
  "devices-in-use" /* DevicesInUse */,
  "audio-device-in-use" /* AudioDeviceInUse */,
  "video-device-in-use" /* VideoDeviceInUse */
].includes(status);
var isVideoDeviceInUse = (status) => status === "video-device-in-use" /* VideoDeviceInUse */;
var toDeniedDevices = (status) => {
  if (!status) {
    return void 0;
  }
  if (isGrantedOnlyAudio(status)) {
    return "camera" /* Camera */;
  }
  if (isGrantedOnlyVideo(status)) {
    return "microphone" /* Microphone */;
  }
  if (isRejected(status)) {
    return "microphone-and-camera" /* Both */;
  }
};
var getPermissionStatus = async (getPermissionState = getInputDevicePermissionState) => {
  const { audio, video } = await getPermissionState();
  if (audio === "denied") {
    if (video === "denied") {
      return "permissions-rejected" /* PermissionsRejected */;
    }
    if (video === "granted") {
      return "initial-permissions-videoinput-granted-audioinput-denied" /* InitialPermissionsGrantedAudioInputDenied */;
    }
    if (video === "prompt") {
      return "initial-permissions-audioinput-denied" /* InitialPermissionsAudioInputDenied */;
    }
  }
  if (audio === "granted") {
    if (video === "denied") {
      return "initial-permissions-audioinput-granted-videoinput-denied" /* InitialPermissionsGrantedVideoInputDenied */;
    }
    if (video === "granted") {
      return "initial-permissions-granted" /* InitialPermissionsGranted */;
    }
    if (video === "prompt") {
      return "initial-permissions-audioinput-granted" /* InitialPermissionsAudioInputGranted */;
    }
  }
  if (audio === "prompt") {
    if (video === "denied") {
      return "initial-permissions-videoinput-denied" /* InitialPermissionsVideoInputDenied */;
    }
    if (video === "granted") {
      return "initial-permissions-videoinput-granted" /* InitialPermissionsVideoInputGranted */;
    }
    if (video === "prompt") {
      return "initial-permissions-not-granted" /* InitialPermissionsNotGranted */;
    }
  }
  return "initial" /* Initial */;
};

// src/logger.ts
var noopLogger = Object.freeze({
  trace() {
  },
  debug() {
  },
  info() {
  },
  warn() {
  },
  error() {
  }
});
var logger = noopLogger;
function setLogger(newLogger) {
  const oldLogger = logger;
  logger = newLogger;
  return oldLogger;
}
var createModuleLogger = (metaBase) => {
  return Object.keys(logger).reduce((log, key) => {
    const k = key;
    log[k] = (meta, msg) => {
      if (typeof meta === "string") {
        return logger[k](metaBase, meta);
      }
      if (typeof meta === "object") {
        return logger[k]({ ...meta, meta: metaBase }, msg);
      }
      return logger[k]({ meta: metaBase, context: meta }, msg);
    };
    return log;
  }, {});
};
var createLogProxyHandler = (logger2, name, scope) => {
  return {
    get: (target, p, receiver) => {
      const value = Reflect.get(target, p, receiver);
      logger2.debug(
        { scope, name, prop: p, value },
        `called get ${name}[${String(p)}]`
      );
      return value;
    },
    set: (target, p, value, receiver) => {
      logger2.debug(
        { scope, name, prop: p, value },
        `called set ${name}[${String(p)}]`
      );
      return Reflect.set(target, p, value, receiver);
    }
  };
};
var proxyWithLog = (logger2, scope) => (obj, name) => {
  return new Proxy(obj, createLogProxyHandler(logger2, name, scope));
};

// src/userMedia.ts
import {
  MediaDeviceFailure,
  applyConstraints as applyConstraints2,
  findMediaInputFromMediaStreamTrack,
  getDevices,
  getUserMedia,
  hasAudioInputs,
  hasVideoInputs,
  isExactDeviceConstraint,
  isRequestedResolution,
  isStreamingRequestedDevices,
  muteStreamTrack as muteStreamTrack2,
  stopMediaStream as stopMediaStream2
} from "@pexip/media-control";

// src/utils.ts
import {
  applyConstraints,
  createTrackDevicesChanges,
  extractConstraintsWithKeys,
  findDeviceFromConstraints,
  muteStreamTrack,
  relaxInputConstraint,
  stopMediaStream
} from "@pexip/media-control";

// src/typeGuard.ts
import { isAudioNodeInit } from "@pexip/media-processor";
var isNonNullObject = (value) => {
  if (typeof value === "object" && value !== null) {
    return true;
  }
  return false;
};
var isMedia = (value) => {
  if (isNonNullObject(value) && "release" in value) {
    return true;
  }
  return false;
};

// src/utils.ts
var makeDeriveDeviceStatus = (constraints) => (audio, video, both) => {
  if (!constraints.audio && constraints.video) {
    return video;
  }
  if (!constraints.video && constraints.audio) {
    return audio;
  }
  return both;
};
var createMediaProcess = (process) => async (mediaP) => {
  const media = await mediaP;
  return process(media) || media;
};
var createMediaPipeline = (init) => {
  const getPipeline = () => typeof init === "function" ? init() : init;
  return {
    pipe: (process) => {
      getPipeline().push(process);
    },
    execute: async (m) => {
      const [first, ...processes] = getPipeline();
      if (first) {
        const piped = processes.reduce(
          (prev, next) => next(prev),
          first(m)
        );
        return piped;
      }
      const media = m instanceof Promise ? await m : m;
      if (isMedia(media)) {
        return Promise.resolve(media);
      }
      throw new Error("Expect a media input or a processor");
    }
  };
};
var interpretInput = (input, getCurrentInput) => {
  if (input === true || input === void 0) {
    return getCurrentInput();
  }
  if (input === false) {
    return void 0;
  }
  return input;
};
var createMemorizedGetExpectedInput = () => {
  const props = {
    cachedExpectedInputs: /* @__PURE__ */ new Map()
  };
  return (constraints, getInfo) => {
    if (props.cachedExpectedInputs.has(constraints)) {
      return props.cachedExpectedInputs.get(constraints);
    }
    const { devices, input } = getInfo();
    const relaxedConstraints = relaxInputConstraint(constraints, devices);
    const {
      device: [[device] = []]
    } = extractConstraintsWithKeys(["device"])(relaxedConstraints);
    const found = device ?? findDeviceFromConstraints(constraints, devices);
    const expectedInput = interpretInput(found, () => input);
    props.cachedExpectedInputs.clear();
    props.cachedExpectedInputs.set(constraints, expectedInput);
    return expectedInput;
  };
};
var isMuted = (tracks) => {
  if (!tracks?.length) {
    return void 0;
  }
  return !tracks.some((track) => !track.muted && track.enabled);
};
var createCompareDeviceKind = (kind) => (device) => device.kind === kind;
var isAudioInput = createCompareDeviceKind("audioinput");
var isVideoInput = createCompareDeviceKind("videoinput");
var buildMedia = (getMedia, onSetStatus) => {
  const props = {
    status: getMedia().status ?? "initial" /* Initial */,
    constraints: getMedia().constraints
  };
  const getExpectedAudioInput = createMemorizedGetExpectedInput();
  const getExpectedVideoInput = createMemorizedGetExpectedInput();
  const muteTrack = (kind) => (muted) => {
    const { muteAudio, muteVideo, stream } = getMedia();
    const mute = kind === "audio" ? muteAudio : muteVideo;
    if (mute) {
      return mute(muted);
    }
    return muteStreamTrack(stream)(muted, kind);
  };
  const release = () => {
    const { release: release2, stream } = getMedia();
    if (release2) {
      return release2();
    }
    return new Promise((resolve) => {
      stopMediaStream(stream);
      resolve();
    });
  };
  return {
    get constraints() {
      return props.constraints;
    },
    get devices() {
      return getMedia().devices || [];
    },
    get stream() {
      return getMedia().stream;
    },
    get expectedAudioInput() {
      const media = getMedia();
      return getExpectedAudioInput(props.constraints?.audio, () => ({
        devices: media.devices?.filter(isAudioInput) ?? [],
        input: media.audioInput
      }));
    },
    get expectedVideoInput() {
      const media = getMedia();
      return getExpectedVideoInput(props.constraints?.video, () => ({
        devices: media.devices?.filter(isVideoInput) ?? [],
        input: media.videoInput
      }));
    },
    get rawStream() {
      const { rawStream, stream } = getMedia();
      return rawStream ?? stream;
    },
    get audioInput() {
      return getMedia().audioInput;
    },
    get videoInput() {
      return getMedia().videoInput;
    },
    get status() {
      return props.status;
    },
    set status(status) {
      props.status = status;
      onSetStatus?.(status);
    },
    set constraints(value) {
      props.constraints = value;
    },
    get audioMuted() {
      return isMuted(getMedia().stream?.getAudioTracks());
    },
    get videoMuted() {
      return isMuted(getMedia().stream?.getVideoTracks());
    },
    muteAudio: muteTrack("audio"),
    muteVideo: muteTrack("video"),
    applyConstraints: async (constraints) => {
      const { stream, applyConstraints: prevApplyConstraints } = getMedia();
      if (prevApplyConstraints) {
        return await prevApplyConstraints(constraints);
      }
      return await applyConstraints(stream?.getTracks(), constraints);
    },
    release,
    getSettings: () => {
      const { getSettings, stream } = getMedia();
      if (!stream) {
        return {
          audio: [],
          video: []
        };
      }
      if (getSettings) {
        return getSettings();
      }
      return {
        audio: stream.getAudioTracks().map((track) => track.getSettings()),
        video: stream.getVideoTracks().map((track) => track.getSettings())
      };
    },
    toJSON: () => toJSON(getMedia())
  };
};
var cloneMedia = async (media) => {
  const stream = (media.rawStream ?? media.stream)?.clone();
  stream?.getTracks().forEach((track) => track.enabled = true);
  const { audio, video } = media.getSettings();
  const clonedMedia = buildMedia(() => ({
    stream,
    constraints: media?.constraints,
    devices: media?.devices,
    status: media?.status,
    rawStream: stream,
    audioInput: media?.audioInput,
    videoInput: media?.videoInput,
    getSettings: () => ({ audio, video })
  }));
  return Promise.resolve(clonedMedia);
};
var shallowCopy = (original, overriding) => {
  const copy = Object.create(
    Object.getPrototypeOf(original),
    Object.getOwnPropertyDescriptors(original)
  );
  return Object.defineProperties(
    copy,
    Object.getOwnPropertyDescriptors(overriding)
  );
};
var getDevicesChanges = (prev, next) => {
  const trackChanges = createTrackDevicesChanges(prev);
  return trackChanges(next);
};
var applyExtendedConstraints = (media, applyExtended) => async (constraints) => {
  await media.applyConstraints(constraints);
  if (!isOverConstrained(media.status)) {
    await applyExtended(constraints);
  }
};
var AUDIO_SETTINGS_KEYS = [
  "denoise",
  "vad",
  "asd"
];
var VIDEO_SETTINGS_KEYS = [
  "frameRate",
  "videoSegmentation",
  "videoSegmentationModel",
  "foregroundThreshold",
  "backgroundBlurAmount",
  "edgeBlurAmount",
  "flipHorizontal",
  "bgImageUrl",
  "width",
  "height"
];
var MIXING_SETTINGS_KEYS = [
  "mixWithAdditionalMedia"
];
var hasSettingsChanged = (keysToLookFor) => {
  const cache = {};
  return (settingsA, settingsB) => {
    if (cache.result !== void 0 && cache.settingsA === settingsA && cache.settingsB === settingsB) {
      return cache.result;
    }
    cache.settingsA = settingsA;
    cache.settingsB = settingsB;
    for (const key of keysToLookFor) {
      if (settingsA === settingsB) {
        cache.result = false;
        return cache.result;
      }
      if (settingsA === void 0 || settingsB === void 0) {
        cache.result = true;
        return cache.result;
      }
      if (settingsA[key] !== settingsB[key]) {
        cache.result = true;
        return cache.result;
      }
    }
    cache.result = false;
    return cache.result;
  };
};
var toJSON = (media) => {
  return {
    constraints: media.constraints,
    devices: media.devices,
    stream: media.stream,
    rawStream: media.rawStream,
    audioInput: media.audioInput,
    videoInput: media.videoInput,
    expectedAudioInput: media.expectedAudioInput,
    expectedVideoInput: media.expectedVideoInput,
    status: media.status,
    audioMuted: media.audioMuted,
    videoMuted: media.videoMuted
  };
};
var wrapToJSON = (media) => {
  media.toJSON = () => toJSON(media);
  return media;
};
var getBlurKernelSize = (percentage, height, max = 20) => {
  if (height <= 0 || percentage <= 0 || max <= 0) {
    return 0;
  }
  return Math.min(Math.ceil(percentage * 1e-3 * height), max);
};

// src/userMedia.ts
var toErrorDeviceStatus = (error, deriveDeviceStatus) => {
  switch (error.message) {
    case MediaDeviceFailure.AudioInputDeviceNotFoundError:
      return "audio-device-not-found" /* AudioDeviceNotFound */;
    case MediaDeviceFailure.VideoInputDeviceNotFoundError:
      return "video-device-not-found" /* VideoDeviceNotFound */;
    case MediaDeviceFailure.AudioAndVideoDeviceNotFoundError:
      return "audio-video-devices-not-found" /* AudioVideoDevicesNotFound */;
    case MediaDeviceFailure.NotAllowedError:
    case MediaDeviceFailure.NotFoundError:
    case MediaDeviceFailure.SecurityError:
    case MediaDeviceFailure.PermissionDeniedError:
      return deriveDeviceStatus(
        "permissions-rejected-audioinput" /* PermissionsRejectedAudioInput */,
        "permissions-rejected-videoinput" /* PermissionsRejectedVideoInput */,
        "permissions-rejected" /* PermissionsRejected */
      );
    case MediaDeviceFailure.NotReadableError:
    case MediaDeviceFailure.TrackStartError:
    case MediaDeviceFailure.AbortError:
      return deriveDeviceStatus(
        "audio-device-in-use" /* AudioDeviceInUse */,
        "video-device-in-use" /* VideoDeviceInUse */,
        "devices-in-use" /* DevicesInUse */
      );
    case MediaDeviceFailure.MissingConstraintsError:
      return deriveDeviceStatus(
        "no-audio-devices-found" /* NoAudioDevicesFound */,
        "no-video-devices-found" /* NoVideoDevicesFound */,
        "no-devices-found" /* NoDevicesFound */
      );
    case MediaDeviceFailure.OverconstrainedError:
      return deriveDeviceStatus(
        "audio-overconstrained" /* AudioOverconstrained */,
        "video-overconstrained" /* VideoOverconstrained */,
        "overconstrained" /* Overconstrained */
      );
    case MediaDeviceFailure.TypeError:
      return deriveDeviceStatus(
        "invalid-audio-constraints" /* InvalidAudioConstraints */,
        "invalid-video-constraints" /* InvalidVideoConstraints */,
        "invalid-constraints" /* InvalidConstraints */
      );
    case MediaDeviceFailure.NotSupportedError:
      return deriveDeviceStatus(
        "not-supported-error-only-audio" /* NotSupportedErrorOnlyAudioInput */,
        "not-supported-error-only-video" /* NotSupportedErrorOnlyVideoInput */,
        "not-supported-error" /* NotSupportedError */
      );
    default:
      return deriveDeviceStatus(
        "unknown-error-only-audioinput" /* UnknownErrorOnlyAudioinput */,
        "unknown-error-only-videoinput" /* UnknownErrorOnlyVideoinput */,
        "unknown-error" /* UnknownError */
      );
  }
};
var mapErrorStatus = (status) => (constraints) => {
  const toStatus = makeDeriveDeviceStatus(constraints);
  switch (status) {
    case "audio-video-devices-not-found" /* AudioVideoDevicesNotFound */:
      return toStatus(
        "audio-device-not-found" /* AudioDeviceNotFound */,
        "video-device-not-found" /* VideoDeviceNotFound */,
        status
      );
    case "devices-in-use" /* DevicesInUse */:
      return toStatus(
        "audio-device-in-use" /* AudioDeviceInUse */,
        "video-device-in-use" /* VideoDeviceInUse */,
        status
      );
    case "invalid-constraints" /* InvalidConstraints */:
      return toStatus(
        "invalid-audio-constraints" /* InvalidAudioConstraints */,
        "invalid-video-constraints" /* InvalidVideoConstraints */,
        status
      );
    case "no-devices-found" /* NoDevicesFound */:
      return toStatus(
        "no-audio-devices-found" /* NoAudioDevicesFound */,
        "no-video-devices-found" /* NoVideoDevicesFound */,
        status
      );
    case "not-supported-error" /* NotSupportedError */:
      return toStatus(
        "not-supported-error-only-audio" /* NotSupportedErrorOnlyAudioInput */,
        "not-supported-error-only-video" /* NotSupportedErrorOnlyVideoInput */,
        status
      );
    case "overconstrained" /* Overconstrained */:
      return toStatus(
        "audio-overconstrained" /* AudioOverconstrained */,
        "video-overconstrained" /* VideoOverconstrained */,
        status
      );
    case "permissions-rejected" /* PermissionsRejected */:
      return toStatus(
        "permissions-rejected-audioinput" /* PermissionsRejectedAudioInput */,
        "permissions-rejected-videoinput" /* PermissionsRejectedVideoInput */,
        status
      );
    case "unknown-error" /* UnknownError */:
      return toStatus(
        "unknown-error-only-audioinput" /* UnknownErrorOnlyAudioinput */,
        "unknown-error-only-videoinput" /* UnknownErrorOnlyVideoinput */,
        status
      );
    default:
      return status;
  }
};
var toSameDeviceStatus = ({
  audio,
  video
}) => {
  if (audio) {
    if (video) {
      return "permissions-granted" /* PermissionsGranted */;
    }
    return "permissions-granted-fallback-videoinput" /* PermissionsGrantedFallbackVideoinput */;
  }
  if (video) {
    return "permissions-granted-fallback-audioinput" /* PermissionsGrantedFallbackAudioinput */;
  }
  return "permissions-granted-fallback-devices" /* PermissionsGrantedFallback */;
};
var toOnlyDeviceStatus = (kind, matched, devices) => {
  const hasRelatedDevices = devices.some((d) => {
    if (kind === "audioinput") {
      return d.kind === "videoinput";
    }
    return d.kind === "audioinput";
  });
  if (matched) {
    if (hasRelatedDevices) {
      return kind === "audioinput" ? "permissions-only-audioinput" /* PermissionsOnlyAudioinput */ : "permissions-only-videoinput" /* PermissionsOnlyVideoinput */;
    }
    return kind === "audioinput" ? "permissions-only-audioinput-no-video-devices" /* PermissionsOnlyAudioinputNoVideoDevices */ : "permissions-only-videoinput-no-audio-devices" /* PermissionsOnlyVideoinputNoAudioDevices */;
  }
  if (hasRelatedDevices) {
    return kind === "audioinput" ? "permissions-only-fallback-audioinput" /* PermissionsOnlyAudioinputFallback */ : "permissions-only-fallback-videoinput" /* PermissionsOnlyVideoinputFallback */;
  }
  return kind === "audioinput" ? "permissions-only-fallback-audioinput-no-video-devices" /* PermissionsOnlyAudioinputFallbackNoVideoDevices */ : "permissions-only-fallback-videoinput-no-audio-devices" /* PermissionsOnlyVideoinputFallbackNoAudioDevices */;
};
var requestUserMedia = (currentDevices, getMedia = getUserMedia) => async (constraints) => {
  const deriveDeviceStatus = makeDeriveDeviceStatus(constraints);
  const devices = currentDevices ?? await getDevices();
  try {
    const stream = await getMedia(constraints);
    const grantedDevices = devices.some((device) => device.label) ? devices : await getDevices();
    const { audio, video } = isStreamingRequestedDevices(
      constraints,
      stream,
      grantedDevices
    );
    const onlyAudioStatus = toOnlyDeviceStatus(
      "audioinput",
      audio,
      grantedDevices
    );
    const onlyVideoStatus = toOnlyDeviceStatus(
      "videoinput",
      video,
      grantedDevices
    );
    const status = deriveDeviceStatus(
      onlyAudioStatus,
      onlyVideoStatus,
      toSameDeviceStatus({ audio, video })
    );
    return [stream, status];
  } catch (error) {
    if (error instanceof Error) {
      const status = toErrorDeviceStatus(error, deriveDeviceStatus);
      return [void 0, status];
    }
    throw error;
  }
};
var mergeNoDeviceStatus = (constraints, anyDevices, status) => {
  if (status !== "no-devices-found" /* NoDevicesFound */ || !anyDevices.audio && !anyDevices.video && constraints.audio && constraints.video) {
    return status;
  }
  if (!anyDevices.audio && constraints.audio) {
    return "no-audio-devices-found" /* NoAudioDevicesFound */;
  }
  if (!anyDevices.video && constraints.video) {
    return "no-video-devices-found" /* NoVideoDevicesFound */;
  }
  return status;
};
var requestUserMediaWithRetry = (currentDevices, createRequestUserMedia = requestUserMedia) => async (constraints) => {
  const devices = currentDevices ?? await getDevices();
  const anyAudioDevices = hasAudioInputs(devices);
  const anyVideoDevices = hasVideoInputs(devices);
  const request = createRequestUserMedia(devices);
  if (anyAudioDevices && anyVideoDevices) {
    const [stream, status] = await request(constraints);
    if (stream) {
      return [stream, status];
    }
    const mapStatus = mapErrorStatus(status);
    if (constraints.video) {
      const [audioStream] = await request({
        ...constraints,
        video: false
      });
      if (audioStream) {
        const videoStatus = mapStatus({ video: true });
        return [audioStream, videoStatus];
      }
    }
    if (constraints.audio) {
      const [videoStream] = await request({
        ...constraints,
        audio: false
      });
      if (videoStream) {
        return [videoStream, mapStatus({ audio: true })];
      }
    }
    return [stream, status];
  } else {
    const [stream, status] = await request({
      audio: anyAudioDevices && constraints.audio,
      video: anyVideoDevices && constraints.video
    });
    return [
      stream,
      mergeNoDeviceStatus(
        constraints,
        { audio: anyAudioDevices, video: anyVideoDevices },
        status
      )
    ];
  }
};
var createGetUserMediaProcess = (getUserMedia3, getCurrentDevices, { initialMedia, scope = "media" }) => {
  const props = {
    unsubscribe: void 0,
    cachedDevices: getCurrentDevices(),
    cachedAudioTrackId: "",
    cachedVideoTrackId: "",
    cachedAudioInput: void 0,
    cachedVideoInput: void 0
  };
  const getExpectedAudioInput = createMemorizedGetExpectedInput();
  const getExpectedVideoInput = createMemorizedGetExpectedInput();
  const getInput = ([track] = []) => {
    if (!track) {
      return void 0;
    }
    const isAudio = track.kind === "audio";
    const cacheKey = isAudio ? "cachedAudioInput" : "cachedVideoInput";
    const cachedTrackId = isAudio ? "cachedAudioTrackId" : "cachedVideoTrackId";
    const trackId = track.label;
    const { width, height } = track.getSettings();
    if (trackId === props[cachedTrackId] && track.readyState === "live" && (isAudio || !isAudio && isRequestedResolution({ width, height }, props[cacheKey]))) {
      return props[cacheKey];
    }
    const devices = getCurrentDevices();
    const findTrack = findMediaInputFromMediaStreamTrack(devices);
    const input = findTrack(track);
    props[cacheKey] = input;
    props[cachedTrackId] = trackId;
    return input;
  };
  return async (constraints) => {
    const [stream, status] = await getUserMedia3(constraints);
    logger.debug({ scope, stream, status }, "getUserMedia");
    const deriveDeviceStatus = makeDeriveDeviceStatus(constraints);
    const muteTrack = muteStreamTrack2(stream);
    const muteAudio = (mute) => muteTrack(mute, "audio");
    const muteVideo = (mute) => muteTrack(mute, "video");
    return wrapToJSON(
      shallowCopy(initialMedia ?? {}, {
        constraints,
        devices: getCurrentDevices(),
        rawStream: stream,
        stream,
        status,
        get audioInput() {
          return getInput(stream?.getAudioTracks());
        },
        get videoInput() {
          return getInput(stream?.getVideoTracks());
        },
        get expectedAudioInput() {
          return getExpectedAudioInput(constraints.audio, () => ({
            devices: getCurrentDevices().filter(isAudioInput),
            input: getInput(stream?.getAudioTracks())
          }));
        },
        get expectedVideoInput() {
          return getExpectedVideoInput(constraints.video, () => ({
            devices: getCurrentDevices().filter(isVideoInput),
            input: getInput(stream?.getVideoTracks())
          }));
        },
        muteAudio,
        muteVideo,
        get audioMuted() {
          return isMuted(stream?.getAudioTracks());
        },
        get videoMuted() {
          return isMuted(stream?.getVideoTracks());
        },
        applyConstraints: async (constraints2) => {
          try {
            logger.debug(
              { scope, constraints: constraints2 },
              "apply native constraints"
            );
            return await applyConstraints2(
              stream?.getTracks(),
              constraints2
            );
          } catch (error) {
            if (error instanceof Error) {
              const status2 = toErrorDeviceStatus(
                error,
                deriveDeviceStatus
              );
              logger.error(
                {
                  scope,
                  status: status2,
                  error,
                  constraints: constraints2
                },
                "fail to apply native constraints"
              );
              initialMedia.status = status2;
            }
            throw error;
          }
        },
        getSettings: () => {
          const settings = {
            audio: stream?.getAudioTracks().map((track) => track.getSettings()) ?? [],
            video: stream?.getVideoTracks().map((track) => track.getSettings()) ?? []
          };
          logger.debug({ settings, scope }, "get native settings");
          return settings;
        },
        release: () => {
          stopMediaStream2(stream);
          if (props.unsubscribe) {
            props.unsubscribe();
            props.unsubscribe = void 0;
          }
          return Promise.resolve();
        }
      })
    );
  };
};

// src/videoProcessor.ts
import {
  createVideoProcessor,
  createCanvasTransform,
  createVideoTrackProcessor,
  createVideoTrackProcessorWithFallback,
  isRenderEffects,
  isSegmentationModel
} from "@pexip/media-processor";
import {
  muteStreamTrack as muteStreamTrack3,
  extractConstraintsWithKeys as extractConstraintsWithKeys2,
  getValueFromConstrainNumber
} from "@pexip/media-control";
import { isEmpty } from "@pexip/utils";
var FEATURE_KEYS = [
  "backgroundBlurAmount",
  "bgImageUrl",
  "edgeBlurAmount",
  "flipHorizontal",
  "foregroundThreshold",
  "frameRate",
  "videoSegmentation",
  "videoSegmentationModel",
  "width",
  "height"
];
var getVideoConstraints = extractConstraintsWithKeys2(FEATURE_KEYS);
var updateFeatureProps = (constraints, props) => {
  const extracted = getVideoConstraints(constraints);
  return FEATURE_KEYS.reduce((accm, key) => {
    switch (key) {
      case "videoSegmentation": {
        const [[feature] = []] = extracted[key];
        if (isRenderEffects(feature) && props[key] !== feature) {
          props[key] = feature;
          return { ...accm, [key]: feature };
        }
        return accm;
      }
      case "videoSegmentationModel": {
        const [[feature] = []] = extracted[key];
        if (isSegmentationModel(feature) && props[key] !== feature) {
          props[key] = feature;
          return { ...accm, [key]: feature };
        }
        return accm;
      }
      case "bgImageUrl": {
        const [[feature] = []] = extracted[key];
        if (feature) {
          props[key] = feature;
          return { ...accm, [key]: feature };
        }
        return accm;
      }
      case "width":
      case "height":
      case "frameRate":
      case "foregroundThreshold":
      case "edgeBlurAmount":
      case "backgroundBlurAmount": {
        const [feature] = extracted[key];
        if (feature !== void 0) {
          const value = getValueFromConstrainNumber(feature);
          if (props[key] !== value) {
            props[key] = value;
            return {
              ...accm,
              [key]: value
            };
          }
        }
        return accm;
      }
      case "flipHorizontal": {
        const [feature] = extracted[key];
        if (feature !== void 0 && props[key] !== feature) {
          props[key] = feature;
          return { ...accm, [key]: feature };
        }
        return accm;
      }
    }
  }, {});
};
var applyFeatures = async (transformer, features) => {
  if (features.bgImageUrl) {
    await transformer.loadBackgroundImage(features.bgImageUrl);
  }
  Object.keys(features).forEach((key) => {
    const k = key;
    switch (k) {
      case "edgeBlurAmount":
      case "foregroundThreshold": {
        const value = features[k];
        if (value !== void 0) {
          transformer[k] = value;
        }
        return;
      }
      case "backgroundBlurAmount": {
        const value = features[k];
        if (value !== void 0) {
          transformer[k] = getBlurKernelSize(
            value,
            transformer.height
          );
        }
        return;
      }
      case "videoSegmentation": {
        const value = features[k];
        if (value && value !== transformer.effects) {
          transformer.effects = value;
        }
        return;
      }
      case "flipHorizontal": {
        const value = features[k];
        if (value !== void 0 && value !== transformer.flipHorizontal) {
          transformer.flipHorizontal = value;
        }
        return;
      }
      default: {
        return;
      }
    }
  });
};
var adjustResolution = async (media, features, processingSize) => {
  if (features.videoSegmentation) {
    const {
      video: [videoSettings]
    } = media.getSettings();
    const constraints = updateFeatureProps(media.constraints?.video, {});
    switch (features.videoSegmentation) {
      case "blur":
      case "overlay": {
        if (videoSettings?.height !== processingSize.height) {
          try {
            await media.applyConstraints({
              video: {
                width: processingSize.width,
                height: processingSize.height
              }
            });
            const {
              video: [postVideoSettings]
            } = media.getSettings();
            if (postVideoSettings?.height !== processingSize.height) {
              await media.applyConstraints({
                video: { height: 720 }
              });
            }
          } catch (error) {
            await media.applyConstraints({
              video: { height: 720 }
            });
          }
        }
        break;
      }
      case "none": {
        if (constraints.height && constraints.height !== videoSettings?.height) {
          await media.applyConstraints({
            video: {
              height: constraints.height
            }
          });
        }
        break;
      }
    }
  }
};
var getTrackProcessor = (shouldUseStreamTrackProcessor, ...params) => {
  if (shouldUseStreamTrackProcessor && "MediaStreamTrackProcessor" in window) {
    return createVideoTrackProcessor();
  }
  return createVideoTrackProcessorWithFallback(...params);
};
var createVideoStreamProcess = ({
  trackProcessorAPI = "stream",
  processingWidth,
  processingHeight,
  shouldEnable,
  frameRate,
  videoSegmentation,
  foregroundThreshold,
  bgImageUrl,
  flipHorizontal,
  edgeBlurAmount,
  scope = "media",
  ...options
}) => {
  const videoSegmentationModel = options.videoSegmentationModel ?? "mediapipeSelfie";
  const backgroundBlurAmount = options.backgroundBlurAmount && getBlurKernelSize(options.backgroundBlurAmount, processingHeight);
  const transformer = options.transformer ?? createCanvasTransform(options.segmenters[videoSegmentationModel], {
    width: processingWidth,
    height: processingHeight,
    effects: videoSegmentation,
    foregroundThreshold,
    backgroundBlurAmount,
    edgeBlurAmount,
    flipHorizontal
  });
  const proxy = proxyWithLog(logger, scope);
  const props = {
    videoSegmentationModel,
    segmenters: {
      mediapipeSelfie: proxy(
        options.segmenters.mediapipeSelfie,
        "Segmenter"
      )
    },
    transformer: proxy(transformer, "Transformer"),
    videoProcessor: proxy(
      createVideoProcessor(
        [transformer],
        getTrackProcessor(trackProcessorAPI === "stream", {
          width: processingWidth,
          height: processingHeight,
          frameRate
        })
      ),
      "VideoProcessor"
    ),
    videoSegmentation,
    backgroundBlurAmount,
    edgeBlurAmount,
    foregroundThreshold,
    frameRate,
    bgImageUrl,
    flipHorizontal,
    hasInitialized: options.hasInitializedDeps ?? false
  };
  return async (mediaP) => {
    const media = await mediaP;
    const features = updateFeatureProps(media.constraints?.video, props);
    const shouldEnabled = shouldEnable();
    if (!shouldEnabled || !media.stream?.getVideoTracks().length) {
      logger.debug(
        { scope, features, shouldEnabled },
        "Video processing is skipped"
      );
      return media;
    }
    try {
      if (!props.hasInitialized) {
        await props.videoProcessor.open();
        props.hasInitialized = true;
      }
      await applyFeatures(props.transformer, features);
      await adjustResolution(media, features, {
        width: processingWidth,
        height: processingHeight
      });
      if (features.videoSegmentationModel && features.videoSegmentationModel !== props.transformer.segmenter.model) {
        props.transformer.segmenter = props.segmenters[features.videoSegmentationModel];
      }
      const stream = await props.videoProcessor.process(media.stream);
      const release = async () => {
        props.videoProcessor.close();
        await media.release();
        props.hasInitialized = false;
      };
      const muteAudio = (mute) => {
        media.muteAudio(mute);
        muteStreamTrack3(stream)(mute, "audio");
      };
      const muteVideo = (mute) => {
        media.muteVideo(mute);
        props.transformer.effects = mute ? "none" : props.videoSegmentation ?? "none";
        muteStreamTrack3(stream)(mute, "video");
      };
      const applyConstraints3 = applyExtendedConstraints(media, async (constraints) => {
        if (isEmpty(constraints.video)) {
          return;
        }
        const features2 = updateFeatureProps(
          constraints.video,
          props
        );
        logger.debug(
          { scope, constraints: constraints.video, features: features2 },
          "apply video constraints"
        );
        if (isEmpty(features2)) {
          return;
        }
        try {
          await applyFeatures(props.transformer, features2);
          await adjustResolution(media, features2, {
            width: processingWidth,
            height: processingHeight
          });
          if (features2.videoSegmentationModel && features2.videoSegmentationModel !== props.transformer.segmenter.model) {
            props.transformer.segmenter = props.segmenters[features2.videoSegmentationModel];
          }
        } catch (error) {
          if (error instanceof Error) {
            logger.error(
              {
                scope,
                constraints: constraints.video,
                features: features2
              },
              "failed to apply video constraints"
            );
            options.onError?.(error);
          }
        }
      });
      const prevGetSettings = media.getSettings;
      return wrapToJSON(
        shallowCopy(media, {
          stream,
          muteAudio,
          muteVideo,
          applyConstraints: applyConstraints3,
          release,
          getSettings: () => {
            const { audio, video } = prevGetSettings();
            const videoSettings = {
              videoSegmentation: transformer.effects,
              foregroundThreshold: transformer.foregroundThreshold,
              backgroundBlurAmount: props.backgroundBlurAmount,
              edgeBlurAmount: transformer.edgeBlurAmount,
              flipHorizontal: transformer.flipHorizontal,
              bgImageUrl: transformer.backgroundImage && props.bgImageUrl,
              videoSegmentationModel: transformer.segmenter.model
            };
            const settings = {
              audio,
              video: video.map((settings2) => ({
                ...settings2,
                ...videoSettings
              }))
            };
            logger.debug(
              { scope, settings: videoSettings },
              "get video processor settings"
            );
            return settings;
          }
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        options.onError?.(e);
      }
      return media;
    }
  };
};

// src/audioProcessor.ts
import {
  stopMediaStream as stopMediaStream3,
  extractConstraintsWithKeys as extractConstraintsWithKeys3,
  muteStreamTrack as muteStreamTrack4
} from "@pexip/media-control";
import { createQueue, isEmpty as isEmpty2 } from "@pexip/utils";
import {
  createAudioGraph,
  createAudioGraphProxy,
  createStreamSourceGraphNode,
  createStreamDestinationGraphNode,
  createAnalyzerSubscribableGraphNode,
  createDenoiseWorkletGraphNode,
  createAudioSignalDetector,
  createVADetector,
  createVoiceDetectorFromTimeData,
  createVoiceDetectorFromProbability,
  avg
} from "@pexip/media-processor";
var fetchDenoiseWasm = async (denoiseWasm, wasmURL) => {
  if (!wasmURL || denoiseWasm) {
    return denoiseWasm;
  }
  return await (await fetch(wasmURL)).arrayBuffer();
};
var FEATURE_KEYS2 = ["denoise", "vad", "asd"];
var getAudioConstraints = extractConstraintsWithKeys3(FEATURE_KEYS2);
var updateFeatureProps2 = (constraints, props) => {
  const extracted = getAudioConstraints(constraints);
  return FEATURE_KEYS2.reduce((accm, key) => {
    const [feature] = extracted[key];
    if (feature !== void 0 && props[key] !== feature) {
      props[key] = feature;
      return { ...accm, [key]: feature };
    }
    return accm;
  }, {});
};
var createAudioStreamProcess = ({
  analyzerUpdateFrequency = 0.5,
  audioGraphOptions,
  audioSignalDetectionDuration = 4,
  clock,
  createNodes,
  denoiseParams,
  fftSize = 2048,
  onAudioSignalDetected,
  onVoiceActivityDetected,
  shouldEnable,
  silentThreshold = 10 / 32767,
  throttleMs = 3e3,
  scope = "media"
}) => {
  const props = {
    audioGraphOptions,
    vad: false,
    asd: false,
    denoise: false
  };
  const detectAudio = onAudioSignalDetected && createAudioSignalDetector(() => !!props.asd, onAudioSignalDetected);
  const detectVA = onVoiceActivityDetected && createVADetector(onVoiceActivityDetected, () => !!props.vad, {
    throttleMs,
    clock
  });
  const detectVAFromTimeData = detectVA?.(createVoiceDetectorFromTimeData());
  const createDenoiseNode = async (denoise) => {
    if (!denoise) {
      return void 0;
    }
    if (props.denoiseNode) {
      return props.denoiseNode;
    }
    if (denoiseParams?.workletModule) {
      try {
        await props.audioGraph?.addWorklet(
          denoiseParams?.workletModule,
          denoiseParams?.workletOptions
        );
      } catch (error) {
        logger.error(
          {
            scope,
            error,
            moduleURL: denoiseParams?.workletModule,
            options: denoiseParams?.workletOptions
          },
          "Failed add worklet"
        );
        return void 0;
      }
    }
    try {
      props.denoiseWasm = await fetchDenoiseWasm(
        props.denoiseWasm,
        denoiseParams?.wasmURL
      );
    } catch (error) {
      logger.error(
        {
          scope,
          error,
          url: denoiseParams?.wasmURL,
          prevWasm: props.denoiseWasm
        },
        "Failed to fetch denoise wasm"
      );
      return void 0;
    }
    const detectVAFromProbability = detectVA?.(
      createVoiceDetectorFromProbability()
    );
    if (props.denoiseWasm) {
      const denoise2 = createDenoiseWorkletGraphNode(
        props.denoiseWasm,
        (vads) => {
          detectVAFromProbability?.(avg(vads));
        }
      );
      props.denoiseNode = denoise2;
      return denoise2;
    }
  };
  const createAnalyzer = () => {
    const shouldUseAnalyzer = () => !!(props.vad && !props.denoise || props.asd) && (detectAudio || detectVA);
    if (!shouldUseAnalyzer()) {
      return;
    }
    if (props.analyzer) {
      return props.analyzer;
    }
    const detectSilentAudio = detectAudio?.(
      createQueue(
        audioSignalDetectionDuration / analyzerUpdateFrequency
      ),
      silentThreshold
    );
    const analyzer = createAnalyzerSubscribableGraphNode({
      updateFrequency: analyzerUpdateFrequency,
      messageHandler: (analyzer2) => {
        if (shouldUseAnalyzer()) {
          if (!props.analyzerBuffer) {
            props.analyzerBuffer = new Float32Array(fftSize);
          }
          analyzer2.getFloatTimeDomainData(props.analyzerBuffer);
          const data = Array.from(props.analyzerBuffer);
          detectSilentAudio?.(data);
          !props.denoise && detectVAFromTimeData?.(data);
        }
      },
      fftSize
    });
    props.analyzer = analyzer;
    return analyzer;
  };
  return async (mediaP) => {
    const media = await mediaP;
    updateFeatureProps2(media.constraints?.audio, props);
    const shouldProcessAudio = shouldEnable() && !!media.stream?.getAudioTracks().length && (!!onVoiceActivityDetected || !!onAudioSignalDetected || props.asd || props.vad || props.denoise || !!createNodes);
    if (!media.stream?.getAudioTracks().length || !shouldProcessAudio) {
      return media;
    }
    try {
      const source = createStreamSourceGraphNode(media.stream);
      const destination = createStreamDestinationGraphNode();
      const otherNodes = createNodes?.(media) ?? [];
      const analyzer = createAnalyzer();
      const initialAudioNodeConnection = [
        [source, ...otherNodes, destination],
        [source, analyzer]
      ];
      logger.debug(
        { initialAudioNodeConnection, scope },
        "Initial AudioNodeConnection"
      );
      const audioGraph = createAudioGraphProxy(
        createAudioGraph(
          initialAudioNodeConnection,
          props.audioGraphOptions
        ),
        {
          connect: (target, args) => {
            logger.debug({ scope, target, args }, "connect nodes");
          },
          disconnect: (target, args) => {
            logger.debug({ scope, target, args }, "disconnect nodes");
          }
        }
      );
      props.audioGraph = audioGraph;
      const denoiseNode = await createDenoiseNode(props.denoise);
      const connectDenoise = (node) => {
        if (node) {
          audioGraph.disconnect([source, ...otherNodes, destination]);
          audioGraph.connect([
            source,
            node,
            ...otherNodes,
            destination
          ]);
        }
      };
      connectDenoise(denoiseNode);
      const tracks = [
        ...destination?.node?.stream.getAudioTracks() ?? [],
        ...media.stream?.getVideoTracks().map((t) => t.clone()) ?? []
      ];
      const stream = new MediaStream(tracks);
      const applyConstraints3 = applyExtendedConstraints(media, async (constraints) => {
        if (isEmpty2(constraints.audio)) {
          return;
        }
        const features = updateFeatureProps2(
          constraints.audio,
          props
        );
        logger.debug(
          { scope, constraints: constraints.audio, features },
          "apply audio constraints"
        );
        if (isEmpty2(features) || ["closed", "closing"].includes(audioGraph.state)) {
          return;
        }
        try {
          const denoiseNode2 = await createDenoiseNode(
            props.denoise
          );
          if (denoiseNode2) {
            if (!source.hasConnectedTo(denoiseNode2)) {
              connectDenoise(denoiseNode2);
            }
          } else {
            if (props.denoiseNode) {
              audioGraph.disconnect([
                source,
                props.denoiseNode,
                ...otherNodes,
                destination
              ]);
              audioGraph.connect([
                source,
                ...otherNodes,
                destination
              ]);
              audioGraph.releaseInit(props.denoiseNode);
              props.denoiseNode = void 0;
            }
          }
          const analyzer2 = createAnalyzer();
          if (analyzer2) {
            audioGraph.connect([source, analyzer2]);
          } else {
            if (props.analyzer) {
              audioGraph.disconnect([source, props.analyzer]);
              audioGraph.releaseInit(props.analyzer);
              props.analyzer = void 0;
            }
          }
        } catch (error) {
          if (error instanceof Error) {
            logger.error(
              {
                scope,
                constraints: constraints.audio,
                features
              },
              "failed to apply audio constraints"
            );
          }
        }
      });
      const release = async () => {
        logger.debug({ scope }, "Release Media");
        stopMediaStream3(stream);
        await audioGraph.release();
        await media.release();
        props.denoiseNode = void 0;
        props.analyzer = void 0;
        props.audioGraph = void 0;
      };
      const muteAudio = (mute) => {
        media.muteAudio(mute);
        muteStreamTrack4(stream)(mute, "audio");
      };
      const muteVideo = (mute) => {
        media.muteVideo(mute);
        muteStreamTrack4(stream)(mute, "video");
      };
      const prevGetSettings = media.getSettings;
      return wrapToJSON(
        shallowCopy(media, {
          stream,
          applyConstraints: applyConstraints3,
          muteAudio,
          muteVideo,
          release,
          getSettings: () => {
            const { audio, video } = prevGetSettings();
            const denoise = !!props.denoiseNode && source.hasConnectedTo(props.denoiseNode);
            const asd = !!props.asd;
            const vad = !!props.vad;
            const audioSettings = {
              denoise,
              asd,
              vad
            };
            const settings = {
              audio: audio.map((settings2) => ({
                ...settings2,
                ...audioSettings
              })),
              video
            };
            logger.debug(
              { scope, settings: audioSettings },
              "get audio processor settings"
            );
            return settings;
          }
        })
      );
    } catch (error) {
      logger.error(
        { scope, error },
        "Unable to use WebAudio, return the raw media instead"
      );
      return media;
    }
  };
};

// src/audioMixingProcessor.ts
import {
  muteStreamTrack as muteStreamTrack5,
  stopMediaStream as stopMediaStream4,
  extractConstraintsWithKeys as extractConstraintsWithKeys4
} from "@pexip/media-control";
import { isEmpty as isEmpty3 } from "@pexip/utils";
import {
  createAudioGraph as createAudioGraph2,
  createAudioGraphProxy as createAudioGraphProxy2,
  createChannelMergerGraphNode,
  createStreamDestinationGraphNode as createStreamDestinationGraphNode2,
  createStreamSourceGraphNode as createStreamSourceGraphNode2,
  resumeAudioOnUnmute
} from "@pexip/media-processor";
var FEATURE_KEYS3 = ["mixWithAdditionalMedia"];
var getAudioConstraints2 = extractConstraintsWithKeys4(FEATURE_KEYS3);
var updateFeatureProps3 = (constraints, props) => {
  const extracted = getAudioConstraints2(constraints);
  return FEATURE_KEYS3.reduce((accm, key) => {
    const [feature] = extracted[key];
    if (feature !== void 0 && props[key] !== feature) {
      props[key] = feature;
      return { ...accm, [key]: feature };
    }
    return accm;
  }, {});
};
var createAudioMixingProcess = (getCurrrentMedia, scope = "mixer") => {
  const props = {};
  return async (mediaP) => {
    const media = await mediaP;
    updateFeatureProps3(media.constraints?.audio, props);
    const [mainTrack] = media.stream?.getAudioTracks() ?? [];
    const displayStream = getCurrrentMedia();
    const [displayTrack] = displayStream?.getAudioTracks?.() ?? [];
    const applyConstraints3 = applyExtendedConstraints(media, async (constraints) => {
      if (isEmpty3(constraints.audio)) {
        return;
      }
      const features = updateFeatureProps3(constraints.audio, props);
      logger.debug(
        { scope, constraints: constraints.audio, features },
        "apply audio mixing constraints"
      );
      if (isEmpty3(features) || props.audioGraph && ["closed", "closing"].includes(props.audioGraph.state)) {
        return;
      }
      if (features.mixWithAdditionalMedia) {
        const newStream = getCurrrentMedia();
        const [newTrack] = getCurrrentMedia()?.getAudioTracks() ?? [];
        if (!newTrack) {
          return;
        }
        if (!props.audioGraph || !props.merger) {
          logger.debug(
            { scope },
            "Should update the media stream directly"
          );
          return;
        }
        if (props.displaySource && props.merger) {
          if (newStream === props.displaySource.audioNode?.mediaStream) {
            return;
          }
          props.audioGraph.disconnect([
            props.displaySource,
            props.merger
          ]);
        }
        if (newStream) {
          props.displaySource = createStreamSourceGraphNode2(newStream);
          props.audioGraph.connect([
            props.displaySource,
            props.merger
          ]);
        }
      } else if (features.mixWithAdditionalMedia === false) {
        if (props.displaySource) {
          props.displaySource.release();
          props.audioGraph?.disconnect([props.displaySource]);
          props.displaySource = void 0;
        }
      }
      return Promise.resolve();
    });
    if (!media.stream) {
      return media;
    }
    if (!mainTrack && displayTrack) {
      media.stream?.addTrack(displayTrack);
      return shallowCopy(media, {
        muteAudio: () => {
        },
        applyConstraints: applyConstraints3
      });
    }
    try {
      const mainSource = createStreamSourceGraphNode2(media.stream);
      props.displaySource = displayStream && createStreamSourceGraphNode2(displayStream);
      props.merger = createChannelMergerGraphNode();
      const destination = createStreamDestinationGraphNode2({
        channelCount: 1,
        channelCountMode: "explicit"
      });
      const initialAudioNodeConnection = [
        [mainSource, props.merger],
        [props.merger, destination]
      ];
      logger.debug(
        { initialAudioNodeConnection, scope },
        "Initial AudioNodeConnection"
      );
      const audioGraph = createAudioGraphProxy2(
        createAudioGraph2(initialAudioNodeConnection),
        {
          connect: (target, args) => {
            logger.debug({ scope, target, args }, "connect nodes");
          },
          disconnect: (target, args) => {
            logger.debug({ scope, target, args }, "disconnect nodes");
          }
        }
      );
      props.audioGraph = audioGraph;
      const unsubscribes = media.rawStream?.getAudioTracks().map(resumeAudioOnUnmute(audioGraph.context));
      if (props.displaySource) {
        props.audioGraph.connect([props.displaySource, props.merger]);
      }
      const tracks = [
        ...destination?.node?.stream.getAudioTracks() ?? [],
        ...media.stream?.getVideoTracks() ?? []
      ];
      const stream = new MediaStream(tracks);
      const release = async () => {
        logger.debug({ scope }, "Release Media");
        unsubscribes?.forEach((unsubscribe) => unsubscribe());
        stopMediaStream4(stream);
        await props.audioGraph?.release();
        await media.release();
        props.audioGraph = void 0;
        props.merger = void 0;
        props.displaySource = void 0;
      };
      const muteAudio = (mute) => {
        media.muteAudio(mute);
        muteStreamTrack5(mainSource.audioNode?.mediaStream)(
          mute,
          "audio"
        );
      };
      const prevGetSettings = media.getSettings;
      return wrapToJSON(
        shallowCopy(media, {
          stream,
          applyConstraints: applyConstraints3,
          muteAudio,
          release,
          getSettings: () => {
            const { audio, video } = prevGetSettings();
            const mixWithAdditionalMedia = !!props.mixWithAdditionalMedia;
            const audioSettings = {
              mixWithAdditionalMedia
            };
            const settings = {
              audio: audio.map((settings2) => ({
                ...settings2,
                ...audioSettings
              })),
              video
            };
            logger.debug(
              { scope, settings: audioSettings },
              "get audio mixing processor settings"
            );
            return settings;
          }
        })
      );
    } catch (error) {
      logger.error(
        { scope, error },
        "Unable to use WebAudio, return the raw media instead"
      );
      return media;
    }
  };
};

// src/media.ts
var createMediaPropsHandler = (signals) => ({
  get: (target, p) => {
    switch (p) {
      default:
        return target[p];
    }
  },
  set: (target, p, value) => {
    if (target[p] === value) {
      return true;
    }
    if (p === "devices") {
      const nextDevices = value;
      const changes = getDevicesChanges(
        target[p].flatMap((device) => device.label ? [device] : []),
        nextDevices
      );
      if (isEmpty4(changes.found) && isEmpty4(changes.lost)) {
        return true;
      }
    }
    logger.debug(
      {
        oldValue: target[p],
        newValue: value,
        meta: {
          module: "Media",
          props: target
        }
      },
      `Update Props[${p}]`
    );
    switch (p) {
      case "devices": {
        if (!Array.isArray(value)) {
          return false;
        }
        target[p] = value;
        signals?.onDevicesChanged?.emit(value);
        return true;
      }
      case "media": {
        if (!isMedia(value)) {
          return false;
        }
        const currentStatus = target[p].status;
        target[p] = value;
        signals?.onMediaChanged?.emit(value);
        if (currentStatus !== value.status) {
          signals?.onStatusChanged?.emit(value.status);
        }
        return true;
      }
      default: {
        Reflect.set(target, p, value);
        return true;
      }
    }
  }
});
var createMedia = ({
  getMuteState,
  signals,
  mediaProcessors,
  getDefaultConstraints = () => ({})
}) => {
  const initMedia = (status = "initial" /* Initial */, devices = []) => buildMedia(
    () => ({
      status,
      devices,
      release: () => cleanup()
    }),
    signals.onStatusChanged?.emit
  );
  const _props = {
    devices: [],
    media: initMedia(),
    discardMedia: false
  };
  const props = new Proxy(_props, createMediaPropsHandler(signals));
  const queue = createAsyncQueue();
  const logger2 = createModuleLogger({
    module: "Media",
    props: _props,
    get mediaTracks() {
      return _props.media?.stream?.getTracks();
    },
    get rawMediaTracks() {
      return _props.media?.rawStream?.getTracks();
    }
  });
  const cleanup = async () => {
    if (isInitialPermissions(props.media.status)) {
      props.discardMedia = true;
    }
    const status = await getPermissionStatus();
    props.media = initMedia(status, props.devices);
  };
  const mediaPipeline = createMediaPipeline([
    createGetUserMediaProcess(
      requestUserMediaWithRetry(),
      () => props.devices,
      { initialMedia: props.media }
    ),
    ...mediaProcessors,
    createMediaProcess((media) => {
      const trackSubscriptions = media.rawStream?.getTracks().map(
        (track) => createStreamTrackEventSubscriptions(track, {
          ended: signals.onStreamTrackEnded?.emit,
          mute: signals.onStreamTrackMuted?.emit,
          unmute: signals.onStreamTrackUnmuted?.emit
        })
      );
      const muteTrack = (tracks) => (muted) => {
        const [track] = tracks;
        const kind = track?.kind;
        if (track && (kind === "audio" || kind === "video")) {
          media[kind === "audio" ? "muteAudio" : "muteVideo"](
            muted
          );
          return tracks.forEach((track2) => {
            logger2.debug(
              { trackInResult: track2, intendToMute: muted },
              `mute ${track2.kind}`
            );
            signals.onStreamTrackEnabled?.emit(track2);
          });
        }
        logger2.warn({ tracks, kind }, "trying to mute but no track");
      };
      const muteAudio = muteTrack(media.stream?.getAudioTracks() ?? []);
      const muteVideo = muteTrack(media.stream?.getVideoTracks() ?? []);
      const { audio, video } = getMuteState();
      media.audioMuted !== void 0 && muteAudio(audio);
      media.videoMuted !== void 0 && muteVideo(video);
      const release = async () => {
        trackSubscriptions?.forEach((unsubscribe) => unsubscribe());
        await media.release();
        await cleanup();
      };
      logger2.debug(
        { finalAudioMute: audio, finalVideoMute: video },
        "End of media pipeline"
      );
      return wrapToJSON(
        shallowCopy(media, {
          release,
          muteAudio,
          muteVideo
        })
      );
    })
  ]);
  const updateMedia = async (constraints) => {
    if (!constraints.audio && !constraints.video) {
      throw new Error(MediaDeviceFailure2.MissingConstraintsError);
    }
    const shouldRequestAudio = shouldRequestDevice(
      constraints.audio,
      props.media?.rawStream?.getAudioTracks() ?? [],
      props.devices.filter((device) => device.kind === "audioinput")
    );
    const shouldRequestVideo = shouldRequestDevice(
      constraints.video,
      props.media?.rawStream?.getVideoTracks() ?? [],
      props.devices.filter((device) => device.kind === "videoinput")
    );
    const {
      audio: [prevAudioSettings],
      video: [prevVideoSettings]
    } = props.media.getSettings();
    const hasRequestedResolution = !props.devices.some(
      (device) => device.kind === "videoinput" && device.label
    ) || isRequestedResolution2(constraints.video, props.media?.videoInput);
    const videoFeatures = updateFeatureProps(constraints.video, {});
    const audioFeatures = updateFeatureProps2(constraints.audio, {});
    const mixingFeatures = updateFeatureProps3(constraints.audio, {});
    const audioFeaturesChanged = hasSettingsChanged(AUDIO_SETTINGS_KEYS)(
      prevAudioSettings,
      audioFeatures
    );
    const videoFeaturesChanged = hasSettingsChanged(VIDEO_SETTINGS_KEYS)(
      prevVideoSettings,
      videoFeatures
    );
    const mixingFeaturesChanged = hasSettingsChanged(MIXING_SETTINGS_KEYS)(
      prevAudioSettings,
      mixingFeatures
    );
    logger2.debug(
      {
        constraints,
        stream: props.media.stream,
        currentDevices: props.devices,
        shouldRequestAudio,
        shouldRequestVideo,
        sameResolution: hasRequestedResolution,
        audioFeaturesChanged,
        videoFeaturesChanged,
        mixingFeaturesChanged
      },
      "Is currently streaming requested stream"
    );
    if (shouldRequestAudio || shouldRequestVideo || !hasRequestedResolution || !props.media.stream) {
      if (props.media) {
        await props.media.release();
      }
      if (props.discardMedia) {
        props.discardMedia = false;
      }
      const media = await mediaPipeline.execute(constraints);
      if (props.discardMedia) {
        logger2.debug("Discard media");
        return await media.release();
      }
      props.media = media;
    } else if (audioFeaturesChanged || videoFeaturesChanged || mixingFeaturesChanged) {
      props.media.constraints = constraints;
      await props.media.applyConstraints({
        audio: audioFeatures,
        video: videoFeatures
      });
    }
  };
  const mergeMediaConstraints = (constraints) => ({
    audio: mergeConstraints(getDefaultConstraints().audio)(
      constraints.audio
    ),
    video: mergeConstraints(getDefaultConstraints().video)(
      constraints.video
    )
  });
  const getUserMediaAsync = async (constraints) => {
    queue.enqueue(async () => await updateMedia(constraints), false);
    await queue.execute();
  };
  const getUserMedia3 = (constraints) => {
    queue.enqueue(
      async () => await updateMedia(mergeMediaConstraints(constraints))
    );
  };
  const tryAndGetUserMedia = (constraints) => {
    queue.enqueue(async () => {
      props.media.status = await getPermissionStatus();
      logger2.debug(
        { status: props.media.status },
        "Current Permission State"
      );
      props.devices = await getDevices2();
      if (isInitialPermissionsGranted(props.media.status)) {
        return await updateMedia(
          mergeMediaConstraints(constraints)
        );
      }
    });
  };
  const handleNoInputs = (_availableDevices) => {
    props.media.status = "no-devices-found" /* NoDevicesFound */;
  };
  const handleDeviceChange = (devices) => {
    props.devices = devices;
  };
  subscribe((event) => {
    switch (event.detail.type) {
      case MediaEventType.NoInputDevices:
        logger2.debug(event.detail, "NoInputDevices emitted");
        handleNoInputs(event.detail.devices);
        break;
      case MediaEventType.DevicesFound:
      case MediaEventType.DevicesLost:
        logger2.debug(event.detail, "DevicesFound/Lost emitted");
        handleDeviceChange([
          ...event.detail.authorizedDevices,
          ...event.detail.unauthorizedDevices
        ]);
        break;
      default:
        break;
    }
  });
  return {
    get media() {
      return props.media;
    },
    get devices() {
      return props.devices;
    },
    getUserMedia: getUserMedia3,
    getUserMediaAsync,
    tryAndGetUserMedia
  };
};

// src/previewController.ts
import { captureException, withScope } from "@sentry/hub";
import {
  getUserMedia as getUserMedia2,
  hasChangedInput,
  isMediaDeviceInfo
} from "@pexip/media-control";
import { createAsyncQueue as createAsyncQueue2 } from "@pexip/utils";
var DEFAULT_QUEUE_SIZE = 1;
var DEFAULT_QUEUE_THROTTLE_MS = 500;
var DEFAULT_QUEUE_DELAY_MS = 100;
var DEFAULT_QUEUE_DROP_LAST = false;
var isPreviewInput = (value) => {
  if (isMediaDeviceInfo(value) || value === void 0) {
    return true;
  }
  return false;
};
var extractFeaturesToConstraints = (keysToLookFor, settings) => {
  if (!settings) {
    return {};
  }
  return keysToLookFor.reduce((set, key) => {
    if (settings[key] !== void 0) {
      return { ...set, [key]: settings[key] };
    }
    return set;
  }, {});
};
var createEventHandler = (eventHandlers) => (key) => (callback) => {
  eventHandlers[key] = callback;
  return () => {
    eventHandlers[key] = void 0;
  };
};
var getUM = async (constraints) => [
  await getUserMedia2(constraints),
  "permissions-granted" /* PermissionsGranted */
];
var createAudioVideoProcessingSettingsChangeDetector = (getCurrentMedia, getPreviewMedia) => {
  const hasAudioSettingsChanged = hasSettingsChanged(AUDIO_SETTINGS_KEYS);
  const hasVideoSettingsChanged = hasSettingsChanged(VIDEO_SETTINGS_KEYS);
  return () => {
    const currentMedia = getCurrentMedia?.();
    const previewMedia = getPreviewMedia();
    const {
      audio: [mainAudio],
      video: [mainVideo]
    } = currentMedia?.getSettings() ?? { audio: [], video: [] };
    const {
      audio: [previewAudio],
      video: [previewVideo]
    } = previewMedia.getSettings();
    const changed = hasAudioSettingsChanged(mainAudio, previewAudio) || hasVideoSettingsChanged(mainVideo, previewVideo);
    return changed;
  };
};
var createPreviewStreamController = ({
  getCurrentDevices,
  getCurrentMedia,
  updateMainStream,
  onEnded,
  mediaSignal,
  queueOptions = {
    size: DEFAULT_QUEUE_SIZE,
    throttleInMS: DEFAULT_QUEUE_THROTTLE_MS,
    delayInMS: DEFAULT_QUEUE_DELAY_MS,
    dropLast: DEFAULT_QUEUE_DROP_LAST
  },
  processors
}) => {
  const queue = createAsyncQueue2(queueOptions);
  const eventHandlers = {};
  const release = () => {
    if (isInitial(props.media.status)) {
      props.discardMedia = true;
    }
    props.media = buildMedia(() => ({ release }));
    return Promise.resolve();
  };
  const internalProps = {
    media: buildMedia(() => ({ release })),
    updatingPreview: false,
    updatingMain: false,
    discardMedia: false,
    initialized: false
  };
  const logger2 = createModuleLogger({
    module: "PreviewStreamController",
    eventHandlers,
    props: internalProps
  });
  const cleanUpMainSubscription = () => {
    if (eventHandlers.unsubscribeMain) {
      eventHandlers.unsubscribeMain();
      eventHandlers.unsubscribeMain = void 0;
    }
  };
  const props = new Proxy(internalProps, {
    get: (target, p) => {
      return target[p];
    },
    set: (target, p, value) => {
      if (target[p] === value) {
        return true;
      }
      logger2.debug(
        { oldValue: target[p], newValue: value },
        `Update Props[${p}]`
      );
      switch (p) {
        case "media": {
          if (!isMedia(value)) {
            return false;
          }
          target[p] = value;
          eventHandlers[p]?.(value);
          cleanUpMainSubscription();
          return true;
        }
        case "audioInput":
        case "videoInput": {
          if (!isPreviewInput(value)) {
            return false;
          }
          target[p] = value;
          eventHandlers[p]?.(value);
          return true;
        }
        case "updatingMain":
        case "updatingPreview": {
          if (typeof value !== "boolean") {
            return false;
          }
          target[p] = value;
          eventHandlers[p]?.(value);
          return true;
        }
        default:
          Reflect.set(target, p, value);
          return true;
      }
    }
  });
  const postMediaPipeline = createMediaPipeline(
    processors
  );
  const initFromMain = (mainMedia2) => {
    if (!mainMedia2.stream) {
      return;
    }
    postMediaPipeline.execute(cloneMedia(mainMedia2)).then((media) => {
      props.media = media;
    }).catch((error) => {
      logger2.error({ error }, "Failed to post process media");
      withScope((scope) => {
        scope.setContext("Init from Main", { props: internalProps });
        captureException(error);
      });
    }).finally(() => {
      props.initialized = true;
      if (props.discardMedia) {
        void props.media.release();
      }
    });
    props.audioInput = mainMedia2.audioInput;
    props.videoInput = mainMedia2.videoInput;
    props.originalMainAudioInput = mainMedia2.audioInput;
  };
  const mainMedia = getCurrentMedia();
  if (mainMedia?.stream) {
    initFromMain(mainMedia);
  } else {
    eventHandlers.unsubscribeMain = mediaSignal.add(initFromMain);
  }
  const replaceMainStream = async (constraints) => {
    logger2.debug({ constraints }, "Replacing main stream");
    props.updatingMain = true;
    await updateMainStream(constraints);
    props.updatingMain = false;
  };
  const mediaPipeline = createMediaPipeline(() => [
    createGetUserMediaProcess(getUM, getCurrentDevices, {
      initialMedia: props.media,
      scope: "PreviewStreamController"
    }),
    ...processors
  ]);
  const updatePreviewMedia = async (constraints) => {
    logger2.debug({ constraints }, "Requesting a new preview stream");
    await props.media?.release();
    const media = await mediaPipeline.execute(constraints);
    if (props.discardMedia) {
      logger2.debug("Discard media");
      return await media.release();
    }
    props.media = media;
  };
  const updateAudioInput = async (input) => {
    props.audioInput = input;
    const request = {
      audio: { device: { exact: input } },
      video: props.videoInput
    };
    try {
      props.updatingPreview = true;
      await updatePreviewMedia(request);
    } catch (error) {
      if (error instanceof Error) {
        const errorName = error.name === "Error" ? error.message : error.name;
        if (errorName === "NotReadableError") {
          try {
            logger2.debug(
              { input },
              "NotReadableError, trying to sync main stream AudioInput"
            );
            await replaceMainStream({
              audio: input,
              video: getCurrentMedia()?.videoInput
            });
            await updatePreviewMedia(request);
          } catch (err) {
            logger2.warn(
              { error: err, input },
              "Unable to recover NotReadableError AudioInput for preview"
            );
            if (err instanceof Error) {
              return eventHandlers.audioInputError?.(err);
            }
            throw err;
          }
        } else {
          logger2.warn(
            { error, input },
            "Unable to update AudioInput for preview"
          );
          eventHandlers.audioInputError?.(error);
        }
      } else {
        logger2.error(
          { error, input },
          "Unable to update AudioInput for preview"
        );
        throw error;
      }
    } finally {
      props.updatingPreview = false;
    }
  };
  const updateVideoInput = async (input) => {
    props.videoInput = input;
    try {
      props.updatingPreview = true;
      await updatePreviewMedia({
        audio: props.audioInput,
        video: { device: { exact: input } }
      });
    } catch (error) {
      if (error instanceof Error) {
        logger2.warn(
          { error, input },
          "Unable to update VideoInput for preview"
        );
        return eventHandlers.videoInputError?.(error);
      }
      throw error;
    } finally {
      props.updatingPreview = false;
    }
  };
  const cleanup = () => {
    logger2.debug("Cleanup preview controller");
    props.audioInput = void 0;
    props.videoInput = void 0;
    onEnded?.();
  };
  const hasChangedAudioInput = () => hasChangedInput(props.originalMainAudioInput, props.audioInput);
  const hasChangedVideoInput = () => hasChangedInput(getCurrentMedia()?.videoInput, props.videoInput);
  const hasAudioVideoProcessingSettingsChanged = createAudioVideoProcessingSettingsChangeDetector(
    getCurrentMedia,
    () => props.media
  );
  const hasChanges = () => hasChangedAudioInput() || hasChangedVideoInput() || hasAudioVideoProcessingSettingsChanged();
  const applyChanges = async () => {
    const { audioInput, videoInput } = props;
    const {
      audio: [previewAudio],
      video: [previewVideo]
    } = props.media.getSettings();
    await props.media.release();
    cleanUpMainSubscription();
    if (hasChanges()) {
      const audioSettings = extractFeaturesToConstraints(
        AUDIO_SETTINGS_KEYS,
        previewAudio
      );
      const videoSettings = extractFeaturesToConstraints(
        VIDEO_SETTINGS_KEYS,
        previewVideo
      );
      logger2.info(
        { audioInput, videoInput, audioSettings, videoSettings },
        "Apply changes to main"
      );
      try {
        await replaceMainStream({
          audio: { device: audioInput, ...audioSettings },
          video: { device: videoInput, ...videoSettings }
        });
        props.originalMainAudioInput = props.audioInput;
      } catch (error) {
        if (error instanceof Error) {
          logger2.warn({ error }, "Unable to apply changes to main");
          return eventHandlers.applyChangesError?.(error);
        }
        throw error;
      }
    }
    cleanup();
  };
  const revertChanges = async () => {
    await props.media.release();
    cleanUpMainSubscription();
    const mainMedia2 = getCurrentMedia();
    const mainStream = mainMedia2?.stream;
    if (mainMedia2 && mainStream) {
      const mainMuted = !!mainStream.getTracks().some((t) => t.muted);
      const current = mainMedia2.audioInput;
      const prev = props.originalMainAudioInput;
      const audioChanged = hasChangedInput(prev, current);
      if (mainMuted || audioChanged) {
        logger2.info(
          {
            mainMuted,
            audioChanged,
            currentAudioInput: current,
            prevAudioInput: prev,
            mainMedia: mainMedia2
          },
          "Reverting Changes to main"
        );
        try {
          await replaceMainStream({
            audio: prev,
            video: getCurrentMedia()?.videoInput
          });
        } catch (error) {
          if (error instanceof Error) {
            logger2.warn(
              { error },
              "Unable to revert changes to main"
            );
            return eventHandlers.revertChangesError?.(error);
          }
          throw error;
        }
      }
    }
    cleanup();
  };
  const updateInput = (hasChanged, updater) => (input) => {
    if (hasChanged(input)) {
      queue.enqueue(async () => {
        await updater(input);
      });
    }
  };
  const toEvenHandler = createEventHandler(eventHandlers);
  return {
    get media() {
      return props.media;
    },
    get audioInputChanged() {
      return hasChangedAudioInput();
    },
    get videoInputChanged() {
      return hasChangedVideoInput();
    },
    get inputChanged() {
      return hasChanges();
    },
    get audioInput() {
      return props.audioInput;
    },
    get videoInput() {
      return props.videoInput;
    },
    get updatingPreview() {
      return props.updatingPreview;
    },
    get updatingMain() {
      return props.updatingMain;
    },
    updateAudioInput: updateInput(
      (input) => props.initialized && hasChangedInput(props.audioInput, input),
      updateAudioInput
    ),
    updateVideoInput: updateInput(
      (input) => props.initialized && hasChangedInput(props.videoInput, input),
      updateVideoInput
    ),
    onMediaChanged: toEvenHandler("media"),
    onAudioInputChanged: toEvenHandler("audioInput"),
    onVideoInputChanged: toEvenHandler("videoInput"),
    onAudioInputError: toEvenHandler("audioInputError"),
    onVideoInputError: toEvenHandler("videoInputError"),
    onApplyChangesError: toEvenHandler("applyChangesError"),
    onRevertChangesError: toEvenHandler("revertChangesError"),
    onUpdatingPreview: toEvenHandler("updatingPreview"),
    onUpdatingMain: toEvenHandler("updatingMain"),
    applyChanges,
    revertChanges
  };
};

// src/signals.ts
import { createSignal } from "@pexip/signal";
var createMediaSignal = (name, crucial = true, variant = "generic") => createSignal({
  name: `media/${name}`,
  allowEmittingWithoutObserver: !crucial,
  variant
});
var REQUIRED_SIGNAL_KEYS = [
  "onMediaChanged",
  "onVAD",
  "onSilentDetected"
];
var createMediaSignals = (more, scope = "") => {
  const signalScope = scope && [scope, ":"].join("");
  return [...REQUIRED_SIGNAL_KEYS, ...more].reduce(
    (signals, key) => ({
      ...signals,
      [key]: createMediaSignal(`${signalScope}${key}`)
    }),
    {}
  );
};
export {
  DeniedDevices,
  REQUIRED_SIGNAL_KEYS,
  UserMediaStatus,
  areBothGranted,
  createAudioMixingProcess,
  createAudioStreamProcess,
  createMedia,
  createMediaSignal,
  createMediaSignals,
  createPreviewStreamController,
  createVideoStreamProcess,
  getPermissionStatus,
  hasNoAudioDevices,
  hasNoDevice,
  hasNoVideoDevices,
  isAudioDeviceInUse,
  isDeviceInUse,
  isFallback,
  isFallbackAudio,
  isFallbackVideo,
  isGranted,
  isGrantedOnlyAudio,
  isGrantedOnlyAudioNoVideoDevices,
  isGrantedOnlyVideo,
  isGrantedOnlyVideoNoAudioDevices,
  isInitial,
  isInitialPermissions,
  isInitialPermissionsGranted,
  isInitialPermissionsNotGranted,
  isOnlyAudioError,
  isOnlyVideoError,
  isOverConstrained,
  isRejected,
  isUnknownError,
  isVideoDeviceInUse,
  setLogger,
  toDeniedDevices
};
